import { DefaultSourceActions } from './DefaultSourceActionList';

class RegistrationPackageActions {
  constructor() {
    this.generateActions(
      ...DefaultSourceActions,
      'fetchEventPurchasesSuccess',
      'fetchEventPurchasesError'
    );
  }
}

export default alt.createActions(RegistrationPackageActions);
