import { Map, OrderedMap } from 'immutable';
import debounce from 'lodash.debounce';

import uhApiClient from 'shared/helpers/uhApiClient.jsx';

import { EventSource } from 'sources';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import RegistrationFieldStore from 'shared/stores/RegistrationFieldStore.jsx';

import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import RegistrationFieldActions from 'shared/actions/RegistrationFieldActions.jsx';
import RegistrationResponsesActions from './Actions';

class RegistrationResponsesStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.debouncedListResponses = debounce(this.listResponses, 300);
    this.bindListeners({
      mounted: RegistrationResponsesActions.mounted,
      handleSearchChange: RegistrationResponsesActions.handleSearchChange,
      toggleEditDrawer: RegistrationResponsesActions.toggleEditDrawer,
      handlePageSelect: RegistrationResponsesActions.handlePageSelect,
      updateResponse: RegistrationResponsesActions.updateResponse,

      saveResponse: RegistrationResponsesActions.saveResponse,
      saveResponseSuccess: RegistrationResponsesActions.saveResponseSuccess,
      saveResponseError: RegistrationResponsesActions.saveResponseError,

      listResponses: RegistrationResponsesActions.listResponses,
      listResponsesSuccess: RegistrationResponsesActions.listResponsesSuccess,
      listResponsesError: RegistrationResponsesActions.listResponsesError,

      fieldsLoaded: RegistrationFieldActions.listSuccess,
      fieldsLoadedError: RegistrationFieldActions.listError,
    });
  }

  reset() {
    this.eventId = null;
    this.searchText = '';
    this.openEditDrawer = false;
    this.selectedResponse = null;
    this.loading = false;
    this.fieldsLoading = false;
    this.responses = Map();
    this.pagination = Map({
      page: 1,
      perPage: 15,
      totalCount: 0,
    });
  }

  mounted({ eventId }) {
    this.reset();
    this.eventId = eventId;
    this.listResponses();
  }

  toggleEditDrawer({ responseId = null }) {
    this.fieldsLoading = true;
    this.openEditDrawer = Boolean(responseId);
    this.selectedResponse = this.responses.get(responseId);
  }

  handleSearchChange(searchText) {
    this.loading = true;
    this.searchText = searchText;
    this.debouncedListResponses();
  }

  listResponses() {
    this.loading = true;

    EventSource.listRegistrationResponses({
      eventId: this.eventId,
      params: {
        page: this.pagination.get('page'),
        per_page: this.pagination.get('perPage'),
        search: this.searchText,
        sort: 'asc',
      },
      success: RegistrationResponsesActions.listResponsesSuccess,
      error: RegistrationResponsesActions.listResponsesError,
    });
  }

  listResponsesSuccess({ clients, page, perPage, totalCount }) {
    this.pagination = this.pagination
      .set('page', page)
      .set('perPage', perPage)
      .set('totalCount', totalCount);
    this.responses = OrderedMap(clients.map(client => [client.id, client]));
    this.loading = false;
  }

  listResponsesError(...args) {
    this.loading = false;
    this.notifyError('Failed to load registration responses', ...args);
  }

  handlePageSelect([page]) {
    this.pagination = this.pagination.set('page', page);
    this.listResponses();
  }

  updateResponse({ field, value }) {
    const userAnswers = this.responses
      .get(this.selectedResponse.id)
      .get('answers');
    const answerToUpdate = userAnswers.get(field.get('id'));

    if (answerToUpdate) {
      this.selectedResponse = this.selectedResponse.setIn(
        [
          'answers',
          answerToUpdate.get('custom_registration_field_id'),
          'answer',
        ],
        value
      );
    } else {
      const newAnswer = Map({
        answer: value,
        event_id: this.eventId,
        name: field.get('name'),
        required: field.get('required'),
        custom_registration_field_id: field.get('id'),
        customer_user_id: this.selectedResponse.get('id'),
        meta_registration_field_id: field.get('meta_registration_field_id'),
      });

      this.selectedResponse = this.selectedResponse.setIn(
        ['answers', field.id],
        newAnswer
      );
    }
  }

  saveResponse() {
    this.waitFor(RegistrationFieldStore);
    const { selectedFields } = RegistrationFieldStore.getState();
    this.selectedResponse = this.selectedResponse.set(
      'answers',
      Map(
        selectedFields.map(f => {
          const field = this.selectedResponse.get('answers').get(f.id);

          if (field) {
            return [f.id, field];
          }

          return [
            f.id,
            Map({
              answer: '',
              event_id: this.eventId,
              name: f.get('name'),
              required: f.get('required'),
              custom_registration_field_id: f.get('id'),
              customer_user_id: this.selectedResponse.get('id'),
              meta_registration_field_id: f.get('meta_registration_field_id'),
            }),
          ];
        })
      )
    );

    this.selectedResponse = this.selectedResponse.validateAnswers();

    const isValid = this.selectedResponse.errors.isEmpty();

    if (isValid) {
      const answers = this.selectedResponse.get('answers');
      const payload = answers.map(answer => ({
        id: answer.get('id'),
        event_id: this.eventId,
        answer: answer.get('answer'),
        customer_user_id: this.selectedResponse.get('id'),
        meta_registration_field_id: answer.get('meta_registration_field_id'),
        custom_registration_field_id: answer.get(
          'custom_registration_field_id'
        ),
      }));

      uhApiClient.post({
        url: `events/${this.eventId}/register`,
        data: JSON.stringify({ attributes: { answers: payload.toArray() } }),
        success: {
          action: RegistrationResponsesActions.saveResponseSuccess,
          args: [this.selectedResponse],
        },
        error: RegistrationResponsesActions.saveResponseError,
      });
    }
  }

  saveResponseSuccess([_, response]) {
    this.responses = this.responses.set(response.id, response);
    this.toggleEditDrawer({});
    MessageWindowActions.addMessage.defer('Response saved successfully');
  }

  saveResponseError() {
    this.notifyError('Failed to save response');
  }

  fieldsLoaded() {
    this.fieldsLoading = false;
  }

  fieldsLoadedError() {
    this.fieldsLoading = false;
  }
}

export default alt.createStore(
  RegistrationResponsesStore,
  'RegistrationResponsesStore'
);
