import { List } from 'immutable';
import RegistrationPackage from 'shared/records/RegistrationPackage';
import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { onError, onSuccess } from 'sources/handlers';
import DataStoreActions from './RegistrationPackageActions';

const parserTo = {
  type: RegistrationPackage,
  paginationKey: 'registration_packages',
};

const parser = new Parser(parserTo);

export const fetch = ({ id, success, error }) => {
  uhApiClient.get({
    url: `${parserTo.paginationKey}/${id}`,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.fetchSuccess, success])
    ),
    error: onError(List([DataStoreActions.fetchError, error])),
  });
};

export const fetchEventPurchases = ({ eventId, success, error }) => {
  uhApiClient.get({
    url: `${parserTo.paginationKey}/purchase_count`,
    data: { event_id: eventId },
    success: onSuccess(
      data => ({ ...data, eventId }),
      List([DataStoreActions.fetchEventPurchasesSuccess, success])
    ),
    error: onError(List([DataStoreActions.fetchEventPurchasesError, error])),
  });
};

export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: parserTo.paginationKey,
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error: onError(List([DataStoreActions.listError, error])),
  });
};

export const update = ({ id, params, success, error }) => {
  uhApiClient.put({
    url: `${parserTo.paginationKey}/${id}`,
    data: JSON.stringify({ attributes: params }),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.updateSuccess, success])
    ),
    error: onError(List([DataStoreActions.updateError, error])),
  });
};
