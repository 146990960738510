/* eslint-disable react/require-default-props */
import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  Drawer,
  Button,
  Grid,
  Typography,
  Spinner,
} from '@upperhand/playmaker';

import ResponseEditField from 'containers/events/admin/registrationResponses/components/ResponseEditField.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import RegistrationFieldStore from 'shared/stores/RegistrationFieldStore.jsx';

import RegistrationFieldActions from 'shared/actions/RegistrationFieldActions.jsx';

function ResponseEditDrawer({
  eventId,
  loading,
  open,
  selectedResponse,
  registrationFieldStore,
  onClose,
  onSave,
  onFieldChange,
}) {
  const intl = useIntl();

  useEffect(() => {
    if (open && eventId) {
      RegistrationFieldActions.list(eventId);
    }
  }, [open, eventId]);

  if (!selectedResponse) {
    return null;
  }

  const { selectedFields } = registrationFieldStore;
  const answers = selectedResponse?.get('answers') || [];

  return (
    <Drawer
      title={t('.title', intl, __filenamespace)}
      open={open}
      onClose={onClose}
      classes={{
        root: 'responses-edit-drawer',
        content: 'responses-edit-drawer__content',
        footer: 'responses-edit-drawer__footer',
      }}
      content={
        <Grid container spacing={2}>
          {loading && (
            <Grid item container xs={12} alignItems="center" justify="center">
              <Spinner />
            </Grid>
          )}
          {!loading &&
            selectedFields.map(field => {
              const userAnswer = answers.get(field.id);

              return (
                <Grid container item spacing={1} key={field.id}>
                  <Grid item xs={12}>
                    <Typography variant="body1">{field.get('name')}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ResponseEditField
                      field={field}
                      answer={userAnswer?.get('answer') || undefined}
                      errorText={selectedResponse.errors.getErrors(field.id)}
                      onFieldChange={onFieldChange}
                    />
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      }
      footer={
        <>
          <Button type="tertiary" onClick={onClose}>
            {t('.cancel', intl, __filenamespace)}
          </Button>
          <Button type="primary" disabled={loading} onClick={onSave}>
            {t('.save', intl, __filenamespace)}
          </Button>
        </>
      }
    />
  );
}

ResponseEditDrawer.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  open: PropTypes.bool,
  selectedResponse: PropTypes.object,
  registrationFieldStore: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onFieldChange: PropTypes.func,
};

export default compose(
  memo,
  altContainer({
    stores: {
      registrationFieldStore: RegistrationFieldStore,
    },
  })
)(ResponseEditDrawer);
