/* eslint-disable no-unused-vars */
import { Set, Map } from 'immutable';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import { sumInt } from 'shared/utils/ImmutableUtils.js';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { OrderDataStore } from 'dataStores';
import {
  BalancesSource,
  ClientSource,
  CreditPassSource,
  EventSource,
  EventTypeSource,
  OrderSource,
  PaymentPlanSource,
} from 'sources';
import { OrderableTypes } from 'containers/reports/types';
import RefundModalActions from 'containers/reports/refundModal/Actions';
import OrderSummaryDrawerActions from './Actions';

class OrderSummaryDrawerStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      balanceListSuccess: OrderSummaryDrawerActions.balanceListSuccess,
      balanceListError: OrderSummaryDrawerActions.balanceListError,

      creditPassSuccess: OrderSummaryDrawerActions.creditPassSuccess,
      creditPassError: OrderSummaryDrawerActions.creditPassError,

      clientListResponse: OrderSummaryDrawerActions.clientListResponse,
      eventListResponse: OrderSummaryDrawerActions.eventListResponse,
      eventTypeListResponse: OrderSummaryDrawerActions.eventTypeListResponse,

      openDrawer: OrderSummaryDrawerActions.openDrawer,
      closeDrawer: [
        OrderSummaryDrawerActions.closeDrawer,
        RefundModalActions.openModal,
      ],

      sendReceipt: OrderSummaryDrawerActions.sendReceipt,
      sendReceiptSuccess: OrderSummaryDrawerActions.sendReceiptSuccess,
      sendReceiptError: OrderSummaryDrawerActions.sendReceiptError,

      toggleDotsMenu: OrderSummaryDrawerActions.toggleDotsMenu,

      detailsSuccess: OrderSummaryDrawerActions.detailsSuccess,
      detailsError: OrderSummaryDrawerActions.detailsError,

      paymentPlanSuccess: OrderSummaryDrawerActions.paymentPlanSuccess,
      paymentPlanError: OrderSummaryDrawerActions.paymentPlanError,

      saveNote: OrderSummaryDrawerActions.saveNote,
      saveNoteSuccess: OrderSummaryDrawerActions.saveNoteSuccess,
      saveNoteError: OrderSummaryDrawerActions.saveNoteError,
    });
  }

  reset() {
    this.order = null;
    this.fullyPaid = false;
    this.internalLoadingBalanceDetails = null;
    this.isLoadingBalances = false;
    this.isLoadingBalanceDetails = false;
    this.isLoadingClients = false;
    this.isLoadingCreditPasses = false;
    this.isLoadingEvents = false;
    this.isLoadingEventTypes = false;
    this.isOpen = false;
    this.dotsMenuAnchorEl = null;
    this.orderItemToRefund = null;
    this.orderStatus = null;
    this.waivedAmount = 0;
    this.refundedAmount = 0;
    this.paidAmount = 0;
    this.remainingAmount = 0;
  }

  openDrawer(orderId) {
    this.isOpen = true;
    this.order = OrderDataStore.getState().orders.get(orderId);
    this.fetchOrderDependencies();
  }

  fetchOrderDependencies() {
    let creditPassIds = Set();
    let clientIds = Set();
    let eventIds = Set();
    let orderItemIds = Set();
    this.order.get('order_items').forEach(orderItem => {
      orderItemIds = orderItemIds.add(orderItem.get('id'));
      switch (orderItem.get('orderable_type')) {
        case OrderableTypes.membership_subscription_package: {
          clientIds = clientIds.union(
            orderItem.getIn(['orderable', 'customer_user_ids'])
          );
          break;
        }
        case OrderableTypes.registration_package: {
          clientIds = clientIds.union(
            orderItem.getIn(['orderable', 'client_ids'])
          );
          eventIds = eventIds.add(orderItem.getIn(['orderable', 'event_id']));
          break;
        }
        case OrderableTypes.credit_pass_package: {
          clientIds = clientIds.union(
            orderItem.getIn(['orderable', 'client_ids'])
          );
          creditPassIds = creditPassIds.add(
            orderItem.getIn(['orderable', 'credit_pass_id'])
          );
          break;
        }
        default:
          break;
      }
    });

    if (clientIds.size) {
      this.isLoadingClients = true;
      ClientSource.list({
        params: {
          per_page: 50,
          ids: clientIds.toJS(),
        },
        success: OrderSummaryDrawerActions.clientListResponse,
        error: OrderSummaryDrawerActions.clientListResponse,
      });
    }

    if (eventIds.size) {
      this.isLoadingEvents = true;
      EventSource.list({
        params: {
          per_page: 50,
          ids: eventIds.toJS(),
          fields: ['team_type'],
        },
        success: OrderSummaryDrawerActions.eventListResponse,
        error: OrderSummaryDrawerActions.eventListResponse,
      });
    }

    if (creditPassIds.size) {
      this.isLoadingCreditPasses = true;
      CreditPassSource.list({
        params: {
          ids: creditPassIds.toJS(),
          per_page: 50,
        },
        success: OrderSummaryDrawerActions.creditPassSuccess,
        error: OrderSummaryDrawerActions.creditPassError,
      });
    }

    if (orderItemIds.size) {
      this.isLoadingBalances = true;
      BalancesSource.list({
        params: {
          product_ids: orderItemIds.toJS(),
          per_page: 50,
        },
        success: OrderSummaryDrawerActions.balanceListSuccess,
        error: OrderSummaryDrawerActions.balanceListError,
      });
    }
  }

  closeDrawer() {
    this.reset();
  }

  balanceListSuccess({ records: balances }) {
    this.remainingAmount = balances.reduce(sumInt('outstanding'), 0);
    if (
      this.paidAmount === 0 &&
      this.order.get('total') !== 0 &&
      this.remainingAmount === 0
    ) {
      this.fullyPaid = true;
    }

    this.isLoadingBalances = false;
    this.isLoadingBalanceDetails = balances.count() > 0;
    this.internalLoadingBalanceDetails = Map();

    balances.forEach(balance => {
      this.internalLoadingBalanceDetails =
        this.internalLoadingBalanceDetails.set(balance.get('compoundId'), true);

      BalancesSource.details({
        productId: balance.get('productId'),
        compoundId: balance.get('compoundId'),
        success: OrderSummaryDrawerActions.detailsSuccess,
        error: OrderSummaryDrawerActions.detailsError,
      });
    });
  }

  balanceListError() {
    this.isLoadingBalances = false;
  }

  detailsSuccess({ compoundId, collection }) {
    const paymentPlan = collection.find(
      detail => detail.type === 'PaymentPlan'
    );
    // Possibly amend the order status depending on our details.
    // Not worrying about status any more, but leaving this here
    // in case we later do.
    // const collectionHas = type =>
    //   collection.filter(detail => detail.get('type') === type).count() > 0;
    // const possibleStatuses = [
    //   ['DebitForgiveness', 'waived'],
    //   ['Refund', 'refunded'],
    // ];
    //
    // let statuses = Set(this.orderStatus);
    //
    // possibleStatuses.forEach(ps => {
    //   if (collectionHas(ps[0])) {
    //     statuses = statuses.add(this.fullyPaid ? ps[1] : `partially_${ps[1]}`);
    //   }
    // });
    //
    // if (statuses.count() > 0) this.orderStatus = statuses;

    // Create sum of total waived amount and total refunded amount.
    collection.forEach(c => {
      if (c.get('type') === 'Refund' && c.get('amount') > 0) {
        this.refundedAmount += c.get('amount');
      } else if (c.get('type') === 'DebitForgiveness') {
        this.waivedAmount += c.get('amount');
      } else if (c.get('type') === 'Payment') {
        this.paidAmount += c.get('amount');
      }
    });
    if (paymentPlan) {
      PaymentPlanSource.fetch({
        id: paymentPlan.sourceId,
        params: { fields: ['plan_paid_amount', 'plan_waived_amount'] },
        success: {
          action: OrderSummaryDrawerActions.paymentPlanSuccess,
          args: [compoundId],
        },
        error: OrderSummaryDrawerActions.paymentPlanError,
      });
    } else {
      this.internalLoadingBalanceDetails =
        this.internalLoadingBalanceDetails.set(compoundId, false);
      this.isLoadingBalanceDetails = this.internalLoadingBalanceDetails.some(
        loading => loading
      );
    }
  }

  paymentPlanSuccess([
    { planPaidAmount, planWaivedAmount, remainingAmount },
    compoundId,
  ]) {
    this.paidAmount += planPaidAmount;
    this.waivedAmount += planWaivedAmount;
    this.remainingAmount += remainingAmount;
    this.internalLoadingBalanceDetails = this.internalLoadingBalanceDetails.set(
      compoundId,
      false
    );
    this.isLoadingBalanceDetails = this.internalLoadingBalanceDetails.some(
      loading => loading
    );
  }

  paymentPlanError() {
    this.isLoadingBalanceDetails = false;
  }

  detailsError() {
    this.isLoadingBalanceDetails = false;
  }

  clientListResponse() {
    this.isLoadingClients = false;
  }

  eventListResponse() {
    this.isLoadingEvents = false;
  }

  creditPassSuccess({ credit_passes: creditPasses }) {
    let eventTypeIds = Set();
    let eventIds = Set();
    creditPasses.forEach(creditPass => {
      eventIds = eventIds.union(
        creditPass
          .get('credit_pass_credits')
          .reduce((set, credit) => set.union(credit.get('event_ids')), Set())
      );
      eventTypeIds = eventTypeIds.union(
        creditPass
          .get('credit_pass_credits')
          .reduce(
            (set, credit) => set.union(credit.get('event_type_ids')),
            Set()
          )
      );
    });
    if (eventIds.size) {
      this.isLoadingEvents = true;
      EventSource.list({
        params: {
          per_page: 50,
          ids: eventIds.toJS(),
        },
        success: OrderSummaryDrawerActions.eventListResponse,
        error: OrderSummaryDrawerActions.eventListResponse,
      });
    }

    if (eventTypeIds.size) {
      this.isLoadingEventTypes = true;
      EventTypeSource.list({
        params: {
          per_page: 50,
          ids: eventTypeIds.toJS(),
        },
        success: OrderSummaryDrawerActions.eventTypeListResponse,
        error: OrderSummaryDrawerActions.eventTypeListResponse,
      });
    }
    this.isLoadingCreditPasses = false;
  }

  creditPassError() {
    this.isLoadingCreditPasses = false;
  }

  eventTypeListResponse() {
    this.isLoadingEventTypes = false;
  }

  sendReceipt() {
    OrderSource.sendReceipt({
      id: this.order.get('id'),
      success: OrderSummaryDrawerActions.sendReceiptSuccess,
      error: OrderSummaryDrawerActions.sendReceiptError,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  sendReceiptSuccess() {
    MessageWindowActions.addMessage.defer('Receipt sent.');
  }

  sendReceiptError(...args) {
    this.notifyError('Error sending receipt', args);
  }

  toggleDotsMenu(event) {
    this.dotsMenuAnchorEl = event && event.currentTarget;
  }

  handleNoteChange(note) {
    this.order = this.order.set('note', note);
  }

  saveNote({ note, onSuccessCallback = () => {} }) {
    OrderSource.patch({
      id: this.order.get('id'),
      data: {
        attributes: { note },
        fields: ['order_items', 'coupon', 'note'],
      },
      success: {
        action: OrderSummaryDrawerActions.saveNoteSuccess,
        args: [onSuccessCallback],
      },
      error: {
        action: OrderSummaryDrawerActions.saveNoteError,
        args: [onSuccessCallback],
      },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  saveNoteSuccess([order, onSuccessCallback]) {
    this.order = order;

    onSuccessCallback();
    MessageWindowActions.addMessage.defer('Order note saved.');
  }

  saveNoteError([{ ...args }, onSuccessCallback]) {
    onSuccessCallback();
    this.notifyError('Error saving note', args);
  }
}

export default alt.createStore(
  OrderSummaryDrawerStore,
  'OrderSummaryDrawerStore'
);
