import debounce from 'lodash.debounce';
import { OrderedSet, Map } from 'immutable';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import EmailTemplate from 'shared/records/EmailTemplate';

import { EmailTemplatesSource } from 'sources';

import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import MarketingEmailTemplatesActions from './Actions';

class MarketingEmailTemplatesStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.debouncedSearchTemplates = debounce(this.list, 500);
    this.bindListeners({
      openDrawer:
        MarketingEmailTemplatesActions.openMarketingEmailTemplateDrawer,
      closeDrawer:
        MarketingEmailTemplatesActions.closeMarketingEmailTemplateDrawer,
      togglePreview: MarketingEmailTemplatesActions.toggleMarketingEmailPreview,

      handleChange: MarketingEmailTemplatesActions.handleFieldChange,

      create: MarketingEmailTemplatesActions.createMarketingEmailTemplate,
      createSuccess:
        MarketingEmailTemplatesActions.createMarketingEmailTemplateSuccess,
      createError:
        MarketingEmailTemplatesActions.createMarketingEmailTemplateError,

      delete: MarketingEmailTemplatesActions.deleteMarketingEmailTemplate,
      deleteSuccess:
        MarketingEmailTemplatesActions.deleteMarketingEmailTemplateSuccess,
      deleteError:
        MarketingEmailTemplatesActions.deleteMarketingEmailTemplateError,

      list: MarketingEmailTemplatesActions.listMarketingEmailTemplates,
      listSuccess:
        MarketingEmailTemplatesActions.listMarketingEmailTemplatesSuccess,
      listError:
        MarketingEmailTemplatesActions.listMarketingEmailTemplatesError,

      searchTemplates:
        MarketingEmailTemplatesActions.searchMarketingEmailTemplates,
      pageSelect: MarketingEmailTemplatesActions.handlePageSelect,

      fetchTemplatePreviewSuccess:
        MarketingEmailTemplatesActions.fetchTemplatePreviewSuccess,
      fetchTemplatePreviewError:
        MarketingEmailTemplatesActions.fetchTemplatePreviewError,
    });
  }

  reset() {
    this.isLoading = false;
    this.isUpdating = false;
    this.drawerOpen = false;
    this.isTemplatePreview = false;
    this.search = '';
    this.template = new EmailTemplate();
    this.emailTemplatesIds = OrderedSet();
    this.templatePreview = null;
    this.loading = Map({
      template: false,
      templatePreview: false,
    });
    this.pagination = Map({
      templates: Map({
        page: 1,
        perPage: 15,
        totalCount: 0,
      }),
    });
  }

  togglePreview() {
    this.isTemplatePreview = !this.isTemplatePreview;
  }

  openDrawer({ template }) {
    this.drawerOpen = true;

    this.loading = this.loading.set('templatePreview', true);
    EmailTemplatesSource.fetchTemplatePreview({
      success: MarketingEmailTemplatesActions.fetchTemplatePreviewSuccess,
      error: MarketingEmailTemplatesActions.fetchTemplatePreviewError,
    });

    if (template) {
      this.template = template;
    }

    if (template && !template.body) {
      this.fetchEmailTemplate(template.id);
    }
  }

  closeDrawer() {
    this.drawerOpen = false;
    this.templatePreview = null;
    this.isTemplatePreview = false;
    this.template = new EmailTemplate();
  }

  handleChange({ field, value }) {
    this.template = this.template.set(field, value);
  }

  fetchEmailTemplate(templateId) {
    if (templateId) {
      this.loading = this.loading.set('template', true);

      EmailTemplatesSource.fetch({
        id: templateId,
        params: {
          fields: ['body'],
        },
        success: template => {
          this.template = template;
          this.loading = this.loading.set('template', false);
        },
        error: (...args) => {
          this.loading = this.loading.set('template', false);
          this.notifyError('Error fetching email template', args);
        },
      });
    }
  }

  fetchTemplatePreviewSuccess(templatePreview) {
    this.templatePreview = templatePreview;
    this.loading = this.loading.set('templatePreview', false);
  }

  fetchTemplatePreviewError(...args) {
    this.loading = this.loading.set('templatePreview', false);
    this.notifyError('Error fetching template preview', args);
  }

  create() {
    this.isUpdating = true;

    if (this.template.get('id', false)) {
      EmailTemplatesSource.update({
        template: this.template.toJS(),
        success:
          MarketingEmailTemplatesActions.createMarketingEmailTemplateSuccess,
        error: MarketingEmailTemplatesActions.createMarketingEmailTemplateError,
      });
    } else {
      EmailTemplatesSource.create({
        template: this.template.toJS(),
        success:
          MarketingEmailTemplatesActions.createMarketingEmailTemplateSuccess,
        error: MarketingEmailTemplatesActions.createMarketingEmailTemplateError,
      });
    }
  }

  createSuccess(template) {
    this.isUpdating = false;
    this.emailTemplatesIds = this.emailTemplatesIds.add(template.id);

    if (this.template.get('id', false)) {
      MessageWindowActions.addMessage.defer(
        'Email template updated successfully'
      );
    } else {
      MessageWindowActions.addMessage.defer(
        'Email template created successfully'
      );
    }

    this.closeDrawer();
  }

  createError(...args) {
    this.isUpdating = false;

    this.notifyError('Error creating email template', args);
  }

  delete(templateId) {
    this.isLoading = true;
    EmailTemplatesSource.remove({
      templateId,
      success:
        MarketingEmailTemplatesActions.deleteMarketingEmailTemplateSuccess,
      error: MarketingEmailTemplatesActions.deleteMarketingEmailTemplateError,
    });
  }

  deleteSuccess(template) {
    this.isLoading = false;
    this.emailTemplatesIds = this.emailTemplatesIds.delete(template.id);

    MessageWindowActions.addMessage.defer(
      'Email template deleted successfully'
    );
  }

  deleteError(...args) {
    this.isLoading = false;
    this.notifyError('Error deleting email template', args);
  }

  list() {
    this.isLoading = true;

    EmailTemplatesSource.list({
      params: {
        search: this.search,
        page: this.pagination.getIn(['templates', 'page']),
        per_page: this.pagination.getIn(['templates', 'perPage']),
      },
      success:
        MarketingEmailTemplatesActions.listMarketingEmailTemplatesSuccess,
      error: MarketingEmailTemplatesActions.listMarketingEmailTemplatesError,
    });
  }

  listSuccess({ email_templates: emailTemplates, page, perPage, totalCount }) {
    this.emailTemplatesIds = OrderedSet(
      emailTemplates.map(template => template.id)
    );
    this.pagination = this.pagination
      .setIn(['templates', 'page'], page)
      .setIn(['templates', 'perPage'], perPage)
      .setIn(['templates', 'totalCount'], totalCount);
    this.isLoading = false;
  }

  listError(...args) {
    this.isLoading = false;

    this.notifyError('Error listing email templates', args);
  }

  searchTemplates(searchText) {
    this.search = searchText;
    this.pagination = this.pagination.setIn(['templates', 'page'], 1);
    this.isLoading = true;
    this.debouncedSearchTemplates();
  }

  pageSelect([page]) {
    this.pagination = this.pagination.setIn(['templates', 'page'], page);
    this.list();
  }
}

export default alt.createStore(
  MarketingEmailTemplatesStore,
  'MarketingEmailTemplatesStore'
);
