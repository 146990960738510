import moment from 'moment-timezone';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

import Client from 'shared/records/Client.jsx';
import Customer from 'shared/records/Customer.jsx';
import Staff from 'shared/records/Staff.jsx';

import { RoleSource, ClientSource } from 'sources';

import { currentUser } from 'shared/utils/UserUtils.jsx';
import { getCurrentContext } from 'shared/utils/AuthUtils';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { resetLogContext } from 'ErrorLogger.js';
import { toSlug } from 'shared/utils/StringUtils.jsx';
import { isDBatTheme } from 'shared/utils/ThemeUtils';
import { setContext } from 'Trackers.js';

import CustomerActions from 'shared/actions/CustomerActions.jsx';
import CurrentContextActions from 'shared/actions/CurrentContextActions.jsx';
import MachineSettingsDrawerActions from 'customers/settings/MachineSettingsDrawer/Actions';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import ClientBillingSettingsActions from 'containers/client/settings/billing/Actions.js';

const managedUsersUrl = id => `customer_users/${id}/managed_customer_users`;

class CurrentContextStore extends UpperHandStore {
  constructor() {
    super();
    this.customerUser = new Client();
    this.populateWindowUser();

    this.customer = new Customer();
    this.populateWindowCustomer();

    this.userLoading = false;
    this.customerLoading = false;
    this.managedUsersLoading = false;
    // default to true so we don't flash screens
    this.isCustomerUserStoreLoading = true;
    this.isSaving = false;
    this.openDialog = false;
    this.bindListeners({
      handleAcceptTerms: CurrentContextActions.acceptTerms,
      mounted: CurrentContextActions.fetch,
      refreshCustomerUser:
        ClientBillingSettingsActions.updateCustomerUserAccessSuccess,

      fetchCustomerUserSuccess: CurrentContextActions.fetchCustomerUserSuccess,
      fetchCustomerUserError: CurrentContextActions.fetchCustomerUserError,

      fetchCustomerSuccess: [
        CurrentContextActions.fetchCustomerSuccess,
        CustomerActions.updateSuccess,
        MachineSettingsDrawerActions.updateMachineSettingsSuccess,
      ],
      fetchCustomerError: CurrentContextActions.fetchCustomerError,

      loadManagedProfiles: CurrentContextActions.loadManagedProfiles,

      loadManagedProfilesSuccess:
        CurrentContextActions.loadManagedProfilesSuccess,
      loadManagedProfilesError: CurrentContextActions.loadManagedProfilesError,

      handleUpdateSuccess: CurrentContextActions.updateSuccess,
      handleUpdateError: CurrentContextActions.updateError,
      updatePreferences: CurrentContextActions.updatePreferences,
      changePreferences: CurrentContextActions.changePreferences,

      joinSuccess: CurrentContextActions.joinSuccess,
      joinError: CurrentContextActions.joinError,
      roleListSuccess: CurrentContextActions.roleListSuccess,
      roleListError: CurrentContextActions.roleListError,
      showDialog: CurrentContextActions.openDialog,
      closeDialog: CurrentContextActions.closeDialog,
      acceptTermsSuccess: CurrentContextActions.acceptTermsSuccess,
    });
  }

  updateLoading() {
    this.isCustomerUserStoreLoading =
      this.customerLoading || this.userLoading || this.managedUsersLoading;
  }

  // eslint-disable-next-line class-methods-use-this
  handleAcceptTerms(id) {
    return uhApiClient.put({
      url: `customer_users/${id}/accept_terms`,
      success: CurrentContextActions.acceptTermsSuccess,
      error: CurrentContextActions.updateError,
    });
  }

  mounted({ fields }) {
    const currentContext = getCurrentContext();

    // get and set currentCustomer() info on window
    if (currentContext.customerId) {
      this.customerLoading = true;

      let customerFields = [];

      if (
        ['Admin', 'StaffAdmin', 'StaffMember'].includes(currentContext.role)
      ) {
        customerFields = [
          'paysafe_onboarding_step',
          'bank_account_validated',
          'paysafe_account_status',
          'mode_report_labels',
          'logo',
        ];
      }

      uhApiClient.get({
        url: `customers/${currentContext.customerId}`,
        data: {
          fields: customerFields,
        },
        success: CurrentContextActions.fetchCustomerSuccess,
        error: CurrentContextActions.fetchCustomerError,
      });
    }

    if (!currentContext.userId || currentContext.customerUserId) {
      this.fetchCustomerUser(fields);
    } else {
      this.joinCustomer();
    }

    this.updateLoading();

    /* Seems like this is fixed now that we call mount on customersRoutes instead of app route?  leave this incase we need to revert during QA.
       IF THIS IS IN MASTER BRANCH THIS CAN BE DELETED
    */
    // if (!currentContext.customerId) {
    //   /* We're not really sure what the root cause is, but setting the properties
    //    on the store correctly and letting alt handle the emit change doesn't seem
    //    to work in this case, or the case above. For some reason when doing this
    //    properly the store state is updated correctly, but the AltContainer is
    //    never notified of the change and so props do not update. Manually emitting
    //    the change fixes the issue, so we went with that for now. */
    //   setTimeout(() => {
    //     this.emitChange();
    //   }, 0);
    // }
  }

  joinCustomer() {
    const { customerId } = getCurrentContext();

    this.userLoading = true;

    ClientSource.join({
      id: customerId,
      success: CurrentContextActions.joinSuccess,
      error: CurrentContextActions.joinError,
    });
  }

  showDialog() {
    this.openDialog = true;
  }

  closeDialog() {
    this.openDialog = false;
  }

  fetchCustomerUser(fields = []) {
    const currentContext = getCurrentContext();

    if (currentContext.customerUserId) {
      this.userLoading = true;
      const data = {
        fields:
          currentContext.role === 'Client'
            ? [
                'active_membership_id',
                'agreement_details',
                'active_membership_tier_id',
              ]
            : ['active_membership_id', 'active_membership_tier_id'],
      };

      data.fields = [...data.fields, ...fields];

      uhApiClient.get({
        url: `customer_users/${currentContext.customerUserId}`,
        data,
        success: CurrentContextActions.fetchCustomerUserSuccess,
        error: CurrentContextActions.fetchCustomerUserError,
      });
    }
  }

  joinSuccess(data) {
    this.setCustomerUser(data);

    RoleSource.list({
      success: CurrentContextActions.roleListSuccess,
      error: CurrentContextActions.roleListError,
    });
  }

  joinError(...args) {
    this.userLoading = false;
    this.updateLoading();
    this.notifyError('error while joining user to customer', args);
  }

  roleListSuccess() {
    if (this.customerUser) {
      this.loadManagedProfiles(this.customerUser.id);
    }

    this.userLoading = false;
    this.updateLoading();
  }

  roleListError(...args) {
    this.userLoading = false;
    this.updateLoading();
    this.notifyError('error while Listing Roles of current user', args);
  }

  fetchCustomerUserSuccess(data) {
    this.setCustomerUser(data);

    if (this.customerUser) {
      this.loadManagedProfiles(this.customerUser.id);
    }

    this.userLoading = false;
    this.updateLoading();
  }

  fetchCustomerUserError(...args) {
    this.userLoading = false;
    this.updateLoading();
    this.notifyError('error while fetching current customer user', args);
  }

  fetchCustomerSuccess(data) {
    setTimeout(() => {
      this.customer = new Customer(data.customer ? data.customer : data);
      this.populateWindowCustomer();

      this.customerLoading = false;
      this.updateLoading();
      this.emitChange();
    }, 0);
  }

  fetchCustomerError(...args) {
    this.customerLoading = false;
    this.updateLoading();
    this.notifyError('error while fetching current customer', args);
  }

  loadManagedProfiles(id) {
    this.managedUsersLoading = true;
    this.updateLoading();

    return uhApiClient.get({
      url: managedUsersUrl(id),
      data: {
        fields: [
          'active_membership_id',
          'active_membership_tier_id',
          'permitted_membership_ids',
          'note',
          'agreement_details',
        ],
      },
      success: CurrentContextActions.loadManagedProfilesSuccess,
      error: CurrentContextActions.loadManagedProfilesError,
    });
  }

  loadManagedProfilesSuccess(data) {
    // Avoid potential dispatch in a dispatch errors by waiting to emit the change from the store
    // until after we finish handling the action.
    setTimeout(() => {
      // only set managedProfiles if the user is a client.
      if (
        data &&
        data.total_count > 0 &&
        this.customerUser.addManagedProfiles
      ) {
        this.customerUser = this.customerUser.addManagedProfiles(
          data.managed_customer_users
        );
      }

      this.populateWindowUser();
      this.managedUsersLoading = false;
      this.updateLoading();

      this.emitChange();
    }, 0);

    return false;
  }

  loadManagedProfilesError(...args) {
    this.managedUsersLoading = false;
    this.updateLoading();

    this.notifyError(
      'error while loading managed profiles for current customer user',
      args
    );
  }

  refreshCustomerUser(data) {
    setTimeout(() => {
      this.setCustomerUser(data);
      this.populateWindowUser(false);
      this.emitChange();
    }, 0);
  }

  setCustomerUser(data) {
    this.customerUser = null;

    if (data.type === 'Client') {
      this.customerUser = new Client(data);
    }
    if (['Admin', 'StaffAdmin', 'StaffMember'].includes(data.type)) {
      this.customerUser = new Staff(data);
    }
  }

  populateWindowUser(resetContext = true) {
    const { role, userRole } = getCurrentContext();

    window.user = merge(this.customerUser, {
      id: this.customerUser.user_id,
      customer_user_id: this.customerUser.id,
      role,
      user_role: userRole,
      name: this.customerUser.name(),
      managed_users: this.customerUser.managed_customer_users
        ? this.customerUser.managed_customer_users.map(p => p.toJS())
        : [],
    });
    if (resetContext) {
      // resetLogContext can use setcontext call it first
      setContext();
      resetLogContext();
    }
  }

  populateWindowCustomer() {
    const features = {};
    /* eslint-disable camelcase */
    // legacy rails changes.
    this.customer.features.forEach((enabled, feature_name) => {
      features[`${feature_name}_enabled`] = enabled;
    });

    const { preferences, tenant_preferences } = this.customer;

    window.customer = {
      id: this.customer.id,
      uuid: this.customer.uuid,
      created_at: this.customer.created_at,
      name: this.customer.name,
      external_url: this.customer.external_url,
      announcement_text: this.customer.announcement_text,
      logo: this.customer.logo,
      slug: `${this.customer.id}-${toSlug(this.customer.name || '')}`,

      policy_and_terms: this.customer.raw_policy_and_terms,
      browse_events_default_view: preferences.get(
        'browse_events_default_view',
        'list'
      ),
      charge_membership_cart_in_be: this.customer.corporate_account,
      client_payment_methods: this.customer.client_payment_methods,
      disable_client_scheduling: preferences.get(
        'disable_client_scheduling',
        false
      ),
      display_fees: tenant_preferences.display_fees,
      policy_updated_at: this.customer.policy_updated_at,
      active_payment_gateway: this.customer.active_payment_gateway,
      primary_payment_gateway: this.customer.primary_payment_gateway,
      restrict_editing: tenant_preferences.restrict_editing,
      enable_staff_member_refund: tenant_preferences.enable_staff_member_refund,
      tz_name: this.customer.time_zone, // this should really be time_zone to match the customer object, but the window version is tz_name
      tz_shortname: this.customer.time_zone
        ? moment().tz(this.customer.time_zone).format('z')
        : '',

      features: this.customer.features.toJS(),
      ...features,
      // staffMember/staffAdmin/Admin only
      paysafe_onboarding_step: this.customer.paysafe_onboarding_step,
      bank_account_validated: this.customer.bank_account_validated,
      paysafe_account_status: this.customer.paysafe_account_status,

      use_paysafe: this.customer.active_payment_gateway === 'paysafe',
      use_stripe: this.customer.active_payment_gateway === 'stripe',

      // HACK: we should add this to the Customer model
      helpdesk_url: isDBatTheme()
        ? 'https://dbathubhelp.upperhand.com'
        : 'https://help.upperhand.com/',

      event_checkout_methods: preferences.get('event_checkout'),
      mode_report_labels: this.customer.mode_report_labels,
      instructor: this.customer.instructor,
      membership_agreement: this.customer.raw_membership_agreement,
      agreement_updated_at: this.customer.agreement_updated_at,
      preferences: preferences.toJS(),
      customized_emails: this.customer.customized_emails,
    };

    // resetLogContext can use setcontext call it first
    setContext();
    resetLogContext();
  }

  handleUpdateSuccess(data) {
    this.setCustomerUser(data);
    this.isSaving = false;
    MessageWindowActions.addMessage.defer('Updated preferences successfully.');
  }

  acceptTermsSuccess(data) {
    const { accepted_customer_terms_at, accepted_upper_hand_terms_at } = data;

    this.customerUser = this.customerUser.merge({
      accepted_customer_terms_at,
      accepted_upper_hand_terms_at,
    });

    this.openDialog = false;
    MessageWindowActions.addMessage.defer('Accepted terms successfully.');
  }

  handleUpdateError(...args) {
    this.isSaving = false;
    this.notifyError('error while updating current customer user', args);
  }

  async updatePreferences() {
    if (!currentUser().isAdmin()) {
      let type = '';
      if (this.customerUser.type === 'Client') {
        type = 'client';
      } else if (this.customerUser.type === 'StaffMember') {
        type = 'staff_member';
      } else {
        type = 'staff_admin';
      }

      this.isSaving = true;
      uhApiClient.put({
        url: `customer_users/${this.customerUser.id}`,
        data: JSON.stringify({
          attributes: merge(await this.customerUser.toServer(false), { type }),
        }),
        success: CurrentContextActions.updateSuccess,
        error: CurrentContextActions.updateError,
      });
    }
  }

  changePreferences([keypath, value, specification]) {
    if (keypath === 'sessionReminderMethods') {
      this.customerUser = this.customerUser.updateIn(
        ['preferences', keypath],
        item => (value ? item.add(specification) : item.delete(specification))
      );
    } else {
      this.customerUser = this.customerUser.setIn(
        ['preferences', ...(Array.isArray(keypath) ? keypath : [keypath])],
        value
      );
    }
  }
}

export default alt.createStore(CurrentContextStore, 'CurrentContextStore');
