import { List } from 'immutable';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import Client from 'shared/records/Client.jsx';
import * as ClientSource from 'sources/ClientSource';
import POSActions from 'point_of_sale/actions/POSActions.jsx';
import POSCheckoutActions from 'point_of_sale/actions/POSCheckoutActions.jsx';
import POSClientActions from 'point_of_sale/actions/POSClientActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import UserValidator from 'shared/utils/UserValidator.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { isEmailValid } from 'shared/utils/UserUtils.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';

export const ACCOUNT_CREATION = 'accountCreation';
export const EXISTING_ACCOUNT_CONFIRMATION = 'existingAccountConfirmation';
export const EXISTING_ACCOUNT_UNARCHIVE = 'existingAccountUnarchive';
export const MORE_INFORMATION = 'moreInformation';
export const PROFILE_CREATION = 'profileCreation';
export const FETCHING_MANAGING_USER = 'fetchingManagingUser';
export const PROFILE_SELECTED = 'profileSelected';

const REQUIRED_CLIENT_FIELDS = List([
  'date_of_birth',
  'email',
  'first_name',
  'gender',
  'last_name',
  'phone',
]);

const REQUIRED_PROFILE_FIELDS = List([
  'date_of_birth',
  'first_name',
  'gender',
  'last_name',
]);

const url = id => (id ? `customer_users/${id}` : 'customer_users');

class POSClientStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      reset: [
        POSActions.ADD_CLIENT_CLICK,
        POSActions.CLIENT_BACK_CLICKED,
        POSCheckoutActions.CLOSE_BUTTON_CLICKED,
      ],

      updateClientRecord: POSClientActions.FORM_UPDATED,
      updateCurrentProfile: POSClientActions.MANAGED_PROFILE_FORM_UPDATED,

      beginAccountCreationSave: POSClientActions.ACCOUNT_CREATION_SAVE,
      cancelAddProfiles: POSClientActions.CANCEL_ADD_PROFILES,

      startAddProfile: POSClientActions.ADD_PROFILE_CLICK,
      cancelAddProfile: POSClientActions.CANCEL_ADD_PROFILE_CLICK,
      addProfile: POSClientActions.ADD_PROFILE,
      removeProfile: POSClientActions.REMOVE_PROFILE,
      selectProfile: POSClientActions.PROFILE_SELECTED,

      createOrUpdateClient: [
        POSClientActions.SAVE_CLIENT,
        POSClientActions.MORE_INFORMATION_SAVE_CLICKED,
      ],

      createOrUpdateClientSuccess:
        POSClientActions.CREATE_OR_UPDATE_CLIENT_SUCCESS,
      createClientError: POSClientActions.CREATE_CLIENT_ERROR,
      updateClientError: POSClientActions.UPDATE_CLIENT_ERROR,

      checkEmailStatus: POSClientActions.EMAIL_BLURRED,
      setEmailStatus: POSClientActions.EMAIL_CHECK_SUCCESS,
      handleEmailCheckError: POSClientActions.EMAIL_CHECK_ERROR,

      useExistingAccount: POSClientActions.EXISTING_ACCOUNT_CONTINUE,
      cancelExistingAccount: POSClientActions.EXISTING_ACCOUNT_CANCEL,

      cancelMoreInformation: POSClientActions.MORE_INFORMATION_CANCEL_CLICKED,

      selectClient: POSActions.CLIENT_SELECTED,

      fetchManagingUserSuccess: POSClientActions.FETCH_MANAGING_USER_SUCCESS,
      fetchManagingUserError: POSClientActions.FETCH_MANAGING_USER_ERROR,

      unarchiveClient: POSClientActions.UNARCHIVE_CLIENT,
      unarchiveClientSuccess: POSClientActions.CLIENT_UNARCHIVED_SUCCESS,
      unarchiveClientError: POSClientActions.CLIENT_UNARCHIVED_ERROR,
    });
  }

  reset() {
    this.client = new Client({ login_enabled: true, gender: 'female' });
    this.originalClient = null;
    this.existingAccount = false;

    this.currentProfile = new Client({ gender: 'female' });
    this.managedProfiles = List();
    this.idCounter = -1;
    this.currentStep = ACCOUNT_CREATION;

    this.isSaving = false;
    this.addingProfile = false;

    this.attemptingAccountCreationSave = false;

    this.emailTypingTimeout = null;

    this.clientFieldErrors = new FieldErrors();
    this.currentProfileFieldErrors = new FieldErrors();
  }

  updateClientRecord([field, value]) {
    this.client = this.client.set(field, value);

    if (field === 'email') {
      clearTimeout(this.emailTypingTimeout);
      this.emailTypingTimeout = setTimeout(() => this.checkEmailStatus(), 400);
    }

    if (this.clientFieldErrors.hasErrors()) {
      this.validateClient();
    }
  }

  updateCurrentProfile([field, value]) {
    this.currentProfile = this.currentProfile.set(field, value);

    if (field === 'date_of_birth' && this.currentProfile.underThirteen()) {
      this.currentProfile = this.currentProfile.set('login_enabled', false);
      this.currentProfile = this.currentProfile.set('email', '');
    }

    if (field === 'login_enabled' && value === false) {
      this.currentProfile = this.currentProfile.set('email', '');
    }

    if (this.currentProfileFieldErrors.hasErrors()) {
      this.validateCurrentProfile();
    }
  }

  validateClient() {
    const validator = new UserValidator(this.client);

    this.clientFieldErrors = validator.validate(
      REQUIRED_CLIENT_FIELDS,
      false
    ).errors;
    this.clientIsValid = !this.clientFieldErrors.hasErrors();
  }

  validateCurrentProfile() {
    const validator = new UserValidator(this.currentProfile);

    this.currentProfileFieldErrors = validator.validate(
      REQUIRED_PROFILE_FIELDS,
      false
    ).errors;
    this.currentProfileIsValid = !this.currentProfileFieldErrors.hasErrors();
  }

  beginAccountCreationSave() {
    if (this.attemptingAccountCreationSave) {
      return false;
    }

    this.attemptingAccountCreationSave = true;

    if (this.client.email) {
      return this.checkEmailStatus();
    }

    return this.accountCreationSave();
  }

  accountCreationSave() {
    this.attemptingAccountCreationSave = false;

    this.validateClient();

    if (this.clientFieldErrors.isEmpty()) {
      this.currentStep = PROFILE_CREATION;
    }
  }

  cancelAddProfiles() {
    this.currentStep = ACCOUNT_CREATION;
  }

  startAddProfile() {
    this.addingProfile = true;
  }

  cancelAddProfile() {
    this.clearCurrentProfile();
  }

  addProfile() {
    this.validateCurrentProfile();

    if (this.currentProfileFieldErrors.isEmpty()) {
      if (this.currentProfile.id === null) {
        this.managedProfiles = this.managedProfiles.push(
          this.currentProfile.set('id', this.idCounter)
        );
        this.idCounter -= 1;
      } else {
        const index = this.managedProfiles.findKey(
          p => p.id === this.currentProfile.id
        );
        this.managedProfiles = this.managedProfiles.set(
          index,
          this.currentProfile
        );
      }

      this.clearCurrentProfile();
    }
  }

  removeProfile(id) {
    if (this.isSaving) {
      return false;
    }

    const index = this.managedProfiles.findKey(p => p.id === id);

    this.managedProfiles = this.managedProfiles.delete(index);

    if (this.currentProfile.id === id) {
      return this.clearCurrentProfile();
    }

    return false;
  }

  selectProfile(id) {
    if (this.isSaving) {
      return false;
    }

    const managedProfile = this.managedProfiles.find(p => p.id === id);

    this.currentProfile = managedProfile;
    this.addingProfile = true;

    return this.validateCurrentProfile();
  }

  async createOrUpdateClient() {
    this.validateClient();

    if (this.clientFieldErrors.isEmpty()) {
      const payload = await this.client.toServer();

      payload.managed_clients_attributes = await Promise.all(
        this.managedProfiles.map(p => p.toServer()).toJS()
      );
      payload.type = 'client';

      this.isSaving = true;

      if (this.client.id) {
        return uhApiClient.put({
          url: url(this.client.id),
          data: JSON.stringify({ attributes: payload }),
          success: POSClientActions.createOrUpdateClientSuccess,
          error: POSClientActions.updateClientError,
        });
      }

      return uhApiClient.post({
        url: url(),
        data: JSON.stringify({ attributes: payload }),
        success: POSClientActions.createOrUpdateClientSuccess,
        error: POSClientActions.createClientError,
      });
    }

    return false;
  }

  createOrUpdateClientSuccess(data) {
    this.client = new Client(data);
  }

  createClientError(...args) {
    this.isSaving = false;
    this.notifyError('error while adding client', args);
  }

  updateClientError(...args) {
    this.isSaving = false;
    this.notifyError('error while updating client', args);
  }

  checkEmailStatus() {
    if (
      this.checkingEmail ||
      !this.client.email ||
      !isEmailValid(this.client.email)
    ) {
      return;
    }

    this.checkingEmail = true;

    // eslint-disable-next-line consistent-return
    return uhApiClient.get({
      url: 'clients',
      data: {
        search: this.client.email,
        statuses: ['archived', 'active', 'invited'],
      },
      success: POSClientActions.emailCheckSuccess,
      error: POSClientActions.emailCheckError,
    });
  }

  setEmailStatus(data) {
    this.checkingEmail = false;

    if (data.clients.length > 0) {
      const { archived, ...attributes } = data.clients[0];

      if (archived) {
        this.currentStep = EXISTING_ACCOUNT_UNARCHIVE;
        this.existingAccount = new Client(attributes);
      } else {
        this.clientFieldErrors = this.clientFieldErrors.add(
          'email',
          'validation.email.in_use'
        );
        this.currentStep = EXISTING_ACCOUNT_CONFIRMATION;
        this.existingAccount = new Client(
          merge(attributes, {
            login_enabled: true,
          })
        );
      }
    } else if (this.attemptingAccountCreationSave) {
      this.accountCreationSave();
    }
  }

  handleEmailCheckError(...args) {
    this.checkingEmail = false;
    this.notifyError('error while checking email availability', args);
  }

  useExistingAccount() {
    this.client = this.existingAccount;

    this.validateClient();

    if (this.clientFieldErrors.hasErrors()) {
      this.currentStep = MORE_INFORMATION;
      this.clientFieldErrors = this.clientFieldErrors.clear();
    } else if (this.client.id) {
      this.currentStep = PROFILE_SELECTED;
    } else {
      this.createOrUpdateClient();
    }
  }

  unarchiveClient() {
    this.isSaving = true;
    ClientSource.patch({
      id: this.existingAccount.id,
      recordAttributes: { archived: false },
      success: POSClientActions.clientUnarchivedSuccess,
      error: POSClientActions.clientUnarchivedError,
    });
  }

  unarchiveClientSuccess(data) {
    this.isSaving = false;
    this.client = new Client(data);
    this.currentStep = PROFILE_SELECTED;
  }

  unarchiveClientError() {
    this.isSaving = false;
    this.client = new Client({ login_enabled: true });
    this.currentStep = ACCOUNT_CREATION;
  }

  cancelExistingAccount() {
    this.existingAccount = null;
    this.client = this.client.set('email', null);
    this.currentStep = ACCOUNT_CREATION;
  }

  cancelMoreInformation() {
    this.reset();
  }

  selectClient(client) {
    this.reset();

    this.client = client;
    this.originalClient = client;
    this.existingAccount = client;

    if (client.managing_customer_user_id) {
      this.fetchManagingUser(client.managing_customer_user_id);
    } else {
      this.checkForMoreInfo();
    }
  }

  checkForMoreInfo() {
    this.validateClient();

    if (this.clientFieldErrors.hasErrors()) {
      this.currentStep = MORE_INFORMATION;
      this.clientFieldErrors = this.clientFieldErrors.clear();
    } else {
      this.currentStep = PROFILE_SELECTED;
    }
  }

  fetchManagingUser(customerUserId) {
    this.currentStep = FETCHING_MANAGING_USER;

    return ClientSource.fetch({
      id: customerUserId,
      params: {
        fields: [
          'active_membership_id',
          'active_membership_tier_id',
          'total_account_credit',
        ],
      },
      success: POSClientActions.fetchManagingUserSuccess,
      error: POSClientActions.fetchManagingUserError,
    });
  }

  fetchManagingUserSuccess(data) {
    this.client = new Client(data);
    this.existingAccount = this.client;

    this.checkForMoreInfo();
  }

  fetchManagingUserError(...args) {
    this.notifyError(
      `error while fetching managing user for ${this.client.name}`,
      args
    );
  }

  clearClient() {
    this.client = new Client({ login_enabled: true, gender: 'female' });
    this.clientFieldErrors = this.clientFieldErrors.clear();
  }

  clearCurrentProfile() {
    this.currentProfile = new Client();
    this.addingProfile = false;
    this.currentProfileFieldErrors = this.currentProfileFieldErrors.clear();
  }
}

export default alt.createStore(POSClientStore, 'POSClientStore');
