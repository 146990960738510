import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

const PRICING_PER_INTERVAL = {
  0: '.pricing_once',
  1: '.pricing_monthly',
  3: '.pricing_quarterly',
  12: '.pricing_annually',
};

function MembershipPrice({ membership }) {
  if (membership.tiered) {
    return (
      <FormattedMessage
        id={messageId('.tiers_count', __filenamespace)}
        values={{ count: membership.membership_tier_count }}
      />
    );
  }

  if (membership.isFree()) {
    return <FormattedMessage id={messageId('.free', __filenamespace)} />;
  }

  const price = <FormattedCurrency value={membership.price} fromCents />;
  const interval = (
    <FormattedMessage
      id={messageId(
        PRICING_PER_INTERVAL[membership.interval_months],
        __filenamespace
      )}
    />
  );

  if (membership.join_fee > 0) {
    return (
      <FormattedMessage
        id={messageId('.price_with_join_fee', __filenamespace)}
        values={{
          price,
          interval,
          joinFee: <FormattedCurrency value={membership.join_fee} fromCents />,
        }}
      />
    );
  }
  return (
    <FormattedMessage
      id={messageId('.price', __filenamespace)}
      values={{ price, interval }}
    />
  );
}

export default MembershipPrice;
