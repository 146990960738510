import { List } from 'immutable';
import RegistrationResponse from 'shared/records/RegistrationResponse';
import CustomerEvent from 'event_mgmt/shared/records/CustomerEvent.jsx';
import EventTranslator from 'event_mgmt/shared/translators/EventTranslator.jsx';
import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { onSuccess, onError } from 'sources/handlers';
import DataStoreActions from './EventActions';

const parserTo = { type: CustomerEvent, paginationKey: 'events' };
const parserRegResponseTo = {
  type: RegistrationResponse,
  paginationKey: 'clients',
};

const parser = new Parser(parserTo);
const parserRegResponse = new Parser(parserRegResponseTo);

// override the default single parser, to use the EventTranslator
parser.parseSingle = data => new EventTranslator(data).toClient();

export const fetch = ({ id, success, error }) => {
  uhApiClient.get({
    url: `${parserTo.paginationKey}/${id}`,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.fetchSuccess, success])
    ),
    error: onError(DataStoreActions.fetchError, error),
  });
};

export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: parserTo.paginationKey,
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error: onError(DataStoreActions.listError, error),
  });
};

export const listEnrolled = ({ clientId, params, success, error }) => {
  uhApiClient.get({
    url: `customer_users/${clientId}/enrolled_events`,
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error: onError(DataStoreActions.listError, error),
  });
};

export const fetchRevenue = ({ id, success, error }) => {
  uhApiClient.get({
    url: `${parserTo.paginationKey}/${id}/revenue`,
    success: onSuccess(data => parser.parseNone(data), success),
    error: onError(DataStoreActions.fetchError, error),
  });
};

export const postTransferClient = ({ params, success, error }) => {
  uhApiClient.post({
    url: 'clients/transfer_client',
    data: JSON.stringify(params),
    success: onSuccess(data => parser.parseNone(data), success),
    error,
  });
};

export const listRegistrationResponses = ({
  eventId,
  params,
  success,
  error,
}) => {
  uhApiClient.get({
    url: `events/${eventId}/registration_responses`,
    data: params,
    success: onSuccess(
      data => parserRegResponse.parsePagination(data),
      List([success])
    ),
    error: onError(error),
  });
};
