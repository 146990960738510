import debounce from 'lodash.debounce';
import { OrderedSet, Map } from 'immutable';

import uhApiClient from 'shared/helpers/uhApiClient.jsx';

import Client from 'shared/records/Client.jsx';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import { EmailCampaignsSource } from 'sources';

import MarketingEmailStatisticsActions from 'containers/marketing/statistics/Actions';

class MarketingEmailStatisticsStore extends UpperHandStore {
  constructor() {
    super();
    this.reset();
    this.debouncedSearch = debounce(this.listEmailsStatistics, 500);
    this.debouncedClientsSearch = debounce(this.listCampaignClients, 500);
    this.bindListeners({
      handlePageSelect: MarketingEmailStatisticsActions.handlePageSelect,
      searchMarketingEmailStatistics:
        MarketingEmailStatisticsActions.searchMarketingEmailStatistics,

      toggleFiltersDrawer: MarketingEmailStatisticsActions.toggleFiltersDrawer,
      handleFilterChange: MarketingEmailStatisticsActions.handleFilterChange,
      applyFilters: MarketingEmailStatisticsActions.applyFilters,
      resetFilters: MarketingEmailStatisticsActions.resetFilters,
      removeFilter: MarketingEmailStatisticsActions.removeFilter,

      listEmailsStatistics:
        MarketingEmailStatisticsActions.listMarketingEmailStatistics,
      listEmailsStatisticsSuccess:
        MarketingEmailStatisticsActions.listMarketingEmailStatisticsSuccess,
      listEmailsStatisticsError:
        MarketingEmailStatisticsActions.listMarketingEmailStatisticsError,

      openStatisticsDrawer:
        MarketingEmailStatisticsActions.openStatisticsDrawer,
      closeStatisticsDrawer:
        MarketingEmailStatisticsActions.closeStatisticsDrawer,
      setActiveTab: MarketingEmailStatisticsActions.setActiveTab,
      listCampaignClients: MarketingEmailStatisticsActions.listCampaignClients,
      listCampaignClientsSuccess:
        MarketingEmailStatisticsActions.listCampaignClientsSuccess,
      listCampaignClientsError:
        MarketingEmailStatisticsActions.listCampaignClientsError,
      clientsPageSelect: MarketingEmailStatisticsActions.clientsPageSelect,
      clientsSearchTextChange:
        MarketingEmailStatisticsActions.clientsSearchTextChange,
    });
  }

  reset() {
    this.isLoading = false;
    this.clientsLoading = false;
    this.clientsLoading = false;
    this.filtersDrawerOpen = false;
    this.statisticsDrawerOpened = false;
    this.selectedCampaignId = null;
    this.activeTab = 'sent';
    this.search = '';
    this.clientSearchText = '';
    this.filters = Map();
    this.appliedFilters = Map();
    this.emailsStatisticsIds = OrderedSet();
    this.clients = Map({
      sent: Map(),
      opened: Map(),
      unsubscribed: Map(),
    });
    this.clientsOpened = Map();
    this.unsubscribedClients = Map();

    this.resetAllPagination();
  }

  getParams() {
    const filters = {
      status: 'sent',
      created_at_max: this.appliedFilters.get('date_created')?.to || null,
      created_at_min: this.appliedFilters.get('date_created')?.from || null,
      scheduled_at_max: this.appliedFilters.get('sent_at')?.to || null,
      scheduled_at_min: this.appliedFilters.get('sent_at')?.from || null,
    };

    const params = {
      search: this.search,
      page: this.pagination.getIn(['emailsStatistics', 'page']),
      per_page: this.pagination.getIn(['emailsStatistics', 'perPage']),
      fields: ['subject', 'created_by'],
      ...filters,
    };

    return params;
  }

  resetAllPagination() {
    this.pagination = Map({
      emailsStatistics: Map({
        page: 1,
        perPage: 15,
        totalCount: 0,
      }),
      clients: Map({
        sent: Map({ page: 1, perPage: 15, totalCount: 0 }),
        opened: Map({ page: 1, perPage: 15, totalCount: 0 }),
        unsubscribed: Map({ page: 1, perPage: 15, totalCount: 0 }),
      }),
    });
  }

  resetClientsPagination() {
    this.pagination = this.pagination.set(
      'clients',
      Map({
        sent: Map({ page: 1, perPage: 15, totalCount: 0 }),
        opened: Map({ page: 1, perPage: 15, totalCount: 0 }),
        unsubscribed: Map({ page: 1, perPage: 15, totalCount: 0 }),
      })
    );
  }

  openStatisticsDrawer({ campaignId }) {
    this.selectedCampaignId = campaignId;
    this.statisticsDrawerOpened = true;

    this.resetClientsPagination();
    this.listCampaignClients({
      event: 'sent',
    });
  }

  closeStatisticsDrawer() {
    this.selectedCampaignId = null;
    this.statisticsDrawerOpened = false;
    this.activeTab = 'sent';
    this.clients = Map({
      sent: Map(),
      opened: Map(),
      unsubscribed: Map(),
    });

    this.resetClientsPagination();
  }

  setActiveTab(tab) {
    this.activeTab = tab;
    this.clientSearchText = '';

    this.listCampaignClients({
      event: tab,
    });
  }

  listEmailsStatistics() {
    this.isLoading = true;

    EmailCampaignsSource.list({
      params: this.getParams(),
      success:
        MarketingEmailStatisticsActions.listMarketingEmailStatisticsSuccess,
      error: MarketingEmailStatisticsActions.listMarketingEmailStatisticsError,
    });
  }

  listEmailsStatisticsSuccess({ campaigns, page, perPage, totalCount }) {
    this.pagination = this.pagination
      .setIn(['emailsStatistics', 'page'], page)
      .setIn(['emailsStatistics', 'perPage'], perPage)
      .setIn(['emailsStatistics', 'totalCount'], totalCount);
    this.emailsStatisticsIds = campaigns.map(c => c.id);
    this.isLoading = false;
  }

  listEmailsStatisticsError(...args) {
    this.isLoading = false;
    this.notifyError('error listing email statistics', args);
  }

  handlePageSelect([page]) {
    this.pagination = this.pagination.setIn(['emailsStatistics', 'page'], page);
    this.listEmailsStatistics();
  }

  searchMarketingEmailStatistics(searchText) {
    this.search = searchText;
    this.pagination = this.pagination.setIn(['emailsStatistics', 'page'], 1);
    this.isLoading = true;
    this.debouncedSearch();
  }

  clientsSearchTextChange(searchText) {
    this.clientSearchText = searchText;
    this.clientsLoading = true;
    this.pagination = this.pagination.setIn(
      ['clients', this.activeTab, 'page'],
      1
    );
    this.debouncedClientsSearch({ event: this.activeTab });
  }

  clientsPageSelect([page, event]) {
    this.pagination = this.pagination.setIn(['clients', event, 'page'], page);
    this.listCampaignClients({ event });
  }

  listCampaignClients({ event = null }) {
    this.clientsLoading = true;

    const pagination = this.pagination.getIn(['clients', event || 'sent']);

    uhApiClient.get({
      url: `marketing/campaigns/${this.selectedCampaignId}/clients`,
      data: {
        event: event !== 'sent' ? event : null,
        per_page: pagination.get('perPage', 50),
        page: pagination.get('page', 1),
        search: this.clientSearchText,
      },
      success: {
        args: [event],
        action: MarketingEmailStatisticsActions.listCampaignClientsSuccess,
      },
      error: MarketingEmailStatisticsActions.listCampaignClientsError,
    });
  }

  listCampaignClientsSuccess(args) {
    const [
      {
        page,
        per_page: perPage,
        total_count: totalCount,
        customer_users: clients,
      },
      event = 'sent',
    ] = args;

    this.clientsLoading = false;
    this.clients = this.clients.set(
      event,
      Map(clients.map(client => [client.id, new Client(client)]))
    );
    this.pagination = this.pagination
      .setIn(['clients', event, 'page'], page)
      .setIn(['clients', event, 'perPage'], perPage)
      .setIn(['clients', event, 'totalCount'], totalCount);
  }

  listCampaignClientsError(...args) {
    this.clientsLoading = false;
    this.notifyError('Error listing campaign clients', args);
  }

  toggleFiltersDrawer() {
    this.filtersDrawerOpen = !this.filtersDrawerOpen;
  }

  handleFilterChange({ field, value }) {
    this.filters = this.filters.set(field, value);
  }

  applyFilters() {
    this.pagination = this.pagination.setIn(['emailsStatistics', 'page'], 1);
    this.appliedFilters = this.filters;
    this.listEmailsStatistics();
  }

  removeFilter(field) {
    this.filters = this.filters.delete(field);
    this.appliedFilters = this.appliedFilters.delete(field);
    this.listEmailsStatistics();
  }

  resetFilters() {
    this.filters = Map();
    this.appliedFilters = Map();
    this.listEmailsStatistics();
  }
}

export default alt.createStore(
  MarketingEmailStatisticsStore,
  'MarketingEmailStatisticsStore'
);
