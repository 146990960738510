import UHTheme from '_uh_theme.jsx';

import ARtheme from '../../../themes/athletic_republic/theme';
import DBatTheme from '../../../themes/dbat/theme';
import UpperHandTheme from '../../../themes/upper_hand/theme';
import HybridjaxTheme from '../../../themes/hybridjax/theme';
import JumpStartSportsTheme from '../../../themes/jump_start_sports/theme';
import TexasTwelveTheme from '../../../themes/texas_twelve/theme';
import PowerBallersTheme from '../../../themes/powerballers/theme';
import K25Theme from '../../../themes/k25/theme';
import CampPulseTheme from '../../../themes/camp_pulse/theme';

export const isARtheme = () => UHTheme.name === ARtheme.name;
export const isDBatTheme = () => UHTheme.name === DBatTheme.name;
export const isUpperHandTheme = () => UHTheme.name === UpperHandTheme.name;
export const isHybridJaxTheme = () => UHTheme.name === HybridjaxTheme.name;
export const isJumpStartSportsTheme = () =>
  UHTheme.name === JumpStartSportsTheme.name;
export const isTexasTwelveTheme = () => UHTheme.name === TexasTwelveTheme.name;
export const isPowerBallersTheme = () =>
  UHTheme.name === PowerBallersTheme.name;
export const isK25Theme = () => UHTheme.name === K25Theme.name;
export const isCampPulseTheme = () => UHTheme.name === CampPulseTheme.name;
export const currentThemeName = UHTheme.name;
