import React from 'react';
import altContainer from 'shared/hocs/altContainer.jsx';
import { injectIntl } from 'react-intl';
import { compose } from 'shared/utils/SharedUtils.js';
import { t } from 'shared/utils/LocaleUtils.js';
import {
  ContentModal,
  Grid,
  Typography,
  DateTimePicker,
} from '@upperhand/playmaker';
import { withStyles } from '@material-ui/styles';

import EditMembershipExpireActions from './EditMembershipExpireActions';
import EditMembershipExpireModalStore from './Store.js';

const styles = {
  typography: {
    paddingBottom: 14,
  },
};

function EditMembershipExpireModal({
  editMembershipExpireModalStore: {
    isOpen,
    isLoading,
    oldMembershipExpiryDate,
    newMembershipExpiryDate,
  },
  intl,
  classes,
}) {
  return (
    <ContentModal
      open={isOpen}
      onSuccess={EditMembershipExpireActions.addNewDate}
      onAbort={EditMembershipExpireActions.closeModal}
      title={t('.title', intl, __filenamespace)}
      successLabel={
        isLoading
          ? t('.loading', intl, __filenamespace)
          : t('.update', intl, __filenamespace)
      }
      cancelLabel={t('.cancel', intl, __filenamespace)}
      size="2x"
    >
      <Typography className={classes.typography}>
        {t('.description', intl, __filenamespace)}
      </Typography>

      <Grid container xs={12} spacing={2}>
        <Grid item xs={6}>
          <Typography>{t('.prevDate', intl, __filenamespace)}</Typography>
          <DateTimePicker
            autoOk
            disablePast
            type="date"
            fullWidth
            disabled
            placeholder="MM/DD/YYYY"
            value={oldMembershipExpiryDate}
            onChange={() => {}}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography>{t('.end_date', intl, __filenamespace)}</Typography>
          <DateTimePicker
            autoOk
            disablePast
            type="date"
            fullWidth
            placeholder="MM/DD/YYYY"
            onChange={value => {
              const { _d: newDate } = value;
              EditMembershipExpireActions.setExpireDate(newDate);
            }}
            value={newMembershipExpiryDate}
          />
        </Grid>
      </Grid>
    </ContentModal>
  );
}

export default compose(
  injectIntl,
  altContainer({
    stores: {
      editMembershipExpireModalStore: EditMembershipExpireModalStore,
    },
  }),
  withStyles(styles)
)(EditMembershipExpireModal);
