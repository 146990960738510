import { Record, Map } from 'immutable';
import { Image } from 'records';

import FieldErrors from 'shared/records/FieldErrors.jsx';

class RegistrationResponse extends Record({
  id: null,
  first_name: '',
  last_name: '',
  gender: '',
  managing_customer_user_id: null,
  managing_customer_user_name: '',
  profile_image: new Image(),
  active_membership_color: null,
  fields_total_count: 0,
  answers: [],
  has_subscription_balance_owed: false,
  errors: new FieldErrors(),
}) {
  constructor(obj = {}) {
    const updatedObj = {
      ...obj,
      answers: Map(
        obj.answers.map(a => [a.custom_registration_field_id, Map(a)])
      ),
    };
    super(updatedObj);
  }

  get fullName() {
    return `${this.first_name} ${this.last_name}`;
  }

  get shorthandGender() {
    return this.gender === 'male' ? 'M' : 'F';
  }

  validateAnswers() {
    let errors = new FieldErrors();

    this.answers.forEach((answer, fieldId) => {
      if (answer.get('required') && !answer.get('answer')) {
        errors = errors.add(fieldId, 'Required field');
      }
    });

    return this.set('errors', errors);
  }
}

export default RegistrationResponse;
