import { OrderedSet, Map, OrderedMap, List } from 'immutable';
import moment from 'moment-timezone';
import { v4 as uuid } from 'uuid';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import {
  ClientSource,
  MembershipSubscriptionSource,
  OrderSource,
  RegistrationSource,
  EventSource,
  SessionSource,
  AdditionalEmailsSource,
} from 'sources';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { currentUser, isEmailValid } from 'shared/utils/UserUtils.jsx';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { creditOperations } from 'user_management/shared/stores/EnrolledEventListStore.jsx';
import OrderSummaryDrawerActions from 'containers/reports/orderSummaryDrawer/Actions';
import Document from 'records/Document';
import { currentCustomer } from 'shared/utils/CustomerUtils';
import FieldErrors from 'shared/records/FieldErrors.jsx';

import CompletedEventSessionsActions from 'containers/events/admin/schedule/completed_event_sessions/Actions';
import FutureEventSessionsActions from 'containers/events/admin/schedule/future_event_sessions/Actions';
import ClassesListActions from 'containers/classes/classesList/Actions';
import CalendarActions from 'calendar/actions/CalendarActions.jsx';
import ClientProfileActions from './Actions';

export const SCHEDULE_TAB = 0;
export const ORDERS_TAB = 1;
export const PAYMENTS_TAB = 2;

const EVENTS_PER_PAGE = 30;

class ClientProfileBaseStore extends UpperHandStore {
  constructor() {
    super();

    this.clientIdsHistory = OrderedMap();
    this.actions = ClientProfileActions;
    this.hasQrIcon = true;
    this.reset();
  }

  reset() {
    this.profileDrawerOpen = false;
    this.showSchedulesDrawer = false;
    this.showOrdersDrawer = false;
    this.showPaymentsDrawer = false;
    this.isLoading = true;
    this.clientId = null;
    this.eventId = null;
    this.userId = null;
    this.accessToVault = false;
    this.subscriptionId = null;
    this.selectedSessionId = null;
    this.selectedEventId = null;
    this.managedProfileIds = OrderedSet();
    this.totalCredits = 0;
    this.totalAccountCredit = 0;
    this.totalCashCredits = 0;
    this.initialTotalCashCredits = 0;
    this.selectedTab = SCHEDULE_TAB;
    this.outstandingBalance = 0;
    this.orderIds = OrderedSet();
    this.tabLoading = false;
    this.upcomingLoading = false;
    this.pastLoading = false;
    this.eventsIds = OrderedSet();
    this.eventId = null;
    this.defaultSelectedTab = null;
    this.eventsLoading = false;
    this.sessionsLoading = false;
    this.orderDetailLoading = false;
    this.registrationIds = OrderedSet();
    this.sessionIds = OrderedSet();
    this.pastRegistrationIds = OrderedSet();
    this.registrationToRemove = null;
    this.eventForRemovingRegistration = null;
    this.creditOperation = undefined;
    this.waiveBalance = false;
    this.productId = null;
    this.pagination = Map({
      upcomingRegistrations: {
        page: 1,
        totalCount: 0,
        perPage: 10,
      },
      pastRegistrations: {
        page: 1,
        totalCount: 0,
        perPage: 10,
      },
      orders: {
        page: 1,
        totalCount: 0,
        perPage: 10,
      },
    });
    this.isFileUploadDrawer = false;
    this.document = new Document();

    this.additionalEmailsList = List();
    this.currentEmailData = Map({
      id: null,
      email: '',
      first_name: '',
      last_name: '',
    });
    this.fieldErrors = new FieldErrors();
    this.isValid = true;
    this.isFetchingAdditionalEmails = false;
    this.isSaving = false;
    this.isModalOpen = false;
    this.clientId = null;
  }

  mounted({ clientId, eventId, isInitialPoint = true, openDrawer = true }) {
    this.reset();
    this.clientId = parseInt(clientId, 10);
    this.eventId = eventId;
    this.isLoading = true;
    this.profileDrawerOpen = openDrawer;

    if (isInitialPoint) {
      this.clientIdsHistory = OrderedMap();
    }

    /* We need uniq index for storing duplicated client ids. Manage#1 -> Manager -> Manage#1
     * and when user goes back he can go through all opened profiles Manage#1 <- Manager <- Manage#1 */
    const index = uuid();
    this.clientIdsHistory = this.clientIdsHistory.set(index, {
      clientId,
      index,
    });

    ClientSource.fetch({
      id: clientId,
      params: {
        fields: [
          'total_credits',
          'agreement_details',
          'total_cash_credits',
          'total_account_credit',
        ],
      },
      success: this.actions.fetchSuccess,
      error: this.actions.fetchError,
    });

    this.handleAdditionalEmailsList(clientId);

    if (openDrawer) {
      this.listRegistrations(true);
    }
  }

  closeDrawer() {
    this.reset();
    this.clientIdsHistory = OrderedMap();
  }

  handleGoBack(clientId) {
    this.mounted({ clientId, isInitialPoint: false });
    /* We need to delete two last items because when user go back additional item
     * will be added to clientIdsHistory */
    this.clientIdsHistory = this.clientIdsHistory.delete(
      this.clientIdsHistory.last().index
    );
    this.clientIdsHistory = this.clientIdsHistory.delete(
      this.clientIdsHistory.last().index
    );
  }

  openScheduleDrawer({ clientId }) {
    this.clientId = clientId;
    this.listRegistrations(true);
    this.showSchedulesDrawer = true;
  }

  closeScheduleDrawer() {
    this.showSchedulesDrawer = false;
  }

  toggleOrdersDrawer({ clientId = null }) {
    if (clientId) {
      this.clientId = clientId;
    }

    this.showOrdersDrawer = !this.showOrdersDrawer;
  }

  togglePaymentsDrawer({
    clientId = null,
    userId = null,
    accessToVault = false,
  }) {
    if (clientId && userId) {
      this.clientId = clientId;
      this.userId = userId;
    }

    this.accessToVault = accessToVault;

    this.showPaymentsDrawer = !this.showPaymentsDrawer;
  }

  fetchSuccess(client) {
    this.clientId = client?.id;
    const isStaff = currentUser().isStaff();
    const isStaffOrManager =
      !client?.get('managed_by_id') || isStaff || !currentUser().managed_by_id;

    if (!client.managing_customer_user_id) {
      this.listManagedProfiles();
    }

    if (isStaffOrManager || currentUser().login_enabled) {
      this.fetchSubscription();

      if (isStaffOrManager) {
        this.fetchStatistic(client.managing_customer_user_id ?? client.id);
      }

      if (client.managing_customer_user_id && isStaff) {
        ClientSource.fetch({
          id: client.managing_customer_user_id,
          success: () => null,
          error: () => null,
        });
      }
    }
    this.userId = client.get('user_id');
    this.accessToVault = client.get('access_to_vault');
    this.isLoading = false;

    const totalCredits = client.get('total_credits');

    if (totalCredits) {
      this.totalCredits = totalCredits.get('unlimited', false)
        ? Infinity
        : totalCredits.get('total', 0);
    }

    this.totalAccountCredit = client.get('total_account_credit', 0);
    this.totalCashCredits = client.get('total_cash_credits', 0);
    this.initialTotalCashCredits = this.totalCashCredits;
    this.hasQrIcon = true;
  }

  fetchError(...args) {
    this.notifyError('Error fetching client', args);
  }

  tabSelected(tab) {
    this.selectedTab = tab;
  }

  listManagedProfiles() {
    ClientSource.list({
      customerUserId: this.clientId,
      params: {
        per_page: 50,
        managing_client_ids: [this.clientId],
        fields: ['agreement_details'],
      },
      success: this.actions.listManagedProfilesSuccess,
      error: this.actions.listManagedProfilesError,
    });
  }

  listManagedProfilesSuccess({ clients }) {
    this.managedProfileIds = OrderedSet(clients.map(client => client.id));
  }

  listManagedProfilesError(...args) {
    this.notifyError('Error listing managed profiles: ', args);
  }

  fetchSubscription() {
    if (!this.isViewingClient) {
      return;
    }

    MembershipSubscriptionSource.list({
      params: {
        client_ids: [this.clientId],
        per_page: 1, // expires_at sort by default, so receiving the most recent subscription
        fields: [
          'membership',
          'has_embed_product',
          'membership_tier',
          'last_payment_at',
        ],
      },
      success: this.actions.fetchSubscriptionSuccess,
      error: this.actions.fetchSubscriptionError,
    });
  }

  fetchSubscriptionSuccess({ membership_subscriptions: subscriptions }) {
    const subscription = subscriptions?.first();

    if (subscription) {
      const amusementCredits = subscription.amusement_credits || 0;

      this.subscriptionId = subscription.id;
      this.totalCashCredits = this.initialTotalCashCredits + amusementCredits;
    }
  }

  clientSaved({ client, attributes }) {
    const recordAttributes = {
      type: 'client',
      role: 'standard',
      first_name: client.first_name,
      last_name: client.last_name,
      date_of_birth: client.date_of_birth
        ? client.date_of_birth.tz(customerTZ(), true)
        : null,
      access_to_vault: client.access_to_vault,
      ...attributes,
    };

    if (client.address) {
      recordAttributes.address = client.address;
    }

    ClientSource.put({
      id: this.clientId,
      fields: [
        'managing_customer_user_name',
        'managing_customer_user_email',
        'managing_customer_user_id',
        'note',
      ],
      recordAttributes,
      success: this.actions.clientUpdateSuccess,
      error: this.actions.clientUpdateError,
    });
  }

  handleUploadImageOrDocument(client, uploadAttr) {
    const recordAttributes = {
      ...uploadAttr,
      type: 'client',
      role: 'standard',
      first_name: client.first_name,
      last_name: client.last_name,
      date_of_birth: client.date_of_birth
        ? client.date_of_birth.tz(customerTZ(), true)
        : null,
      access_to_vault: client.access_to_vault,
    };
    if (client.address) {
      recordAttributes.address = client.address;
    }

    ClientSource.put({
      id: this.clientId,
      fields: [
        'managing_customer_user_name',
        'managing_customer_user_email',
        'managing_customer_user_id',
        'note',
      ],
      recordAttributes,
      success: this.actions.clientUpdateSuccess,
      error: this.actions.clientUpdateError,
    });
  }

  async imageSelected({ client, image }) {
    // eslint-disable-next-line no-console
    console.log('Base Store imageSelected image', image);
    const uploadedImage = await image.toServer();
    // eslint-disable-next-line no-console
    console.log('Base Store imageSelected uploadedImage', image);
    this.handleUploadImageOrDocument(client, { profile_image: uploadedImage });
  }

  async documentSelected({ client, document }) {
    const uploadedPdf = await document.toServer();
    this.handleUploadImageOrDocument(client, { document: uploadedPdf });
  }

  clientUpdateSuccess() {
    this.isFileUploadDrawer = false;
    const message = new TranslatableMessage({
      id: 'containers.clientProfile.Store.client_update_successful',
    });

    MessageWindowActions.addMessage.defer(message);

    // TODO: Need a better way to handle this case.
    this.editingName = false;
  }

  clientUpdateError(...args) {
    this.notifyError('Error updating client:', args);
  }

  // eslint-disable-next-line class-methods-use-this
  fetchStatistic(clientId) {
    uhApiClient.get({
      url: 'ledger/balances_statistics',
      data: {
        client_ids: [clientId],
        balance_remaining_max: -1,
      },
      success: this.actions.fetchStatisticSuccess,
      error: this.actions.fetchStatisticError,
    });
  }

  fetchStatisticSuccess({ outstanding_balance: outstandingBalance }) {
    this.outstandingBalance = Math.abs(outstandingBalance);
  }

  fetchStatisticError(...args) {
    this.notifyError('Error getting balance statistic:', args);
  }

  /* Orders tab actions */
  listOrders() {
    const pagination = this.pagination.get('orders');

    this.tabLoading = true;

    const params = {
      page: pagination.page,
      per_page: pagination.perPage,
      fields: ['order_items', 'coupon', 'note'],
      client_ids: this.clientId,
      status: 'completed',
    };

    OrderSource.list({
      params,
      success: this.actions.listOrdersSuccess,
      error: this.actions.listOrdersError,
    });
  }

  listOrdersSuccess({ orders, page, perPage, totalCount }) {
    this.orderIds = orders.map(order => order.id).toOrderedSet();
    this.pagination = this.pagination.set('orders', {
      page,
      perPage,
      totalCount,
    });
    this.tabLoading = false;
  }

  listOrdersError(...args) {
    this.notifyError('Error getting orders:', args);
  }

  listOrdersPrevNext(pagination) {
    const { totalCount } = this.pagination.get('orders');

    this.pagination = this.pagination.set('orders', {
      page: pagination[0],
      perPage: pagination[1],
      totalCount,
    });
    this.listOrders();
  }

  /* Schedule tab actions */
  listAdditionalInfo(registrations) {
    // First we need do .toSet because it removes duplicated ids
    const sessionIds = registrations
      .map(reg => reg.session_id)
      .toSet()
      .toArray();
    if (sessionIds.length) {
      this.sessionsLoading = true;
      SessionSource.list({
        params: {
          ids: sessionIds,
          fields: ['note', 'client_note'],
        },
        success: this.actions.listSessionsSuccess,
      });
    }
  }

  listEnrolledEvents() {
    this.eventsLoading = true;
    EventSource.listEnrolled({
      clientId: this.clientId,
      params: {
        per_page: EVENTS_PER_PAGE,
        fields: ['team_type'],
      },
      success: this.actions.listEventsSuccess,
    });
  }

  listRegistrations(isUpcoming) {
    const upcomingPagination = this.pagination.get('upcomingRegistrations');
    const pastPagination = this.pagination.get('pastRegistrations');
    const params = {
      page: isUpcoming ? upcomingPagination.page : pastPagination.page,
      per_page: isUpcoming
        ? upcomingPagination.perPage
        : pastPagination.perPage,
      client_ids: this.clientId,
      fields: ['paid'],
      event_ids: this.eventId,
      sort_direction: isUpcoming ? 'asc' : 'desc',
    };

    if (isUpcoming) {
      this.upcomingLoading = true;
      params.start_time = moment().toISOString();
    } else {
      this.pastLoading = true;
      params.end_time = moment().toISOString();
    }

    RegistrationSource.list({
      params,
      success: isUpcoming
        ? this.actions.listRegistrationsSuccess
        : this.actions.listPastRegistrationsSuccess,
      error: this.actions.listRegistrationsError,
    });
  }

  listRegistrationsSuccess({ registrations, page, perPage, totalCount }) {
    /*
      In case when we delete registration item we fetching list again because list is updated.
      And when we delete last item on current page and this page isn't first we need to go back and list registrations.
    */
    if (!registrations.size && page > 1) {
      this.listRegistrationsPrevNext({
        page: page - 1,
        perPage,
        isUpcoming: true,
      });
      return;
    }

    this.registrationIds = registrations
      .map(registration => registration.id)
      .toOrderedSet();
    this.pagination = this.pagination.set('upcomingRegistrations', {
      page,
      perPage,
      totalCount,
    });
    if (registrations.size) {
      this.listAdditionalInfo(registrations);

      // fetch enrolled events only when client have registrations
      if (this.eventsIds.size === 0 && this.clientId) {
        this.listEnrolledEvents();
      }
    } else {
      this.upcomingLoading = false;
    }
  }

  listPastRegistrationsSuccess({ registrations, page, perPage, totalCount }) {
    /*
      In case when we delete registration item we fetching list again because list is updated.
      And when we delete last item on current page and this page isn't first we need to go back and list registrations.
    */
    if (!registrations.size && page > 1) {
      this.listRegistrationsPrevNext({
        page: page - 1,
        perPage,
        isUpcoming: false,
      });
      return;
    }

    this.pastRegistrationIds = registrations
      .map(registration => registration.id)
      .toOrderedSet();
    this.pagination = this.pagination.set('pastRegistrations', {
      page,
      perPage,
      totalCount,
    });
    if (registrations.size) {
      this.listAdditionalInfo(registrations);

      // fetch enrolled events only when client have registrations
      if (this.eventsIds.size === 0 && this.clientId) {
        this.listEnrolledEvents();
      }
    } else {
      this.pastLoading = false;
    }
  }

  listRegistrationsPrevNext({ page, perPage, isUpcoming }) {
    const paginationType = isUpcoming
      ? 'upcomingRegistrations'
      : 'pastRegistrations';
    const { totalCount } = this.pagination.get(paginationType);

    this.pagination = this.pagination.set(paginationType, {
      page,
      perPage,
      totalCount,
    });
    this.listRegistrations(isUpcoming);
  }

  listEventsSuccess({ events, page, totalCount }) {
    this.eventsIds = this.eventsIds.concat(
      events.map(event => event.id).toOrderedSet()
    );

    if (totalCount > this.eventsIds.size && events.size) {
      EventSource.listEnrolled({
        clientId: this.clientId,
        params: {
          page: page + 1,
          per_page: EVENTS_PER_PAGE,
          fields: ['team_type'],
        },
        success: this.actions.listEventsSuccess,
      });
    } else {
      this.eventsLoading = false;
      this.disableLoading();
    }
  }

  listSessionsSuccess({ sessions }) {
    this.sessionIds = sessions.map(session => session.id).toOrderedSet();
    this.sessionsLoading = false;
    this.disableLoading();
  }

  disableLoading() {
    if (!this.eventsLoading && this.upcomingLoading) {
      this.upcomingLoading = false;
    }
    if (!this.eventsLoading && this.pastLoading) {
      this.pastLoading = false;
    }
  }

  listRegistrationsError(...args) {
    this.notifyError('Error getting session list:', args);
  }

  /* Order details drawer */
  // eslint-disable-next-line class-methods-use-this
  openOrderDetailsDrawer(orderId) {
    OrderSource.fetch({
      id: orderId,
      params: {
        fields: ['order_items', 'coupon'],
      },
      success: this.actions.fetchOrderSuccess,
      error: this.actions.fetchOrderError,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  fetchOrderSuccess(response) {
    OrderSummaryDrawerActions.openDrawer.defer(response.id);
  }

  fetchOrderError(...args) {
    this.notifyError('Error getting order details:', args);
  }

  /* Session summary drawer */
  openSessionSummaryDrawer({
    sessionId,
    eventId,
    defaultSelectedTab = 'details',
  }) {
    this.selectedSessionId = sessionId;
    this.selectedEventId = eventId;
    this.defaultSelectedTab = defaultSelectedTab;
  }

  closeSessionSummaryDrawer() {
    this.selectedSessionId = null;
    this.selectedEventId = null;
  }

  /* Cancel registration */
  setRegistrationToRemove({ registration, event }) {
    this.registrationToRemove = registration;
    this.eventForRemovingRegistration = event;

    if (!registration || currentUser().isClient()) {
      return;
    }

    this.creditOperation = registration.paid
      ? creditOperations.REFUND_CREDIT
      : creditOperations.REVOKE_CREDIT;

    this.waiveBalance = !registration.paid;
  }

  registrationCancel() {
    const isSSPOn = this.eventForRemovingRegistration.isSSPTurnedOn();
    const isFixedSchedule = this.eventForRemovingRegistration.isFixedSchedule();

    if (isFixedSchedule && !isSSPOn) {
      RegistrationSource.destroyFixedSSPOff({
        eventId: this.eventForRemovingRegistration.id,
        params: {
          client_ids: [this.clientId],
          waive_balance: this.waiveBalance,
        },
        success: this.actions.registrationCancelSuccess,
        error: this.actions.registrationCancelError,
      });
    } else {
      RegistrationSource.destroy({
        id: this.registrationToRemove.id,
        params: this.getRegistrationCancelParams(),
        success: this.actions.registrationCancelSuccess,
        error: this.actions.registrationCancelError,
      });
    }
  }

  registrationCancelSuccess() {
    const message = new TranslatableMessage({
      id: 'containers.clientProfile.Store.registration_cancel_successful',
    });

    const isUpcoming = this.registrationIds.get(
      this.registrationToRemove.id,
      false
    );
    this.creditOperation = undefined;
    this.waiveBalance = false;
    this.registrationToRemove = null;
    this.eventForRemovingRegistration = null;
    MessageWindowActions.addMessage.defer(message);
    this.listRegistrations(isUpcoming);
  }

  registrationCancelError(...args) {
    this.notifyError(`error while deleting registration`, args);
  }

  setCreditOperation(operation) {
    this.creditOperation = operation;
  }

  setWaive(checked) {
    this.waiveBalance = checked;
  }

  getRegistrationCancelParams() {
    if (this.creditOperation === creditOperations.REVOKE_CREDIT) {
      return { waive_balance: this.waiveBalance, revoke_credit: true };
    }

    return {};
  }

  /* Pay balance */
  openViewBalanceDrawer([_, __, productId]) {
    this.productId = productId;
  }

  // List registrations to update datastore
  onPaymentSuccess(response) {
    const list = params => {
      RegistrationSource.list({
        params: { ...params, fields: ['paid'] },
        success: () => {},
        error: this.actions.listRegistrationsError,
      });
    };

    if (this.registrationIds.size) {
      list({
        ids: this.registrationIds.toArray(),
        sort_direction: 'asc',
      });
    }

    if (this.pastRegistrationIds.size) {
      list({
        ids: this.pastRegistrationIds.toArray(),
        sort_direction: 'desc',
      });
    }

    if (response?.success && response?.type === 'pay_all_balances') {
      MessageWindowActions.addMessage.defer(
        new TranslatableMessage({
          id: 'containers.clientProfile.Store.all_balances_paid',
        })
      );
    }
  }

  /* Pagination actions */
  paginationReset() {
    this.pagination = Map({
      upcomingRegistrations: {
        page: 1,
        totalCount: 0,
        perPage: 10,
      },
      pastRegistrations: {
        page: 1,
        totalCount: 0,
        perPage: 10,
      },
      orders: {
        page: 1,
        totalCount: 0,
        perPage: 10,
      },
    });
  }

  isViewingClient() {
    return window.location.pathname.includes(`clients/${this.clientId}`);
  }

  handleToggleFileUploadDrawer() {
    this.isFileUploadDrawer = !this.isFileUploadDrawer;
  }

  handlePdfUploadChange(document) {
    this.document = new Document(document);
  }

  handleDeleteFile(id) {
    const { clientId } = this;
    ClientSource.deleteFile({ clientId, documentId: id });
  }

  verifyEmail(email) {
    uhApiClient.post({
      url: 'accounts/verification_email',
      data: JSON.stringify({ email }),
      success: this.actions.verifyEmailSuccess,
      error: this.actions.verifyEmailError,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  verifyEmailSuccess() {
    MessageWindowActions.addMessage.defer(
      new TranslatableMessage({
        id: 'containers.clientProfile.Store.verification_sent',
      })
    );
  }

  verifyEmailError(...args) {
    this.notifyError('Error while sending verification email.', args);
  }

  resetPassword(email) {
    uhApiClient.post({
      url: 'accounts/reset_password_email',
      data: JSON.stringify({ email }),
      success: this.actions.resetPasswordSuccess,
      error: this.actions.resetPasswordError,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  resetPasswordSuccess() {
    MessageWindowActions.addMessage.defer(
      new TranslatableMessage({
        id: 'containers.clientProfile.Store.reset_sent',
      })
    );
  }

  resetPasswordError(...args) {
    this.notifyError('Error while sending reset password email.', args);
  }

  handleOpenModal() {
    this.isModalOpen = true;
  }

  handleCloseModal() {
    this.currentEmailData = Map({
      id: null,
      email: '',
      first_name: '',
      last_name: '',
    });
    this.fieldErrors = new FieldErrors();
    this.isModalOpen = false;
  }

  handleAdditionalEmailsList(clientId) {
    this.clientId = clientId;
    this.isFetchingAdditionalEmails = true;
    AdditionalEmailsSource.list({
      params: { client_id: clientId },
      success: this.actions.additionalEmailsListSuccess,
      error: this.actions.additionalEmailsListError,
    });
  }

  handleAdditionalEmailsListSuccess(data) {
    this.additionalEmailsList = data;
    this.isFetchingAdditionalEmails = false;
  }

  handleAdditionalEmailsListError(...args) {
    this.isFetchingAdditionalEmails = false;
    this.notifyError('error listing additional emails', args);
  }

  handleSetAdditionalEmail(currentEmailData) {
    this.currentEmailData = currentEmailData;
    this.isModalOpen = true;
  }

  handlePostEmail() {
    this.validateFields();
    if (this.isValid && !this.isSaving) {
      this.isSaving = true;
      AdditionalEmailsSource.createEmail({
        params: {
          attributes: {
            ...this.currentEmailData.toJS(),
            customer_id: currentCustomer().id,
            client_id: this.clientId,
          },
        },
        success: this.actions.postAdditionalEmailSuccess,
        error: this.actions.postAdditionalEmailError,
      });
    }
  }

  handlePostEmailSuccess(data) {
    this.isSaving = false;
    this.additionalEmailsList = this.additionalEmailsList.push(data);
    const message = new TranslatableMessage({
      id: 'user_management.shared.AdditionalEmailsEditor.add_success',
    });

    MessageWindowActions.addMessage.defer(message);
    this.handleCloseModal();
  }

  handlePostEmailError(...args) {
    this.isSaving = false;
    this.notifyError('Error occurred while adding additional email', args);
    this.handleCloseModal();
  }

  handleUpdateEmail() {
    this.validateFields();
    if (this.isValid && !this.isSaving) {
      this.isSaving = true;
      AdditionalEmailsSource.updateEmail({
        id: this.currentEmailData.get('id'),
        params: {
          attributes: {
            ...this.currentEmailData.toJS(),
            customer_id: currentCustomer().id,
            client_id: this.clientId,
          },
        },
        success: this.actions.updateAdditionalEmailSuccess,
        error: this.actions.updateAdditionalEmailError,
      });
    }
  }

  handleUpdateEmailSuccess(data) {
    this.isSaving = false;
    this.currentEmailData = data;

    const index = this.additionalEmailsList.findIndex(
      item => item.get('id') === this.currentEmailData.get('id')
    );

    if (index !== -1) {
      this.additionalEmailsList = this.additionalEmailsList.update(
        index,
        () => this.currentEmailData
      );
    }
    const message = new TranslatableMessage({
      id: 'user_management.shared.AdditionalEmailsEditor.update_success',
    });

    MessageWindowActions.addMessage.defer(message);
    this.handleCloseModal();
  }

  handleUpdateEmailError(...args) {
    this.isSaving = false;
    this.notifyError('Error occurred while updating additional email', args);
    this.handleCloseModal();
  }

  handleDeleteEmail(id) {
    if (!this.isSaving) {
      this.isSaving = true;
      AdditionalEmailsSource.deleteEmail({
        id,
        success: this.actions.deleteAdditionalEmailSuccess,
        error: this.actions.deleteAdditionalEmailError,
      });
    }
  }

  handleDeleteEmailSuccess(data) {
    this.isSaving = false;
    this.additionalEmailsList = this.additionalEmailsList.filter(
      item => item.get('id') !== data.get('id')
    );
    const message = new TranslatableMessage({
      id: 'user_management.shared.AdditionalEmailsEditor.delete_success',
    });

    MessageWindowActions.addMessage.defer(message);
  }

  handleDeleteEmailError(...args) {
    this.isSaving = false;
    this.notifyError('Error occurred while deleting additional email', args);
  }

  handleUpdateStore({ key, value }) {
    this.currentEmailData = this.currentEmailData.set(key, value);
    if (!this.isValid) {
      this.validateFields();
    }
  }

  validateFields() {
    this.fieldErrors = this.fieldErrors.clear();
    if (!this.currentEmailData.get('first_name')) {
      this.fieldErrors = this.fieldErrors.add(
        'first_name',
        'user_management.shared.AdditionalEmailsEditor.is_required'
      );
    }
    if (!this.currentEmailData.get('last_name')) {
      this.fieldErrors = this.fieldErrors.add(
        'last_name',
        'user_management.shared.AdditionalEmailsEditor.is_required'
      );
    }

    if (!isEmailValid(this.currentEmailData.get('email'))) {
      this.fieldErrors = this.fieldErrors.add(
        'email',
        'user_management.shared.AdditionalEmailsEditor.invalid_email'
      );
    }

    this.isValid = !this.fieldErrors.hasErrors();
  }

  onSessionSummaryOpen({ closeDrawers = true }) {
    const isOnCalendar = window.location.pathname.includes('calendar');
    const isOnClasses = window.location.pathname.includes('classes');
    const { defaultSelectedTab } = this;

    if (closeDrawers) {
      this.closeScheduleDrawer();
      this.closeDrawer();
    }

    this.defaultSelectedTab = defaultSelectedTab;

    if (isOnCalendar) {
      CalendarActions.viewSessionClosed.defer();
    }

    if (isOnClasses) {
      ClassesListActions.toggleSessionSummaryDrawer.defer({});
    }

    CompletedEventSessionsActions.closeSessionSummaryDrawer.defer();
    FutureEventSessionsActions.closeSessionSummaryDrawer.defer();
  }
}

export default ClientProfileBaseStore;
