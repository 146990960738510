import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import DiscountValue from 'shared/components/DiscountValue.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import MembershipIcon from 'shared/components/icons/Membership.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

import Client from 'shared/records/Client.jsx';
import Membership from 'shared/records/Membership.jsx';

const inlineStyles = {
  container: {
    fontWeight: 'bold',
    paddingLeft: 25,
    position: 'relative',
    marginBottom: 15,
  },
  membershipIcon: {
    height: 16,
    width: 16,
    position: 'absolute',
    left: 0,
  },
  discountValue: {
    color: '#807F7F',
  },
  mb6: {
    marginBottom: 6,
  },
};

function MembershipDiscount({
  membership = new Membership(),
  customerUser = new Client(),
  discount,
  discountAmount = 0,
}) {
  return (
    <div style={inlineStyles.container}>
      <MembershipIcon
        style={inlineStyles.membershipIcon}
        color={membership?.color ?? 'black'}
      />

      <div>
        <FlexBoxJustify style={inlineStyles.mb6}>
          <FormattedMessage id={messageId('.membership', __filenamespace)} />
          <FormattedCurrency value={-discountAmount} fromCents />
        </FlexBoxJustify>

        <div style={inlineStyles.discountValue}>
          <div style={inlineStyles.mb6}>
            <DiscountValue discount={discount} />
          </div>
          <div style={inlineStyles.mb6}>{customerUser.name()}</div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(MembershipDiscount);
