import * as React from 'react';
import { injectIntl } from 'react-intl';
import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { Dropdown, Icon, Spinner } from '@upperhand/playmaker';

import MenuItem from '@mui/material/MenuItem';

import ButtonMenu from 'shared/components/ButtonMenu.jsx';

import { t } from 'shared/utils/LocaleUtils.js';

import { CardReaderDataStore } from 'dataStores';

import CardReaderDropdownActions from './Actions';
import CardReaderDropdownStore from './Store.js';

function CardReaderDropdown({
  cardReaderDropdownStore: {
    cardReaderIds,
    cardReaderId,
    placeholderValue,
    refreshValue,
    isPinging,
  },
  cardReaderDataStore: { cardReaders },
  isMobile,
  fullWidth,
  intl,
}) {
  React.useEffect(() => {
    CardReaderDropdownActions.mounted.defer();
  }, []);

  const processSelection = value => {
    if (value !== refreshValue) {
      CardReaderDropdownActions.selectCardReader(value);
    } else {
      CardReaderDropdownActions.refresh();
    }
  };

  const modifiedDropdownItems = [
    ...cardReaderIds.map(crid => {
      const cardReader = cardReaders.get(crid);

      return {
        label: cardReader.get('nickname'),
        value: cardReader.get('id'),
      };
    }),
    {
      label: t('.refresh', intl, __filenamespace),
      value: refreshValue,
      alternateColor: true,
    },
  ];

  const currentCardReader = cardReaders.get(cardReaderId);

  return isMobile ? (
    <ButtonMenu
      anchorOrigin={{ horizontal: 'middle', vertical: 'bottom' }}
      targetOrigin={{ horizontal: 'middle', vertical: 'top' }}
      icon={
        isPinging ? (
          <Spinner size={24} />
        ) : (
          <Icon name={currentCardReader?.statusIconName || 'devicePending'} />
        )
      }
      buttonType="icon"
    >
      {modifiedDropdownItems.map(item => (
        <MenuItem key={item.value} onClick={() => processSelection(item.value)}>
          {item.label}
        </MenuItem>
      ))}
    </ButtonMenu>
  ) : (
    <Dropdown
      icon={
        isPinging
          ? 'spinner'
          : currentCardReader?.statusIconName || 'devicePending'
      }
      items={modifiedDropdownItems}
      value={cardReaderId || placeholderValue}
      fullWidth={fullWidth}
      onChange={e => processSelection(e.target.value)}
      placeholder={t(
        cardReaderIds.size > 0 ? '.placeholder' : '.noReaders',
        intl,
        __filenamespace
      )}
      alternateItemColor="#009FDD"
    />
  );
}

export default compose(
  injectIntl,
  altContainer({
    stores: {
      cardReaderDropdownStore: CardReaderDropdownStore,
      cardReaderDataStore: CardReaderDataStore,
    },
  })
)(CardReaderDropdown);
