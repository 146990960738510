/* eslint-disable react/require-default-props */
import React, { memo, useState } from 'react';
import { Grid, Typography, Card, Button } from '@upperhand/playmaker';

import UserAvatar from 'shared/components/_UserAvatar.jsx';
import AnswerHeader from 'containers/events/admin/registrationResponses/components/AnswerHeader.jsx';

import Client from 'shared/records/Client.jsx';
import RegistrationResponse from 'shared/records/RegistrationResponse';
import PropTypes from 'prop-types';

function Answers({
  response = new RegistrationResponse(),
  onEditResponse = () => {},
}) {
  const [expanded, setExpanded] = useState(false);
  const answers = response?.answers?.toArray() || [];

  return (
    <>
      <Card
        classes={{
          root: 'registration-responses__list-item',
          content: 'registration-responses__list-item-content',
        }}
      >
        <Grid container key={response?.id} alignItems="center">
          <Grid item container xs={3} alignItems="center">
            <div
              role="presentation"
              className="registration-responses__list-item-name"
              onClick={() => setExpanded(!expanded)}
            >
              <UserAvatar user={new Client(response)} />
              <Typography>{response?.fullName}</Typography>
              <Button
                rounded
                icon={expanded ? 'arrowUp' : 'arrowDown'}
                type="tertiary"
                onClick={() => setExpanded(!expanded)}
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <Typography>{response?.managing_customer_user_name}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>{response?.shorthandGender}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>{`${answers.length}/${response?.fields_total_count}`}</Typography>
          </Grid>
        </Grid>
      </Card>
      {expanded && (
        <Card
          classes={{
            root: 'registration-responses__answer',
            content: 'registration-responses__answer-content',
          }}
        >
          <Grid container alignItems="center">
            <AnswerHeader
              onEditResponse={() =>
                onEditResponse({ responseId: response?.id })
              }
            />
            {answers?.map(answer => (
              <Grid
                item
                container
                xs={12}
                key={answer.get('id')}
                className="registration-responses__answer-item"
              >
                <Grid item xs={5}>
                  <Typography>
                    {answer.get('name', '')}
                    {answer.get('required', false) ? '*' : ''}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography>{answer.get('answer')}</Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Card>
      )}
    </>
  );
}

Answers.propTypes = {
  response: PropTypes.instanceOf(RegistrationResponse),
  onEditResponse: PropTypes.func,
};

function TableContent({ responses = [], onEditResponse = () => {} }) {
  return (
    <>
      {responses.map(response => (
        <Answers
          key={response.id}
          response={response}
          onEditResponse={onEditResponse}
        />
      ))}
    </>
  );
}

TableContent.propTypes = {
  responses: PropTypes.arrayOf(PropTypes.instanceOf(RegistrationResponse)),
  onEditResponse: PropTypes.func,
};

export default memo(TableContent);
