class RegistrationResponsesActions {
  constructor() {
    this.generateActions(
      'mounted',
      'listResponses',
      'listResponsesSuccess',
      'listResponsesError',
      'handleSearchChange',
      'handlePageSelect',
      'toggleEditDrawer',
      'updateResponse',
      'saveResponse',
      'saveResponseSuccess',
      'saveResponseError'
    );
  }
}

export default alt.createActions(RegistrationResponsesActions);
