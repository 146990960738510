import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { Grid, Spinner, Switch, Icon } from '@upperhand/playmaker';
import { injectIntl } from 'react-intl';
import { List } from 'immutable';
import clsx from 'clsx';

import { t } from 'shared/utils/LocaleUtils.js';
import altContainer from 'shared/hocs/altContainer.jsx';

import { FlexBoxJustifyCenter } from 'shared/components/FlexBox.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { smallScreen } from 'shared/utils/DOMUtils';

import PaymentMethodModal from 'containers/client/settings/paymentMethodModal/PaymentMethodModal.jsx';

import EmptyState from './components/EmptyState.jsx';
import PaymentMethodInfo from './components/PaymentMethodInfo.jsx';
import CardAdd from './components/CardAdd.jsx';
import AccountCredits from './components/AccountCredits.jsx';

import ClientBillingSettingsActions from './Actions';
import ClientBillingSettingsStore from './Store';

import './styles.scss';

function Billing({
  clientBillingSettingsStore = {},
  drawerMode = false,
  staffMode = false,
  userId = null,
  access = false,
  customerUserId = null,
  intl,
  accountCreditAutoPay = false,
  allowAccountCreditAutoPay = false,
  accountCredits = 0,
}) {
  const {
    paymentMethods,
    isLoading,
    accessToVault,
    accountCreditAutoPay: storeAccountCreditAutoPay,
  } = clientBillingSettingsStore;

  const isMobile = smallScreen();

  useEffect(() => {
    if (!staffMode) {
      ClientBillingSettingsActions.mounted(
        userId,
        customerUserId,
        access,
        staffMode,
        accountCreditAutoPay
      );
    }
  }, [access, customerUserId, staffMode, userId, accountCreditAutoPay]);

  const isMobileView = isMobile || drawerMode;
  const hasPaymentMethods = !paymentMethods.isEmpty();
  const showPaymentMethods =
    !isLoading && hasPaymentMethods && (accessToVault || !staffMode);
  const showEmptyState =
    !allowAccountCreditAutoPay && !showPaymentMethods && !isLoading;
  const showEmptyStateWithAuto =
    allowAccountCreditAutoPay &&
    !accountCreditAutoPay &&
    !showPaymentMethods &&
    !isLoading;
  const showMobileAddCard = staffMode
    ? isMobileView && !isLoading && accessToVault
    : isMobileView && !isLoading;
  const showDesktopAddCard = staffMode
    ? !isMobile && !drawerMode && !isLoading && accessToVault
    : !isMobile && !drawerMode && !isLoading;
  const showAutoPay = staffMode
    ? !isLoading && allowAccountCreditAutoPay && accessToVault
    : !isLoading && allowAccountCreditAutoPay;
  const listClasses = clsx(
    'client-settings-billing__content',
    isMobileView && 'client-settings-billing__content-mobile'
  );

  return (
    <div className="client-settings-billing">
      {isLoading && (
        <FlexBoxJustifyCenter className="client-settings-billing__loading">
          <Spinner />
        </FlexBoxJustifyCenter>
      )}
      {showEmptyState && (
        <Grid container spacing={2} alignContent="flex-start" justify="center">
          <Grid item xs={12}>
            <EmptyState intl={intl} access={accessToVault || !staffMode} />
          </Grid>
        </Grid>
      )}
      <div
        className={clsx(
          'client-settings-billing__access',
          !hasPaymentMethods &&
            !allowAccountCreditAutoPay &&
            'client-settings-billing__access_centered'
        )}
      >
        {!isLoading && !staffMode && (
          <div>
            <Switch
              checked={accessToVault}
              labelValue={t('.pos_access', intl, __filenamespace)}
              labelPlacement="end"
              onChange={ClientBillingSettingsActions.updateCustomerUserAccess}
            />
            <span
              data-tip
              data-for="pos_access"
              className="client-settings-billing__tooltip"
            >
              <Icon
                name="info"
                className="client-settings-billing__tooltip_icon"
              />
            </span>
            <ReactTooltip
              id="pos_access"
              place={!hasPaymentMethods ? 'top' : 'right'}
              className="client-settings-billing__uh-tooltip"
              effect="solid"
            >
              {t('.pos_info', intl, __filenamespace)}
            </ReactTooltip>
          </div>
        )}
      </div>
      {showAutoPay && (
        <Switch
          classes={{ switchBase: 'client-settings-billing__credit_auto_pay' }}
          checked={storeAccountCreditAutoPay}
          labelValue={t('.allow_credits', intl, __filenamespace)}
          labelPlacement="end"
          onChange={ClientBillingSettingsActions.toggleAccountCreditAutoPay}
        />
      )}
      {showEmptyStateWithAuto && (
        <Grid container spacing={2} alignContent="flex-start" justify="center">
          <Grid item xs={12}>
            <EmptyState intl={intl} access={accessToVault || !staffMode} />
          </Grid>
        </Grid>
      )}
      {(showPaymentMethods || (accountCreditAutoPay && !isLoading)) && (
        <div className={listClasses}>
          {showMobileAddCard && <CardAdd />}
          {!isLoading && accountCreditAutoPay && (
            <AccountCredits intl={intl} accountCredits={accountCredits} />
          )}
          {paymentMethods.map(paymentMethod => (
            <PaymentMethodInfo
              paymentMethod={paymentMethod}
              intl={intl}
              key={paymentMethod.id}
            />
          ))}
          {showDesktopAddCard && <CardAdd />}
        </div>
      )}
      <PaymentMethodModal
        userId={userId}
        onSuccess={ClientBillingSettingsActions.listPaymentMethods.defer}
      />
    </div>
  );
}

Billing.propTypes = {
  clientBillingSettingsStore: PropTypes.shape({
    paymentMethods: PropTypes.instanceOf(List),
    isLoading: PropTypes.bool,
  }),
  intl: PropTypes.object.isRequired,
  drawerMode: PropTypes.bool,
  staffMode: PropTypes.bool,
  access: PropTypes.bool,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customerUserId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      clientBillingSettingsStore: ClientBillingSettingsStore,
    },
  })
)(Billing);
