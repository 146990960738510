export default {
  // The keys defined here will override those in the base locale file, for example:
  // fontFamily: 'Courier New'
  name: 'Camp Pulse',
  siteURL: 'https://www.camppulse.io/',
  logo: {
    url: 'https://s3.amazonaws.com/upperhand-app/static/images/camp_pulse_logo.png',
    width: 325,
  },
  login: {
    desktopSignInColor: '#29323E',
    mobileSignInColor: '#29323E',
  },
  palette: {
    // TODO: remove primary1Color and accent1Color replaace int usages with a new one
    primary1Color: '#29323E',
    accent1Color: '#31AF52',

    primary: {
      main: '#29323E',
    },
    secondary: {
      main: '#31AF52',
    },
  },
  sideNav: {
    selectedColor: '#29323E',
  },
  icons: {
    cart: '#29323E',
  },
  statusBar: {
    backgroundColor: '#29323E',
  },
};
