import { Map } from 'immutable';

import CalendarActions from 'calendar/actions/CalendarActions.jsx';
import CancelSessionActions from 'containers/events/admin/schedule/cancelSessionModal/Actions';
import EventDisplayActions from 'event_mgmt/display/actions/EventDisplayActions.jsx';
import POSEventActions from 'point_of_sale/actions/POSEventActions.jsx';
import POSProductListActions from 'point_of_sale/actions/POSProductListActions.jsx';
import POSSchedulingActions from 'point_of_sale/actions/POSSchedulingActions.jsx';
import RegistrationActions from 'event_mgmt/display/actions/RegistrationActions.jsx';
import { SessionActions } from 'sources';
import SessionSummaryDrawerActions from 'containers/events/admin/sessionSummaryDrawer/Actions.js';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { list as listSessions } from 'sources/SessionSource';
import { SESSION_STATUSES } from 'shared/records/Session';
import POSSingleSessionContentActions from 'point_of_sale/actions/POSSingleSessionContentActions';

class SessionDataStore extends UpperHandStore {
  constructor() {
    super();

    this.isLoading = false;
    this.loadingMore = false;
    this.sessions = Map();
    this.pagination = Map({ page: 1, perPage: 50, totalCount: 0 });

    this.bindListeners({
      listSuccess: [SessionActions.listSuccess],
      listError: SessionActions.listError,

      fetchSuccess: [
        SessionActions.fetchSuccess,
        SessionSummaryDrawerActions.fetchSuccess,
      ],
      fetchError: [
        SessionActions.fetchError,
        SessionSummaryDrawerActions.fetchError,
      ],

      fetchRegistrationSession: RegistrationActions.scheduleSuccess,

      listRegistrationSessions: [
        RegistrationActions.listSuccess,
        POSSchedulingActions.listPurchasedRegistrationsSuccess,
      ],
      listEventSessions: [
        POSProductListActions.eventSelected,
        POSEventActions.fetchEventSuccess,
      ],
      listMoreEventSessions:
        POSSingleSessionContentActions.listMoreEventSessions,
      listEventMgmtDisplaySessions: EventDisplayActions.clientDisplayMounted,

      listCalendarSessions: CalendarActions.listSuccess,
      listCalendarSessionsSuccess: CalendarActions.listSessionsSuccess,
      listCalendarSessionsError: CalendarActions.listSessionsError,

      markCanceled: CancelSessionActions.sessionCanceledSuccess,

      updateSuccess: SessionActions.updateSuccess,
      updateError: SessionActions.updateError,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  list(params = {}) {
    const requestParams = { ...params };

    delete requestParams.successAction;
    delete requestParams.errorAction;

    if (Object.keys(requestParams).length === 0) {
      return;
    }

    const success = params.successAction
      ? {
          action: params.successAction,
          args: [params],
        }
      : SessionActions.listSuccess;
    const error = params.errorAction || SessionActions.listError;

    listSessions({
      params: {
        ...requestParams,
        per_page: params.per_page || this.pagination.get('perPage'),
      },
      success,
      error,
    });
  }

  listSuccess({ sessions, page, perPage, totalCount }) {
    const fetchedSessions = sessions.groupBy(s => s.id).map(s => s.first());

    this.sessions = this.sessions.merge(fetchedSessions);
    this.pagination = this.pagination.set('page', page);
    this.pagination = this.pagination.set('perPage', perPage);
    this.pagination = this.pagination.set('totalCount', totalCount);
    this.loadingMore = false;
    this.isLoading = false;
  }

  listError(...args) {
    this.notifyError('error listing sessions', args);
  }

  fetchSuccess(session) {
    this.sessions = this.sessions.set(session.id, session);
  }

  fetchError(...args) {
    this.notifyError('error fetching session', args);
  }

  fetchRegistrationSession(registration) {
    this.list({ ids: [registration.session_id] });
  }

  listRegistrationSessions({ registrations }) {
    const ids = registrations.map(r => r.session_id).toJS();

    if (ids.length > 0) {
      this.list({ ids });
    }
  }

  listEventSessions(event) {
    this.list({ event_ids: event.id });
  }

  listMoreEventSessions(event) {
    this.loadingMore = true;
    this.list({
      event_ids: event.id,
      page: this.pagination.get('page') + 1,
      perPage: this.pagination.get('perPage'),
    });
  }

  listEventMgmtDisplaySessions({ eventId, params }) {
    this.isLoading = true;
    this.list({ event_ids: eventId, ...params });
  }

  listCalendarSessions(data) {
    if (!data.event_times.length) {
      return;
    }

    this.list({
      ids: data.event_times.map(et => et.session_id),
      fields: ['note', 'client_note'],
      successAction: CalendarActions.listSessionsSuccess,
      errorAction: CalendarActions.listSessionsError,
    });
  }

  listCalendarSessionsSuccess([fetchedData, params]) {
    const { sessions, page, perPage, totalCount } = fetchedData;

    this.listSuccess({ sessions, page, perPage, totalCount });

    if (page * perPage < totalCount) {
      this.isLoading = true;
      this.list({ ...params, page: page + 1, perPage });
    } else {
      this.isLoading = false;
    }
  }

  listCalendarSessionsError(...args) {
    this.notifyError('error listing calendar sessions', args);
  }

  markCanceled({ id }) {
    const session = this.sessions.get(id);
    if (session) {
      this.sessions = this.sessions.set(
        id,
        session.set('status', SESSION_STATUSES.cancelled)
      );
    }
  }

  updateSuccess(session) {
    this.sessions = this.sessions.set(session.id, session);
  }

  updateError(...args) {
    this.notifyError('error updating session', args);
  }
}

export default alt.createStore(SessionDataStore, 'SessionDataStore');
