import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  CurrencyTextField,
  Dropdown,
  Typography,
  Icon,
} from '@upperhand/playmaker';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';

import CheckboxExpander from 'memberships/components/CheckboxExpander.jsx';
import ExplanatoryText from 'shared/components/_ExplanatoryText.jsx';
import MembershipEditingActions from 'memberships/actions/MembershipEditingActions.jsx';

import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { toString } from 'shared/utils/StringUtils.jsx';

const styles = {
  explanatoryText: {
    marginTop: 10,
  },
  currencyIcon: {
    top: '50%',
  },
  input: {
    width: 180,
  },
  infoIcon: {
    svg: {
      fill: 'var(--color-primary)',
    },
  },
};

const Price = injectIntl(
  ({
    disableEditInterval,
    price,
    onChange,
    interval,
    intervalErrorText,
    intl,
    priceErrorText,
    tiered,
    disableTiers,
  }) => (
    <div className="membership__price">
      <div className="membership__price-header">
        <Typography variant="fieldLabel">
          {t('.header', intl, __filenamespace)}
        </Typography>
        <div className="membership__price-header-row">
          <FormControlLabel
            name="tiered"
            label={t('.tiered_pricing', intl, __filenamespace)}
            control={<Checkbox color={tiered ? 'primary' : 'dark'} />}
            onChange={onChange}
            checked={tiered}
            disabled={disableTiers}
          />
          <Box data-tip data-for="tiered-pricing" sx={styles.infoIcon}>
            <Icon name="info" />
          </Box>
          <ReactTooltip
            id="tiered-pricing"
            className="uh-tooltip"
            effect="solid"
          >
            {t('.pricing_tooltip', intl, __filenamespace)}
          </ReactTooltip>
        </div>
      </div>
      {!tiered && (
        <div className="membership__price-row">
          <CurrencyTextField
            fullWidth
            classes={{
              root: 'membership__price-value',
            }}
            name="price"
            value={price / 100}
            onChange={(e, value) =>
              onChange(e, Math.round(value * 100), 'price')
            }
            errorText={priceErrorText}
          />
          <Dropdown
            fullWidth
            classes={{
              root: 'membership__price-interval',
            }}
            name="interval_months"
            value={toString(interval)}
            onChange={e =>
              onChange(e, parseInt(e.target.value, 10), 'interval_months')
            }
            errorText={intervalErrorText}
            disabled={disableEditInterval}
            items={[
              {
                value: '1',
                label: t('.monthly', intl, __filenamespace),
              },
              {
                value: '3',
                label: t('.quarterly', intl, __filenamespace),
              },
              {
                value: '12',
                label: t('.yearly', intl, __filenamespace),
              },
              {
                value: '0',
                label: t('.once', intl, __filenamespace),
              },
            ]}
          />
        </div>
      )}
    </div>
  )
);

const JoinFee = injectIntl(
  ({ joinFeeChecked, joinFee, onChange, intl, style }) => (
    <CheckboxExpander
      label={t('.join_fee_checkbox_label', intl, __filenamespace)}
      checked={joinFeeChecked}
      onChange={MembershipEditingActions.joinFeeToggled}
      style={style}
    >
      <div className="membership__checkboxExpander">
        <ExplanatoryText
          text={t('.join_fee_explanatory_text', intl, __filenamespace)}
          style={styles.explanatoryText}
        />
        <div className="membership__checkboxExpander-row">
          <FormattedMessage id={messageId('.join_fee', __filenamespace)} />
          <CurrencyTextField
            classes={{
              root: 'membership__checkboxExpander-value',
            }}
            onChange={(e, value) =>
              onChange(e, Math.round(value * 100), 'join_fee')
            }
            name="join_fee"
            value={joinFee / 100}
          />
        </div>
      </div>
    </CheckboxExpander>
  )
);

const CommitmentLength = injectIntl(
  ({
    commitmentLengthChecked,
    commitmentMonths,
    onChange,
    interval,
    intl,
    style,
  }) => (
    <CheckboxExpander
      label={t('.commitment_length_checkbox_label', intl, __filenamespace)}
      checked={commitmentLengthChecked && interval !== 0}
      onChange={MembershipEditingActions.commitmentLengthToggled}
      disabled={interval === 0}
      style={style}
    >
      <div className="membership__checkboxExpander">
        <ExplanatoryText
          text={t('.commitment_length_explanatory_text', intl, __filenamespace)}
          style={styles.explanatoryText}
        />
        <div className="membership__checkboxExpander-row">
          <FormattedMessage
            id={messageId('.commitment_length', __filenamespace)}
          />
          <Dropdown
            classes={{
              root: 'membership__checkboxExpander-value',
            }}
            name="commitment_months"
            value={commitmentMonths}
            // eslint-disable-next-line no-undef
            onChange={e => onChange(_, e.target.value, 'commitment_months')}
            items={[
              {
                value: 1,
                label: t('.1_month', intl, __filenamespace),
              },
              {
                value: 3,
                label: t('.3_months', intl, __filenamespace),
              },
              {
                value: 6,
                label: t('.6_months', intl, __filenamespace),
              },
              {
                value: 12,
                label: t('.1_year', intl, __filenamespace),
              },
            ]}
          />
        </div>
      </div>
    </CheckboxExpander>
  )
);

const Pricing = injectIntl(
  ({
    disableEditInterval,
    onChange,
    price,
    interval,
    joinFeeChecked,
    joinFee,
    commitmentLengthChecked,
    commitmentMonths,
    priceErrorText,
    intervalErrorText,
    tiered,
    disableTiers,
  }) => (
    <>
      <Price
        disableEditInterval={disableEditInterval}
        price={price}
        priceErrorText={priceErrorText}
        intervalErrorText={intervalErrorText}
        interval={interval}
        tiered={tiered}
        disableTiers={disableTiers}
        onChange={onChange}
      />
      {!tiered && (
        <>
          <JoinFee
            joinFee={joinFee}
            joinFeeChecked={joinFeeChecked}
            onChange={onChange}
          />
          <CommitmentLength
            commitmentMonths={commitmentMonths}
            commitmentLengthChecked={commitmentLengthChecked}
            interval={interval}
            onChange={onChange}
          />
        </>
      )}
    </>
  )
);

export default Pricing;
