import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { List, Set } from 'immutable';
import clsx from 'clsx';
import {
  Dropdown,
  MultipleSelect,
  TextField,
  Typography,
} from '@upperhand/playmaker';
import ReactTooltip from 'react-tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/Info';

import { FlexBoxColumnLayout } from 'shared/components/FlexBox.jsx';
import EventTypeLabel from 'event_mgmt/editing/components/EventTypeLabel.jsx';
import EventTypeEditor from 'components/EventTypeEditor';
import LocationEditor from 'components/LocationEditor';

import EventType from 'event_mgmt/shared/records/EventType.jsx';

import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors, boldText } from 'shared/styles/uhStyles.jsx';

import sportTypes from 'shared/constants/sportTypes';
import eventSeasons from 'shared/constants/eventSeasons';

import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';

import './TabEventsAndLocations.scss';

const styles = {
  group: {
    width: '80%',
    marginBottom: 16,
  },
};

class TabEventAndLocation extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { customerEvent, fieldErrors, eventTypes, eventType } = this.props;
    return (
      fieldErrors !== nextProps.fieldErrors ||
      customerEvent.get('title') !== nextProps.customerEvent.get('title') ||
      customerEvent.get('buyer_limit') !==
        nextProps.customerEvent.get('buyer_limit') ||
      customerEvent.get('privacy') !== nextProps.customerEvent.get('privacy') ||
      customerEvent.getIn(['schedules', 0, 'location', 'id']) !==
        nextProps.customerEvent.getIn(['schedules', 0, 'location', 'id']) ||
      customerEvent.getIn(['schedules', 0, 'duration']) !==
        nextProps.customerEvent.getIn(['schedules', 0, 'duration']) ||
      customerEvent.get('event_type') !==
        nextProps.customerEvent.get('event_type') ||
      customerEvent.get('schedule_type') !==
        nextProps.customerEvent.get('schedule_type') ||
      eventTypes !== nextProps.eventTypes ||
      eventType !== nextProps.eventType ||
      customerEvent.get('sport_type') !==
        nextProps.customerEvent.get('sport_type') ||
      !customerEvent
        .get('seasons', Set())
        .equals(nextProps.customerEvent.get('seasons', Set()))
    );
  }

  eventType() {
    const { customerEvent } = this.props;
    return customerEvent.get('event_type') || new EventType();
  }

  render() {
    const { intl, fieldErrors, handleInputChange, customerEvent, eventTypes } =
      this.props;
    const seasonsError = fieldErrors.get('seasons', List()).join(',');

    const radioDisabled =
      customerEvent.get('status') !== 'draft' ||
      (customerEvent.get('id') !== null &&
        customerEvent.get('schedule_type') === 'class_schedule');

    // Pulled out of the Radio group in order to handle adding the beta class event condition
    const eventOptions = [
      <FormControlLabel
        key="fixed_schedule"
        value="fixed_schedule"
        disabled={radioDisabled}
        label={
          <EventTypeLabel
            label={t('.fixed_schedule', intl, __filenamespace)}
            description={t(
              '.fixed_schedule_description',
              intl,
              __filenamespace
            )}
            example={t('.fixed_schedule_example', intl, __filenamespace)}
          />
        }
        control={<Radio />}
        style={{ paddingBottom: '0.5em' }}
      />,
      <FormControlLabel
        key="open_booking"
        value="open_booking"
        label={
          <EventTypeLabel
            label={t('.open_booking', intl, __filenamespace)}
            description={t('.open_booking_description', intl, __filenamespace)}
            example={t('.open_booking_example', intl, __filenamespace)}
          />
        }
        disabled={radioDisabled}
        control={<Radio />}
        style={{ paddingBottom: '0.5em' }}
      />,
    ];

    return (
      <FlexBoxColumnLayout
        childrenStyle={{ maxWidth: 560 }}
        style={{ justifyContent: 'flex-start' }}
        className="events-locations"
      >
        <div>
          <div style={styles.group}>
            <TextField
              fullWidth
              label={t('.event_title', intl, __filenamespace)}
              placeholder={t('.event_title', intl, __filenamespace)}
              errorText={
                fieldErrors.get('title')
                  ? fieldErrors.get('title').join(',')
                  : null
              }
              onChange={handleInputChange}
              name="title"
              value={customerEvent.get('title')}
            />
          </div>
          <div style={styles.group}>
            <p
              style={merge(boldText, {
                color: uhColors.grey,
                paddingTop: '1em',
                paddingBottom: '1em',
              })}
            >
              <FormattedMessage
                id={messageId('.event_schedule_type_header', __filenamespace)}
              />
            </p>
            <RadioGroup
              name="scheduleType"
              onChange={(event, value) =>
                EventActions.scheduleTypeSelected(value)
              }
              value={customerEvent.get('schedule_type')}
            >
              {eventOptions.filter(x => !!x)}
            </RadioGroup>
          </div>
        </div>
        <div>
          {customerEvent.isFixedSchedule() && (
            <Box sx={{ ...styles.group, marginBottom: '16px' }}>
              <Box
                component="span"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '0 0 5px',
                }}
              >
                <Typography variant="caption">
                  {t('.sport_type', intl, __filenamespace)}
                </Typography>
                <InfoIcon data-tip data-for="sport-type-info" />
              </Box>
              <Dropdown
                id="sport_type_dropdown"
                fullWidth
                items={sportTypes}
                name="sport_type"
                onChange={event => handleInputChange(event, event.target.value)}
                value={customerEvent.get('sport_type')}
                placeholder={t(
                  '.sport_type_placeholder',
                  intl,
                  __filenamespace
                )}
                errorText={fieldErrors.get('sport_type', List()).join(',')}
              />
              <ReactTooltip
                id="sport-type-info"
                effect="solid"
                className="events-locations__tooltip"
              >
                {t('.sport_type_tooltip', intl, __filenamespace)}
              </ReactTooltip>
            </Box>
          )}
          <div style={styles.group}>
            <EventTypeEditor
              label={t('.event_type', intl, __filenamespace)}
              eventTypes={eventTypes}
              errorText={fieldErrors.get('event_type', List()).join(',')}
              onEventTypeSelect={EventActions.eventTypeSelected}
              onCreateOrUpdateSuccess={EventActions.eventTypeSelected}
              selectedEventTypeId={this.eventType() && this.eventType().id}
            />
          </div>

          {!customerEvent.isClassSchedule() && (
            <div style={styles.group}>
              <LocationEditor
                initialLocation={customerEvent.getIn([
                  'schedules',
                  0,
                  'location',
                ])}
                selectedLocationId={customerEvent.getIn([
                  'schedules',
                  0,
                  'location',
                  'id',
                ])}
                onChange={value => {
                  EventActions.updateEventStore(
                    ['schedules', 0, 'location', 'id'],
                    value
                  );
                }}
                parentObject="event"
              />
            </div>
          )}
          {customerEvent.isFixedSchedule() && (
            <Box sx={{ width: '80%', marginBottom: '16px' }}>
              <MultipleSelect
                fullWidth
                selectAll
                classes={{
                  root: clsx(
                    'events-locations__seasons',
                    seasonsError ? 'events-locations__seasons-error' : ''
                  ),
                }}
                name="seasons"
                rounded={false}
                label={t('.seasons', intl, __filenamespace)}
                items={eventSeasons}
                selectedItems={customerEvent.get('seasons', Set()).toJS()}
                onChange={value => {
                  handleInputChange({
                    target: { name: 'seasons', value: Set(value) },
                  });
                }}
              />
              {seasonsError && (
                <Typography variant="error">{seasonsError}</Typography>
              )}
            </Box>
          )}
          <div style={styles.group}>
            <Box
              component="span"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0 0 5px',
              }}
            >
              <Typography variant="caption">Buyer Limit</Typography>
              <InfoIcon data-tip data-for="buyer_limit" />
            </Box>
            <TextField
              fullWidth
              placeholder="Enter Limit"
              name="buyer_limit"
              value={customerEvent.get('buyer_limit')}
              onChange={handleInputChange}
            />
            <ReactTooltip
              id="buyer_limit"
              effect="solid"
              className="events-locations__tooltip"
            >
              Note: If limit is blank then buyer limit is unlimited
            </ReactTooltip>
          </div>
          {!customerEvent.isClassSchedule() && (
            <div style={styles.group}>
              <FormControlLabel
                control={
                  <Switch
                    name="privacy"
                    onChange={handleInputChange}
                    checked={customerEvent.get('privacy')}
                  />
                }
                label={t('.private_event', intl, __filenamespace)}
              />
            </div>
          )}
        </div>
      </FlexBoxColumnLayout>
    );
  }
}

export default injectIntl(TabEventAndLocation);
