import * as React from 'react';
import AltContainer from 'alt-container';

import AthleteStore from 'event_mgmt/shared/stores/AthleteStore.jsx';
import AutomationTemplateDescriptionActions from 'shared/actions/AutomationTemplateDescriptionActions.jsx';
import AutomationTemplateDescriptionStore from 'shared/stores/AutomationTemplateDescriptionStore.jsx';
import AvailableTimesStore from 'event_mgmt/shared/stores/PurchaseDrawerAvailableTimesStore.js';
import CartStore from 'event_mgmt/shared/stores/CartStore.jsx';
import Event from 'event_mgmt/display/components/_Event.jsx';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventDisplayActions from 'event_mgmt/display/actions/EventDisplayActions.jsx';
import EventStaffStore from 'event_mgmt/display/stores/EventStaffStore.js';
import EventClientsStore from 'event_mgmt/display/stores/EventClientsStore.js';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';
import MembershipDiscountStore from 'event_mgmt/display/stores/MembershipDiscountStore.js';
import PDRegistrationPackageStore from 'event_mgmt/display/stores/PurchaseDrawerRegistrationPackageStore.jsx';
import PurchaseDrawerStore from 'event_mgmt/display/stores/PurchaseDrawerStore.js';
import RegistrationActions from 'event_mgmt/display/actions/RegistrationActions.jsx';
import RegistrationStore from 'event_mgmt/display/stores/RegistrationStore.jsx';
import SingleSessionPurchasableContentStore from 'event_mgmt/display/stores/single_session_purchase/SingleSessionPurchasableContentStore.jsx';
import WaitlistActions from 'event_mgmt/shared/actions/WaitlistActions.jsx';
import WaitlistStore from 'event_mgmt/shared/stores/WaitlistStore.jsx';
import ClientCreditActions from 'shared/actions/ClientCreditActions';
import ClientCreditStore from 'shared/stores/ClientCreditStore';
import {
  SessionDataStore,
  ClientDataStore,
  RegistrationPackageDataStore,
} from 'dataStores';
import BrowseEventsBookDrawer from 'containers/browseEventsBookDrawer/BrowseEventsBookDrawer.jsx';
import { currentUser, isLoggedIn } from 'shared/utils/UserUtils.jsx';
import { SESSION_STATUSES } from 'shared/records/Session';

class EventMgmtDisplay extends React.PureComponent {
  componentDidMount() {
    const { eventSlug } = this.props;
    this.eventId = eventSlug
      ? parseInt(eventSlug.split('-')[0], 10)
      : undefined;

    EventDisplayActions.clientDisplayMounted({
      eventId: this.eventId,
      params: {
        start_time: new Date().toISOString(),
        statuses: [
          SESSION_STATUSES.standard,
          SESSION_STATUSES.non_conforming,
          SESSION_STATUSES.conflicting,
        ],
      },
    });

    if (this.eventId) {
      AutomationTemplateDescriptionActions.list({ eventId: this.eventId });
      EventActions.fetchAvailability({ id: this.eventId });

      if (isLoggedIn()) {
        if (currentUser().isClient()) {
          const clientId = currentUser().customer_user_id;
          const managedUsersIds = currentUser().managed_users;
          const managedIds = managedUsersIds
            .filterNot(user => user.archived)
            .map(user => user.id);
          const clientIds = managedIds.push(clientId);

          RegistrationActions.list({
            event_id: this.eventId,
            client_ids: clientIds.toArray(),
          });
        } else {
          RegistrationActions.list({
            event_id: this.eventId,
          });
        }
        ClientCreditActions.list({ eventIds: this.eventId });
        WaitlistActions.fetch(this.eventId);
      }
    }
  }

  render() {
    return (
      <main>
        <AltContainer
          stores={{
            athletes: AthleteStore,
            automationTemplateDescriptions: AutomationTemplateDescriptionStore,
            clientCreditStore: ClientCreditStore,
            cart: CartStore,
            event: EventStore,
            availableTimesStore: AvailableTimesStore,
            eventStaffStore: EventStaffStore,
            eventClientsStore: EventClientsStore,
            membershipDiscountStore: MembershipDiscountStore,
            pdRegistrationPackageStore: PDRegistrationPackageStore,
            registrations: RegistrationStore,
            sspStore: SingleSessionPurchasableContentStore,
            sessionDataStore: SessionDataStore,
            waitlistStore: WaitlistStore,
            clientDataStore: ClientDataStore,
            registrationPackageDataStore: RegistrationPackageDataStore,

            purchaseDrawerOpen: () => ({
              store: PurchaseDrawerStore,
              value: PurchaseDrawerStore.getState().open,
            }),
          }}
        >
          <Event />
          <BrowseEventsBookDrawer />
        </AltContainer>
      </main>
    );
  }
}

export default EventMgmtDisplay;
