import { Iterable, List, Map, OrderedMap, Set, fromJS } from 'immutable';
import moment from 'moment-timezone';

import AutomationTemplateDescriptionStore from 'shared/stores/AutomationTemplateDescriptionStore.jsx';
import AvailableStaffActions from 'event_mgmt/editing/actions/AvailableStaffActions.jsx';
import ApiErrorDialogActions from 'shared/actions/ApiErrorDialogActions.jsx';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventDisplayActions from 'event_mgmt/display/actions/EventDisplayActions.jsx';
import EventTranslator from 'event_mgmt/shared/translators/EventTranslator.jsx';
import EventTypeListingStore from 'shared/stores/EventTypeListingStore.jsx';
import FeePlanStore from 'event_mgmt/shared/stores/FeePlanStore.jsx';
import LocationActions from 'shared/actions/LocationActions.jsx';
import LocationStore from 'shared/stores/LocationStore.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import ResourceActions from 'resources/actions/ResourceActions.js';
import ResourceEditingStore from 'resources/stores/ResourceEditingStore.js';
import Schedule from 'event_mgmt/shared/records/Schedule.jsx';
import { SessionActions, TeamSource, RegistrationPackageSource } from 'sources';
import StaffActions from 'shared/actions/StaffActions.jsx';
import StaffStore from 'shared/stores/StaffStore.jsx';
import StoreActions from 'shared/actions/StoreActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import AvailableResourceIdCollection from 'shared/records/AvailableResourceIdCollection.jsx';
import CustomerEvent, {
  SCHEDULE_TYPE,
} from 'event_mgmt/shared/records/CustomerEvent.jsx';
import PricingAutomationCollection from 'shared/records/PricingAutomationCollection.jsx';
import ScheduleResource from 'shared/records/ScheduleResource.js';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

import { isCollectionWithContents, merge } from 'shared/utils/ObjectUtils.jsx';
import { numberToCurrency } from 'event_mgmt/shared/utils/FormattingUtils.jsx';
import { list as listSessions } from 'sources/SessionSource';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { calculateEndDate } from 'event_mgmt/shared/utils/ScheduleUtils.jsx';
import {
  currentCustomer,
  enabledCustomerFeatures,
} from 'shared/utils/CustomerUtils';
import ScheduleStaff from 'shared/records/ScheduleStaff';
import AdminPageActions from 'containers/events/admin/admin_page/actions';
import DateOfBirth from 'records/DateOfBirth.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';

const DEFAULT_DURATION_IN_SECONDS = 30 * 60;
const MAX_SESSIONS_PER_PAGE = 100;

const eventUrl = id => (id ? `events/${id}` : 'events');

class EventStore extends UpperHandStore {
  constructor() {
    super();
    this.handleReset();

    this.bindListeners({
      handleReset: StoreActions.prepareForReuse,
      handleClientEventDisplayMounted: EventDisplayActions.clientDisplayMounted,
      handleSessionsLoadSuccess: SessionActions.listSuccess,
      listMoreEventSessions: EventActions.loadMoreSessions,
      handleUpdateEventStore: EventActions.updateEventStore,
      handleScheduleTypeSelect: EventActions.scheduleTypeSelected,

      handleStaffSelectToggle: EventActions.staffSelectToggled,

      handleStaffAdded: EventActions.staffAdded,
      handleStaffRemoved: EventActions.staffRemoved,

      handleResourceAdded: EventActions.resourceAdded,
      handleNewResourceAdded: ResourceActions.CREATE_OR_UPDATE_SUCCESS,
      handleResourceRemoved: EventActions.resourceRemoved,

      handleListSwap: EventActions.listSwap,

      handlePreferenceOverrideAdded: EventActions.preferenceOverrideAdded,
      handlePreferenceOverrideRemoved: EventActions.preferenceOverrideRemoved,
      handlePreferenceOverrideAllRemoved:
        EventActions.preferenceOverrideAllRemoved,

      handlePostEvent: EventActions.postEvent,
      handleCloneEvent: EventActions.clone,
      handleCloneSuccess: EventActions.cloneSuccess,
      handleCreateOrUpdateServer: EventActions.createOrUpdateServer,
      handleUpdateEventSelection: EventActions.updateEventSelection,
      handleFetch: EventActions.fetch,
      handleFetchTeam: EventActions.fetchTeam,
      handleFetchTeamSuccess: EventActions.fetchTeamSuccess,

      updateEventStoreFromServer: [
        EventActions.fetchSuccess,
        EventActions.updateSuccess,
        EventActions.createSuccess,
      ],

      handleError: EventActions.error,
      handleLocationCreateSuccess: LocationActions.createOrUpdateSuccess,

      handleFetchAvailability: EventActions.FETCH_AVAILABILITY,
      handleFetchAvailabilitySuccess: EventActions.FETCH_AVAILABILITY_SUCCESS,

      handleStaffCreateSuccess: StaffActions.createSuccess,

      markDirty: EventActions.markDirty,
      updateAvailableStaffAndResources: AvailableStaffActions.LIST_SUCCESS,
      handleCheckConflict: EventActions.CHECK_CONFLICT,
      handleEventTypeSelect: EventActions.EVENT_TYPE_SELECTED,

      handleUpdateStartDate: EventActions.startDateUpdated,
      handleUpdateEndDate: EventActions.endDateUpdated,

      toggleSSPConfirmation: EventActions.toggleSSPConfirmation,
      fillPaymentMethods: EventActions.fillPaymentMethods,
      handleResetDobFields: EventActions.resetDobFields,
      handleResetAgeFields: EventActions.resetAgeFields,
    });
  }

  handleReset() {
    this.availableTimes = List();
    this.customerEvent = new CustomerEvent();
    this.sessionsLoading = false;
    this.sessionsHasMore = true;
    this.sessionsPageLoaded = 1;
    this.fieldErrors = Map();
    this.isDeleting = false;
    this.isLoadingEvent = false;
    this.isSavingEvent = false;
    this.isLoadingAvailability = false;
    this.saveRequests = Map();
    this.savedState =
      'save'; /* make this a function of isSavingEvent and saveRequests, see https://www.pivotaltracker.com/story/show/138437383 */
    this.spots_remaining = 0;
    this.availableStaffIds = Set();
    this.availableResourceIds = Set();
    this.fixedScheduleSessions = OrderedMap();
    this.fixedScheduleSessionsLoaded = false;
    this.isCheckingConflicts = false;
    this.eventsToUpdate = List();
    this.selectedEvents = List();
    this.confirmSSPDialogOpened = false;
  }

  handleClientEventDisplayMounted({ eventId, isTeamsPage = false }) {
    if (!eventId) {
      return;
    }

    this.sessionsLoading = true;
    if (isTeamsPage) {
      this.handleFetchTeam([
        eventId,
        ['team_type', 'participant_ids', 'registered_client_ids'],
      ]);
    } else {
      this.handleFetch([eventId, ['exclusive_membership_ids']]);
    }
  }

  handleSessionsLoadSuccess({ perPage, page }) {
    this.sessionsLoading = false;
    this.sessionsHasMore = perPage * page < this.customerEvent.session_ids.size;
    this.sessionsPageLoaded = page;
  }

  listMoreEventSessions(params) {
    if (!this.sessionsHasMore) return;

    this.sessionsPageLoaded += 1;
    this.sessionsLoading = true;

    listSessions({
      params: {
        per_page: MAX_SESSIONS_PER_PAGE,
        event_ids: this.customerEvent.get('id'),
        page: this.sessionsPageLoaded,
        ...params,
      },
      success: SessionActions.listSuccess,
      error: SessionActions.listError,
    });
  }

  markDirty([key, value]) {
    if (!this.saveRequests || !Iterable.isIterable(this.saveRequests)) {
      // this nonsense avoids some specs spewing a bunchd of red error text even
      // though the specs pass... not sure how it's getting set to a plain object
      this.saveRequests = Map(this.saveRequests);
    }
    if (value) {
      this.saveRequests = this.saveRequests.set(key, true);
    } else {
      this.saveRequests = this.saveRequests.remove(key);
    }
    this.savedState =
      this.saveRequests.filter((v, _k) => !!v).size > 0 ? 'save' : 'saved';
  }

  handleUpdateEventStore(args) {
    const keyPath = args[0];
    const value = args[1];

    if (keyPath.length) {
      this.customerEvent = this.customerEvent.setIn(keyPath, value);
    } else {
      this.customerEvent = value;
    }
    this.markDirty(['EventStore', true]);
  }

  handleEventTypeSelect(eventTypeId) {
    const eventType = EventTypeListingStore.getState().findById(eventTypeId);

    this.customerEvent = this.customerEvent.set('event_type', eventType);
  }

  handleUpdateStartDate(newDate) {
    this.customerEvent = this.customerEvent.setIn(
      ['schedules', 0, 'availability_schedule', 'start_date'],
      newDate
    );

    const existingExclusions = this.customerEvent.getIn([
      'schedules',
      0,
      'availability_schedule',
      'exclusions',
    ]);

    this.customerEvent = this.customerEvent.setIn(
      ['schedules', 0, 'availability_schedule', 'exclusions'],
      existingExclusions.filter(date => date > newDate).sort()
    );

    this.limitDateSpecificDaytimes('after', newDate);

    const availabilitySchedule = this.customerEvent.getIn([
      'schedules',
      0,
      'availability_schedule',
    ]);

    if (availabilitySchedule.frequency) {
      this.setNewEndDate({ startDate: newDate });
    }

    if (availabilitySchedule.repeatingWeekdays().size === 0) {
      this.setRepeatingWeekdays(this.weekdaysInCalendarRange());
    } else {
      this.setRepeatingWeekdays(this.scheduledWeekdaysInCalendarRange());
    }
  }

  handleUpdateEndDate(newDate) {
    this.customerEvent = this.customerEvent.setIn(
      ['schedules', 0, 'availability_schedule', 'end_date'],
      newDate
    );

    this.customerEvent = this.customerEvent.setIn(
      ['schedules', 0, 'availability_schedule', 'stops_by_date'],
      newDate
    );

    const existingExclusions = this.customerEvent.getIn([
      'schedules',
      0,
      'availability_schedule',
      'exclusions',
    ]);

    this.customerEvent = this.customerEvent.setIn(
      ['schedules', 0, 'availability_schedule', 'exclusions'],
      existingExclusions.filter(date => date < newDate).sort()
    );

    this.limitDateSpecificDaytimes('before', newDate);

    const frequency = this.customerEvent.getIn([
      'schedules',
      0,
      'availability_schedule',
      'frequency',
    ]);

    if (!frequency && !this.customerEvent.isOpenBooking()) {
      this.setRepeatingWeekdays(this.weekdaysInCalendarRange());
    }
  }

  handleScheduleTypeSelect(type) {
    if (
      (type === SCHEDULE_TYPE.openBooking &&
        this.customerEvent.isOpenBooking()) ||
      (type === SCHEDULE_TYPE.fixedSchedule &&
        this.customerEvent.isFixedSchedule()) ||
      (type === SCHEDULE_TYPE.classSchedule &&
        this.customerEvent.isClassSchedule())
    ) {
      return;
    }

    if (type === SCHEDULE_TYPE.openBooking) {
      this.customerEvent = this.customerEvent
        .set('schedule_type', type)
        .mergeDeepIn(['schedules', 0], {
          max_size: 1,
          resource_usage_mode: Schedule.RESOURCE_USAGE_ONE,
          duration: DEFAULT_DURATION_IN_SECONDS,
          availability_schedule: {
            frequency: null,
            indefinite: !this.customerEvent.getIn([
              'schedules',
              0,
              'availability_schedule',
              'end_date',
            ]),
          },
        });
    }
    if (type === SCHEDULE_TYPE.fixedSchedule) {
      this.customerEvent = this.customerEvent
        .set('allow_staff_selection', false)
        .set('schedule_type', type)
        .mergeDeepIn(['schedules', 0], {
          max_size: 999,
          resource_usage_mode: Schedule.RESOURCE_USAGE_ALL,
          duration: null,
          availability_schedule: {
            frequency: null,
            indefinite: false,
          },
        });
    }
    if (type === SCHEDULE_TYPE.classSchedule) {
      this.customerEvent = this.customerEvent
        .set('allow_staff_selection', false)
        .set('privacy', false)
        .set('schedule_type', type)
        .set('schedules', List([new Schedule()]));
    }

    this.markDirty(['EventStore', true]);
  }

  handleStaffSelectToggle(checked) {
    if (this.customerEvent.allow_staff_selection !== checked) {
      this.handleUpdateEventStore([['allow_staff_selection'], checked]);
    }
  }

  handleStaffAdded(id) {
    const existingScheduleStaff = this.customerEvent.getIn(
      ['schedules', 0, 'schedule_staff'],
      List()
    );

    if (existingScheduleStaff.some(st => st.customer_user_id === id)) {
      return;
    }

    const newScheduleStaff = new ScheduleStaff({
      customer_user_id: id,
      schedule_id: this.customerEvent.getIn(['schedules', 0, 'id']),
      position: existingScheduleStaff.size,
    });

    this.customerEvent = this.customerEvent.setIn(
      ['schedules', 0, 'schedule_staff'],
      existingScheduleStaff.push(newScheduleStaff)
    );

    this.handleUpdateEventStore([
      ['schedules', 0, 'customer_user_ids'],
      this.customerEvent
        .getIn(['schedules', 0, 'customer_user_ids'])
        .push(id)
        .toSet()
        .toList(),
    ]);

    this.markDirty(['EventStore', true]);

    this.handleCheckConflict();
  }

  handleStaffRemoved(id) {
    this.handleUpdateEventStore([
      ['schedules', 0, 'customer_user_ids'],
      this.customerEvent
        .getIn(['schedules', 0, 'customer_user_ids'])
        .filterNot(existingId => existingId === id),
    ]);
    this.handleUpdateEventStore([
      ['schedules', 0, 'schedule_staff'],
      this.customerEvent
        .getIn(['schedules', 0, 'schedule_staff'])
        .filterNot(st => st.customer_user_id === id),
    ]);
    if (
      this.customerEvent.getIn(['schedules', 0, 'customer_user_ids']).size === 0
    ) {
      this.handleUpdateEventStore([['allow_staff_selection'], false]);
    }
    this.handleCheckConflict();
  }

  handleResourceAdded(resource) {
    const existingScheduleResources = this.customerEvent.getIn(
      ['schedules', 0, 'schedule_resources'],
      List()
    );

    if (existingScheduleResources.some(sr => sr.resource_id === resource.id)) {
      return;
    }

    const newScheduleResource = new ScheduleResource({
      resource_id: resource.id,
      schedule_id: this.customerEvent.getIn(['schedules', 0, 'id']),
      position: existingScheduleResources.size,
    });

    this.customerEvent = this.customerEvent.setIn(
      ['schedules', 0, 'schedule_resources'],
      existingScheduleResources.push(newScheduleResource)
    );

    this.customerEvent = this.customerEvent.deleteIn([
      'schedules',
      0,
      'resource_preferring_staff',
      resource.id.toString(),
    ]);

    this.markDirty(['EventStore', true]);

    this.handleCheckConflict();
  }

  handleNewResourceAdded() {
    this.waitFor(ResourceEditingStore);

    const newResource = ResourceEditingStore.getState().resource;

    this.handleResourceAdded(newResource);

    // [zgoldman:2018-05-16] For some reason I am unable to get both the EventStore
    // and the EventResourceStore to both respond to the ResourceActions.createOrUpdateSuccess
    // action. If this store responds to the action the EventResourceStore will not, but if
    // the listener is removed in this store then the EventResourceStore will respond as
    // expected. Paired with Josh on this and we could not determine a cause so this is a quick
    // workaround to get the EventResourceStore to know about a new Resource.
    EventActions.resourceAdded.defer(newResource);
  }

  handleResourceRemoved(resource) {
    this.customerEvent = this.customerEvent.updateIn(
      ['schedules', 0, 'schedule_resources'],
      (ls = List()) => ls.filterNot(sr => sr.resource_id === resource.id)
    );

    this.customerEvent = this.customerEvent.deleteIn([
      'schedules',
      0,
      'resource_preferring_staff',
      resource.id.toString(),
    ]);

    this.markDirty(['EventStore', true]);

    this.handleCheckConflict();
  }

  handleListSwap({ oldIndex, newIndex, key = 'schedule_resources' }) {
    const originalList = this.customerEvent.getIn(
      ['schedules', 0, key],
      List()
    );

    const swappedItem = originalList.get(oldIndex);
    const swappedList = originalList
      .remove(oldIndex)
      .insert(newIndex, swappedItem)
      .map((sr, position) => sr.merge({ position }));

    this.handleUpdateEventStore([['schedules', 0, key], swappedList]);
  }

  handlePreferenceOverrideAdded([resourceId, staffId]) {
    this.customerEvent = this.customerEvent.updateIn(
      ['schedules', 0, 'resource_preferring_staff', resourceId.toString()],
      (ls = List()) => ls.push(staffId).toSet().toList()
    );

    this.markDirty(['EventStore', true]);
  }

  handlePreferenceOverrideRemoved([resourceId, staffId]) {
    this.customerEvent = this.customerEvent.updateIn(
      ['schedules', 0, 'resource_preferring_staff', resourceId.toString()],
      (ls = List()) => ls.filterNot(id => id === staffId)
    );

    this.markDirty(['EventStore', true]);
  }

  handlePreferenceOverrideAllRemoved(resourceId) {
    this.customerEvent = this.customerEvent.setIn(
      ['schedules', 0, 'resource_preferring_staff', resourceId.toString()],
      List()
    );

    this.markDirty(['EventStore', true]);
  }

  handlePostEvent(_e) {
    this.handleUpdateEventStore([['status'], 'active']);
    if (this.eventIsValid()) {
      this.setPostedEventDefaults();
      this.handleCreateOrUpdateServer();
    } else {
      this.handleUpdateEventStore([['status'], 'draft']);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handleCloneEvent(id) {
    uhApiClient.post({
      url: `${eventUrl(id)}/clone`,
      success: EventActions.cloneSuccess,
      error: EventActions.error,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  handleCloneSuccess(data) {
    const message = new TranslatableMessage({
      id: 'event_mgmt.shared.clone_success',
    });
    MessageWindowActions.addMessage.defer(message);

    const clonedEvent = new EventTranslator(data).toClient();
    window.location.href = clonedEvent.admin_path;
  }

  setPostedEventDefaults() {
    if (this.customerEvent.description === '') {
      this.handleUpdateEventStore([
        ['description'],
        'More event information to follow. Let us know if you have any questions in the meantime.',
      ]);
    }
  }

  eventIsValid() {
    const { status, sport_type: sportType, seasons } = this.customerEvent;
    this.fieldErrors = Map();
    if (
      status === 'cancelled' ||
      status === 'completed' ||
      status === 'deleted'
    ) {
      return true;
    }
    const title = this.customerEvent.title
      ? this.customerEvent.title.trim()
      : this.customerEvent.title;

    if (title === '' && status === 'active') {
      this.addError('title', 'Title is required to post event.');
    }
    if (this.customerEvent.isFixedSchedule() && sportType === '') {
      this.addError('sport_type', 'Sport type is required to post event.');
    }
    if (this.customerEvent.isFixedSchedule() && seasons.size === 0) {
      this.addError('seasons', 'Season is required to post fixed event.');
    }
    this.validateEventType();
    if (!this.customerEvent.isClassSchedule()) {
      this.validatePrice();
      this.validatePaymentMethods();
      this.validateSchedule(this.customerEvent.schedules.get(0));
      this.validateAvailableStaff();
      this.validateAvailableResources();
    }

    return this.fieldErrors.isEmpty();
  }

  validatePaymentMethods() {
    this.clearError('payment_methods');

    if (!this.customerEvent.get('id')) return;

    const paymentMethods = this.customerEvent.get('payment_methods');
    const isInvalid = !paymentMethods.some(m => m);

    if (isInvalid) {
      this.addError(
        'payment_methods',
        'At least one payment method need to be selected.'
      );
    }
  }

  validateEventType() {
    if (this.customerEvent.status !== 'active') {
      return;
    }

    const eventType = this.customerEvent.event_type;

    if (eventType && (eventType.id || (eventType.name && eventType.color))) {
      return;
    }

    this.addError('event_type', 'Event type is required to post event.');
  }

  validatePrice() {
    const descriptions = AutomationTemplateDescriptionStore.getState().forEvent(
      this.customerEvent.id
    );

    let price;
    if (isCollectionWithContents(descriptions)) {
      price =
        PricingAutomationCollection.create(descriptions).getMinimumPrice();
    } else {
      price = this.customerEvent.base_price;
    }
    if (this.customerEvent.get('date_based_price')) {
      if (!this.customerEvent.get('scheduled_date')) {
        this.addError('scheduled_date', 'date is required');
      }
      if (!this.customerEvent.get('scheduled_price')) {
        this.addError('scheduled_price', 'price is required');
      }
    }

    if (
      this.customerEvent.status === 'active' &&
      (price === '' || price == null)
    ) {
      this.addError('base_price', 'Price required to post event.');
    }

    const parsedPrice = parseFloat(this.customerEvent.get('base_price', 0), 10);
    const { feePlan } = FeePlanStore.getState();
    const minPrice = Math.max(feePlan.breakEvenPrice(), 0.49) + 0.01;

    if (!this.customerEvent.freeEvent && parsedPrice < minPrice) {
      this.addError(
        'base_price',
        `Price must be at least ${numberToCurrency(minPrice)}.`
      );
    }

    if (this.customerEvent.freeEvent && parsedPrice !== 0.0) {
      this.addError('base_price', 'Price must be $0.00 when event is free.');
    }
  }

  validateSchedule(schedule) {
    this.validateAvailabilitySchedule(schedule.availability_schedule);
  }

  updateAvailableStaffAndResources(availableResources) {
    const idCollection = new AvailableResourceIdCollection(availableResources);

    this.availableStaffIds = idCollection.staff_ids;
    this.availableResourceIds = idCollection.resource_ids;

    this.validateAvailableStaff();
    this.validateAvailableResources();

    this.isCheckingConflicts = false;
  }

  validateAvailableStaff() {
    this.clearError('unavailable_staff');
    this.clearError('booked_staff');
    if (!this.customerEvent.get('id') && this.customerEvent.isOpenBooking())
      return;

    const scheduledStaff =
      this.customerEvent.schedules.first().customer_user_ids;
    const unavailableStaff = scheduledStaff.filterNot(s =>
      this.availableStaffIds.has(s)
    );

    const errorContent = this.customerEvent.isOpenBooking()
      ? 'booked_staff'
      : 'unavailable_staff';
    unavailableStaff.forEach(staffId => this.addError(errorContent, staffId));
  }

  validateAvailableResources() {
    this.clearError('unavailable_resources');

    // Resources are always available for OB events.
    if (this.customerEvent.isOpenBooking()) {
      return;
    }

    const assignedResourceIds = this.customerEvent
      .getIn(['schedules', 0, 'schedule_resources'], List())
      .map(sr => sr.resource_id);

    const unavailableResourceIds = assignedResourceIds.filterNot(id =>
      this.availableResourceIds.has(id)
    );

    unavailableResourceIds.forEach(id =>
      this.addError('unavailable_resources', id)
    );
  }

  validateAvailabilitySchedule(availabilitySchedule) {
    if (this.customerEvent.status !== 'active') return;

    if (!availabilitySchedule.start_date) {
      this.addError('start_date', 'Start date required to post event.');
    }

    if (!availabilitySchedule.end_date && !availabilitySchedule.indefinite) {
      this.addError('end_date', 'End date required to post event.');
    }

    if (
      moment(availabilitySchedule.end_date).isBefore(
        moment(availabilitySchedule.start_date)
      )
    ) {
      this.addError('end_date', 'End date cannot be before start date.');
    }

    if (!availabilitySchedule.areAllStartTimesPresent()) {
      this.addError('start_time', 'Required.');
    }

    if (!availabilitySchedule.areAllEndTimesPresent()) {
      this.addError('end_time', 'Required.');
    }

    if (!availabilitySchedule.areAllTimesValid()) {
      this.addError('times', 'End time must be after start time.');
    }
  }

  addError(field, error) {
    const fieldErrors = this.fieldErrors.get(field)
      ? this.fieldErrors.get(field).push(error)
      : List([error]);

    this.fieldErrors = this.fieldErrors.set(field, fieldErrors);
  }

  clearError(field) {
    this.fieldErrors = this.fieldErrors.delete(field);
  }

  handleUpdateEventSelection([events, status]) {
    this.selectedEvents = List(events);
    this.eventsToUpdate = this.selectedEvents.map(event =>
      event.set('status', status)
    );
    if (this.eventsToUpdate.size) {
      this.customerEvent = this.eventsToUpdate.first();
      if (
        this.customerEvent.status !==
        this.selectedEvents.find(e => e.id === this.customerEvent.id).status
      ) {
        this.updateSelectedEvents();
      }
    }
  }

  handleSelectedEventUpdateSuccess(data) {
    this.eventsToUpdate = this.eventsToUpdate.filterNot(
      event => event.id === data.id
    );
    if (this.eventsToUpdate.size) {
      this.customerEvent = this.eventsToUpdate.first();
      if (
        this.customerEvent.status !==
        this.selectedEvents.find(e => e.id === this.customerEvent.id).status
      ) {
        this.updateSelectedEvents();
      }
    } else {
      this.eventsToUpdate = List();
      this.selectedEvents = List();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async updateSelectedEvents() {
    await this.updateEvent();
  }

  handleCreateOrUpdateServer(event = null) {
    if (event) {
      this.customerEvent = event;
    }
    if (this.eventIsValid()) {
      this.isSavingEvent = true;
      this.savedState = 'saving';
      if (this.customerEvent.id) {
        this.updateEvent();
      } else {
        this.createEvent();
      }
    }
  }

  async createEvent() {
    const payload = await this.payload();

    return uhApiClient.post({
      url: eventUrl(),
      data: payload,
      success: EventActions.createSuccess,
      error: EventActions.error,
    });
  }

  async updateEvent() {
    if (this.customerEvent.status === 'deleted') {
      this.isDeleting = true;
    }
    const eventId = this.customerEvent.id;
    const payload = await this.payload();
    return uhApiClient.put({
      url: eventUrl(eventId),
      data: payload,
      success: EventActions.updateSuccess,
      error: EventActions.error,
    });
  }

  handleCheckConflict() {
    this.validateAvailableStaff();
    this.validateAvailableResources();
  }

  async payload() {
    const payload = await new EventTranslator(this.customerEvent).toServer();
    const image = this.customerEvent.getIn(['image', 'url']);

    if (!image) {
      payload.image = null;
    }
    if (!this.customerEvent.date_based_price) {
      payload.scheduled_date = null;
      payload.scheduled_price = null;
    }
    if (this.customerEvent.isOpenBooking()) {
      payload.sport_type = '';
    }
    delete payload.date_based_price;
    return JSON.stringify(
      merge(
        { fields: ['image', 'schedule_resources', 'schedule_staff'] },
        { attributes: payload }
      )
    );
  }

  handleFetch(args) {
    const [id, fields = []] = Array.isArray(args) ? args : [args];
    this.isLoadingEvent = true;
    this.isCheckingConflicts = true;

    return uhApiClient.get({
      url: eventUrl(id),
      data: { fields: ['image', ...fields] },
      success: EventActions.fetchSuccess,
      error: EventActions.error,
      requireToken: false,
    });
  }

  handleFetchTeam(args) {
    const [id, fields = []] = Array.isArray(args) ? args : [args];
    this.isLoadingEvent = true;

    return TeamSource.fetch({
      id,
      params: { fields: ['image', ...fields] },
      success: EventActions.fetchTeamSuccess,
      error: EventActions.error,
    });
  }

  handleFetchTeamSuccess(data) {
    AdminPageActions.fetchTeam.defer(data.id);
    EventActions.fetchSuccess.defer(data);
    this.updateEventStoreFromServer(data);
  }

  updateEventStoreFromServer(data) {
    this.markDirty(['EventStore', false]);
    this.isLoadingEvent = false;
    this.isSavingEvent = false;
    this.customerEvent = new EventTranslator(data).toClient();

    const shouldFetchLimits =
      this.customerEvent.get('buyer_limit', 0) > 0 && currentUser().isClient();

    if (shouldFetchLimits) {
      this.isLoadingEvent = true;
      RegistrationPackageSource.fetchEventPurchases({
        eventId: this.customerEvent.get('id'),
        success: () => {
          this.isLoadingEvent = false;
        },
        error: () => {
          this.isLoadingEvent = false;
        },
      });
    }

    if (this.isDeleting && data.status === 'deleted') {
      const message = new TranslatableMessage({
        id: 'event_mgmt.shared.delete_success',
      });
      MessageWindowActions.addMessage.defer(message);
    }

    if (this.selectedEvents.size) {
      this.handleSelectedEventUpdateSuccess(data);
    }
  }

  handleLocationCreateSuccess([_data, parentObject]) {
    if (parentObject === 'event') {
      this.waitFor(LocationStore);

      this.customerEvent = this.customerEvent.setIn(
        ['schedules', 0, 'location'],
        LocationStore.getState().location
      );
    }
  }

  handleDateSelect([date]) {
    this.handleFetchAvailability({ date: moment(date).format('YYYY-MM-DD') });
  }

  handleFetchAvailability({ date, id }) {
    this.isLoadingAvailability = true;

    return uhApiClient.get({
      url: `events/${id || this.customerEvent.id}/availability`,
      data: { date },
      success: EventActions.fetchAvailabilitySuccess,
      error: EventActions.error,
      requireToken: false,
    });
  }

  handleFetchAvailabilitySuccess(data) {
    this.spots_remaining = data.spots_remaining;
    this.fixedScheduleSessions = fromJS(
      data.fixed_schedule_sessions || OrderedMap()
    );
    this.isLoadingAvailability = false;
    this.fixedScheduleSessionsLoaded = true;
  }

  /*
   * TODO[jrogers,2016-08-12]
   * This approach allows us to create a staff member within the
   * event editing screens, but it also couples together the
   * selection of an event's staff member with the creation of
   * a staff member. This is becoming a pattern. Is it a good
   * pattern? If so, delete this comment and those like it above.
   */
  handleStaffCreateSuccess(data) {
    this.waitFor(StaffStore);
    const staffMemberIds = this.customerEvent.getIn([
      'schedules',
      0,
      'customer_user_ids',
    ]);
    this.customerEvent = this.customerEvent.setIn(
      ['schedules', 0, 'customer_user_ids'],
      staffMemberIds.push(data.id)
    );
  }

  handleError(...args) {
    this.markDirty(['EventStore', true]);
    this.isLoadingEvent = false;
    this.isSavingEvent = false;
    const error = args[0];

    if (error.status === 422) {
      ApiErrorDialogActions.error.defer(error);
    } else {
      this.notifyError('Error while processing event', args);
    }
  }

  limitDateSpecificDaytimes(direction, limitingDate) {
    const dateSpecificDaytimes = this.customerEvent.getIn([
      'schedules',
      0,
      'availability_schedule',
      'date_specific_daytimes',
    ]);

    this.customerEvent = this.customerEvent.setIn(
      ['schedules', 0, 'availability_schedule', 'date_specific_daytimes'],
      dateSpecificDaytimes
        .filter((_times, date) => {
          if (direction === 'before') {
            return moment.tz(customerTZ(), date) < limitingDate;
          }
          if (direction === 'after') {
            return moment.tz(customerTZ(), date) > limitingDate;
          }
          return false;
        })
        .sort()
    );
  }

  setRepeatingWeekdays(weekdays) {
    const daytimes = this.customerEvent.getIn([
      'schedules',
      0,
      'availability_schedule',
      'daytimes',
    ]);

    const defaultTimes =
      daytimes.first() || List([Map({ start_time: '', end_time: '' })]);

    const newDaytimes = (weekdays.count() > 0 ? weekdays : Set(['none']))
      .toMap()
      .map(weekday => daytimes.get(weekday, defaultTimes));

    this.customerEvent = this.customerEvent.setIn(
      ['schedules', 0, 'availability_schedule', 'daytimes'],
      newDaytimes
    );

    const frequency = this.customerEvent.getIn([
      'schedules',
      0,
      'availability_schedule',
      'frequency',
    ]);

    if (frequency !== null) this.setNewEndDate({ weekdays });
  }

  weekdaysInCalendarRange() {
    return this.customerEvent
      .getIn(['schedules', 0, 'availability_schedule'])
      .daysInScheduleRange()
      .toSet()
      .map(day => new Date(day).getDay())
      .map(d => String(d));
  }

  scheduledWeekdaysInCalendarRange() {
    const weekdaysInCalendarRange = this.weekdaysInCalendarRange();
    const repeatingWeekdays = this.customerEvent
      .getIn(['schedules', 0, 'availability_schedule'])
      .repeatingWeekdays();
    const overlap = weekdaysInCalendarRange.intersect(repeatingWeekdays);

    if (overlap.size === 0) {
      return weekdaysInCalendarRange;
    }

    return overlap;
  }

  setNewEndDate(options = {}) {
    const availabilitySchedule = this.customerEvent.getIn([
      'schedules',
      0,
      'availability_schedule',
    ]);

    const startDate = options.startDate || availabilitySchedule.start_date;
    const interval = options.interval || availabilitySchedule.interval;
    const repeatDuration =
      options.repeatDuration || availabilitySchedule.repeat_duration;
    const weekdays =
      options.weekdays || availabilitySchedule.repeatingWeekdays();
    const repeatMode = options.repeatMode || availabilitySchedule.repeat_mode;
    const stopsBy =
      repeatMode === 'until' &&
      (options.stopsBy || availabilitySchedule.stops_by_date);

    const newEndDate = calculateEndDate(
      startDate,
      interval,
      repeatDuration,
      weekdays,
      stopsBy
    );

    this.customerEvent = this.customerEvent.setIn(
      ['schedules', 0, 'availability_schedule', 'end_date'],
      newEndDate
    );

    const existingExclusions = this.customerEvent.getIn([
      'schedules',
      0,
      'availability_schedule',
      'exclusions',
    ]);

    this.customerEvent = this.customerEvent.setIn(
      ['schedules', 0, 'availability_schedule', 'exclusions'],
      existingExclusions.filter(date => date < newEndDate).sort()
    );

    this.limitDateSpecificDaytimes('before', newEndDate);
  }

  toggleSSPConfirmation(open) {
    this.confirmSSPDialogOpened = open;
  }

  fillPaymentMethods() {
    const { event_checkout_methods: customerMethods } = currentCustomer();

    let availableMethods = Map(customerMethods);

    if (!enabledCustomerFeatures(['ach'])) {
      availableMethods = availableMethods.delete('bank');
    }

    this.customerEvent = this.customerEvent.set(
      'payment_methods',
      availableMethods
    );
  }

  handleResetAgeFields() {
    this.customerEvent = this.customerEvent.updateIn(
      ['schedules', 0],
      schedule => schedule.merge({ max_age: null, min_age: null })
    );
  }

  handleResetDobFields() {
    this.customerEvent = this.customerEvent.setIn(
      ['schedules', 0, 'date_of_birth'],
      new DateOfBirth()
    );
  }
}

export default alt.createStore(EventStore, 'EventStore');
