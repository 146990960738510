/* eslint-disable react/require-default-props */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { TextField, Dropdown } from '@upperhand/playmaker';

function ResponseEditField({ field, answer, errorText, onFieldChange }) {
  if (!field) {
    return null;
  }

  const fieldType = field.get('field_type');
  const options = field.get('options', []);
  const fieldOptions = options.map(option => ({
    label: option,
    value: option,
  }));

  const handleFieldChange = e => {
    onFieldChange({ field, value: e.target.value });
  };

  switch (fieldType) {
    case 'number':
      return (
        <TextField
          fullWidth
          value={answer}
          errorText={errorText}
          onChange={handleFieldChange}
        />
      );
    case 'short_text':
      return (
        <TextField
          fullWidth
          value={answer}
          errorText={errorText}
          onChange={handleFieldChange}
        />
      );
    case 'long_text':
      return (
        <TextField
          fullWidth
          multiline
          rows={4}
          value={answer}
          errorText={errorText}
          onChange={handleFieldChange}
        />
      );
    case 'dropdown':
      return (
        <Dropdown
          fullWidth
          value={answer}
          items={fieldOptions}
          errorText={errorText}
          onChange={handleFieldChange}
        />
      );
    case 'multiple_choice':
      return (
        <Dropdown
          fullWidth
          value={answer}
          errorText={errorText}
          items={fieldOptions}
          onChange={handleFieldChange}
        />
      );
    default:
      return null;
  }
}

ResponseEditField.propTypes = {
  field: PropTypes.object,
  answer: PropTypes.string,
  onFieldChange: PropTypes.func,
};

export default memo(ResponseEditField);
