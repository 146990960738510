import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { Grid, Typography } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils';

function TableHeader() {
  const intl = useIntl();

  return (
    <div className="registration-responses__table-header">
      <Grid container direction="row" justify="flex-start">
        <Grid item alignItems="center" xs={3}>
          <Typography variant="subtitle2">
            {t('.name', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item alignItems="center" xs={3}>
          <Typography variant="subtitle2">
            {t('.managed_by', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2">
            {t('.gender', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2">
            {t('.total_responses_answered', intl, __filenamespace)}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default memo(TableHeader);
