import * as React from 'react';

function Circle({ className, diameter, color, style }) {
  return (
    <div
      className={className}
      style={{
        height: diameter,
        width: diameter,
        border: `${diameter / 2}px solid ${color}`,
        borderRadius: '50%',
        ...style,
      }}
    >
      &nbsp;
    </div>
  );
}

export default Circle;
