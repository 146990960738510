import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { List } from 'immutable';
import FixedScheduleProfileList from 'shared/components/registration/FixedScheduleProfileList.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import ProfileSelector from 'shared/components/_ProfileSelector.jsx';
import PDRegistrationPackageActions from 'event_mgmt/display/actions/PurchaseDrawerRegistrationPackageActions.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

const styles = {
  totalContainer: {
    marginTop: 20,
    marginBottom: 15,
    fontSize: 16,
  },
};

function Total({ totalPrice }) {
  return (
    <FlexBoxJustify style={styles.totalContainer}>
      <FormattedMessage id={messageId('.total', __filenamespace)} />
      <span style={{ fontWeight: 'bold' }}>
        {totalPrice >= 0 ? (
          <FormattedCurrency value={totalPrice} fromCents />
        ) : (
          <span>&ndash;</span>
        )}
      </span>
    </FlexBoxJustify>
  );
}

const onProfileRemove = id =>
  PDRegistrationPackageActions.fixedScheduleAthleteRemoved(id);

function SimpleFixedScheduleContent({
  intl,
  purchasedCounts,
  event,
  eventIsFull,
  membershipEventDiscounts,
  paymentPlanDescription,
  selectableAthletes,
  selectedAthletes,
  totalPrice,
}) {
  const athletes = selectableAthletes.map(a => {
    const buyerLimitReached = event.isBuyerLimitReached({
      clientId: a.get('id'),
      purchasedCounts,
    });
    const updatedLastName = buyerLimitReached
      ? t('.purchased', intl, __filenamespace, { name: a.get('last_name') })
      : a.get('last_name');

    return a.set('last_name', updatedLastName);
  });

  const handleProfileSelect = (_, __, id) => {
    const buyerLimitReached = event.isBuyerLimitReached({
      clientId: id,
      purchasedCounts,
    });

    if (!buyerLimitReached) {
      PDRegistrationPackageActions.fixedScheduleAthleteAdded(id);
    } else {
      MessageWindowActions.addMessage(
        t('.buyer_limit_reached', intl, __filenamespace)
      );
    }
  };

  return (
    <div>
      <ProfileSelector
        afterProfileCreate={
          PDRegistrationPackageActions.fixedScheduleProfileCreated
        }
        athletes={athletes}
        disabled={eventIsFull}
        disabledText={t('.event_is_full', intl, __filenamespace)}
        header={t('actions.choose_attendees', intl)}
        onChange={handleProfileSelect}
        preventProfileCreation={event.isMembershipExclusive()}
        value={null}
      />

      <FixedScheduleProfileList
        eventDiscounts={
          paymentPlanDescription ? List() : membershipEventDiscounts
        }
        eventPrice={event.price}
        profiles={selectedAthletes}
        onRemove={onProfileRemove}
      />

      <Total totalPrice={totalPrice} />
    </div>
  );
}

export default injectIntl(SimpleFixedScheduleContent);
