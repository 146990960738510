import React from 'react';
import { injectIntl } from 'react-intl';
import {
  Typography,
  Grid,
  Card,
  Icon,
  Button as UHButton,
} from '@upperhand/playmaker';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose, downloadFileFromURL } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';
import { smallScreen } from 'shared/utils/DOMUtils';
import { toSlug } from 'shared/utils/StringUtils.jsx';

import MessagingDrawerActions from 'shared/actions/MessagingDrawerActions.js';
import ClassCancellationActions from 'containers/classes/classesCancellation/Actions';
import ClassCreationActions from 'containers/classes/classesCreation/Actions';
import EventMarketingActions from 'event_mgmt/display/actions/EventMarketingActions';

import { uhColors } from 'shared/styles/uhStyles.jsx';

import { ClassesDataStore } from 'dataStores';

function EventType({ classItem }) {
  if (!classItem) return null;

  const { event_type: eventType } = classItem;
  const { name, color } = eventType;

  return (
    <div className="event-type">
      <div className="event-type-color" style={{ backgroundColor: color }} />
      <p>{name}</p>
    </div>
  );
}

function Header({
  intl = {},
  title = '',
  classId = null,
  createTitle = '',
  hideCreateButton = false,
  onCreate = () => null,
  onClassCreate = () => null,
  classesDataStore = {},
}) {
  const { classes } = classesDataStore;
  const selectedClass = classes.get(classId, null);
  const isMobile = smallScreen();

  const openMessagingDrawer = e => {
    e.preventDefault();
    MessagingDrawerActions.eventDrawerOpened(selectedClass);
  };

  const menuItems = [
    {
      label: t('.export_attendance', intl, __filenamespace),
      available: true,
      onClick: () => {
        downloadFileFromURL({
          url: `events/${selectedClass.id}/attendance_export_csv`,
          fileName: `attendance-${toSlug(
            `${selectedClass.id}-${selectedClass.title}`
          )}.csv`,
        });
      },
    },
    {
      label: t('.send_email', intl, __filenamespace),
      available: true,
      onClick: () => EventMarketingActions.formOpened(),
    },
    {
      label: t('.edit', intl, __filenamespace),
      available: true,
      onClick: () =>
        ClassCreationActions.toggleEditModal({ classItem: selectedClass }),
    },
    {
      label: t('.cancel', intl, __filenamespace),
      available: selectedClass?.status !== 'cancelled',
      onClick: () =>
        ClassCancellationActions.toggleCancelModal({
          classId: selectedClass.id,
        }),
    },
    {
      label: t('.completed', intl, __filenamespace),
      available: true,
      onClick: () =>
        ClassCreationActions.completeClass({
          classItem: selectedClass,
        }),
    },
  ].filter(item => item.available);

  return (
    <div className="classes-list__header">
      <Grid
        container
        className="classes-list__header-container"
        alignItems="center"
        justify="space-between"
        spacing={1}
      >
        <Grid
          item
          container
          justify="space-between"
          alignItems="center"
          md={3}
          xs={12}
        >
          <Typography variant="h4">{title}</Typography>
          {onClassCreate && !hideCreateButton && (
            <div className="button-add-class-container">
              <Button
                onClick={onClassCreate}
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: uhColors.charcoalBlack,
                  height: '38px',
                  padding: '6px, 16px',
                }}
              >
                <span className="add-class-text">
                  {t('.create_class', intl, __filenamespace)}
                </span>
              </Button>
            </div>
          )}
        </Grid>
        <Grid
          item
          container
          md={9}
          xs={12}
          alignItems="flex-end"
          justify="space-between"
          wrap="nowrap"
        >
          {selectedClass && (
            <>
              <Grid item xs="auto">
                <Card
                  contentDirection="row"
                  menuOptions={menuItems}
                  rootStyle={{ background: 'var(--color-bg-gray)' }}
                  contentStyle={{
                    padding: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                  classes={{ content: 'card-content' }}
                  width="100%"
                >
                  <div className="classes-list__header-title">
                    <div className="header-title-text">
                      <Typography variant="subtitle1">
                        {selectedClass?.title || 'No title'}
                      </Typography>
                      <EventType classItem={selectedClass} />
                    </div>
                    <div className="classes-list__header-actions">
                      <div className="header-actions-container">
                        <div className="header-actions-buttons">
                          <a
                            aria-label="Open Messaging Drawer"
                            className="icon-btn"
                            href="/"
                            onClick={openMessagingDrawer}
                          >
                            <Icon name="chatBubble" size="small" />
                          </a>
                          <a
                            aria-label="Open Class in New Tab"
                            href={selectedClass?.path}
                            target="_blank"
                            className="icon-btn"
                            rel="noopener noreferrer"
                          >
                            <Icon name="link" size="small" />
                          </a>
                          <a
                            aria-label='Download "Registrations" CSV'
                            className="icon-btn"
                            href="/"
                            onClick={e => {
                              e.preventDefault();
                              downloadFileFromURL({
                                url: `events/${selectedClass.id}/registrations_csv`,
                                fileName: `registrations-${toSlug(
                                  `${selectedClass.id}-${selectedClass.title}`
                                )}.csv`,
                              });
                            }}
                          >
                            <Icon name="cloudDownload" size="small" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
                <div className="create-sessions-button-container">
                  {isMobile && (
                    <UHButton rounded disabled={!classId} onClick={onCreate}>
                      {createTitle}
                    </UHButton>
                  )}
                </div>
              </Grid>
              <Grid item xs="auto">
                {!isMobile && (
                  <UHButton rounded disabled={!classId} onClick={onCreate}>
                    {createTitle}
                  </UHButton>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

Header.propTypes = {
  intl: PropTypes.object,
  classId: PropTypes.number,
  title: PropTypes.string,
  createTitle: PropTypes.string,
  hideCreateButton: PropTypes.bool,
  onCreate: PropTypes.func,
  onClassCreate: PropTypes.func,
  classesDataStore: PropTypes.object,
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      classesDataStore: ClassesDataStore,
    },
  })
)(Header);
