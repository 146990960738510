import { Map } from 'immutable';

// eslint-disable-next-line import/no-cycle
import { UpperHandStore } from 'dataStores';

import { EmailCampaignsActions } from 'sources';

class EmailCampaignsDataStore extends UpperHandStore {
  constructor() {
    super();

    this.campaigns = Map();

    this.bindListeners({
      createSuccess: EmailCampaignsActions.createSuccess,
      createError: EmailCampaignsActions.createError,
      updateSuccess: EmailCampaignsActions.updateSuccess,
      updateError: EmailCampaignsActions.updateError,
      deleteSuccess: EmailCampaignsActions.deleteSuccess,
      deleteError: EmailCampaignsActions.deleteError,
      cancelSuccess: EmailCampaignsActions.cancelSuccess,
      cancelError: EmailCampaignsActions.cancelError,
      listSuccess: EmailCampaignsActions.listSuccess,
      listError: EmailCampaignsActions.listError,
      fetchSuccess: EmailCampaignsActions.fetchSuccess,
      fetchError: EmailCampaignsActions.fetchError,
    });
  }

  createSuccess(campaign) {
    this.campaigns = this.campaigns.set(campaign.id, campaign);
  }

  createError(...args) {
    this.notifyError('error creating email campaign', args);
  }

  updateSuccess(campaign) {
    this.campaigns = this.campaigns.set(campaign.id, campaign);
  }

  updateError(...args) {
    this.notifyError('error updating email campaign', args);
  }

  deleteSuccess(campaign) {
    this.campaigns = this.campaigns.delete(campaign.id);
  }

  deleteError(...args) {
    this.notifyError('error deleting email campaign', args);
  }

  cancelSuccess(campaign) {
    this.campaigns = this.campaigns.set(campaign.id, campaign);
  }

  cancelError(...args) {
    this.notifyError('error canceling email campaign', args);
  }

  listSuccess({ campaigns }) {
    const fetchedCampaigns = campaigns.groupBy(c => c.id).map(c => c.first());

    this.campaigns = this.campaigns.merge(fetchedCampaigns);
  }

  listError(...args) {
    this.notifyError('error listing email templates', args);
  }

  fetchSuccess(campaign) {
    this.campaigns = this.campaigns.set(campaign.id, campaign);
  }

  fetchError(...args) {
    this.notifyError('error fetching email campaign', args);
  }
}

export default alt.createStore(
  EmailCampaignsDataStore,
  'EmailCampaignsDataStore'
);
