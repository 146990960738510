import * as React from 'react';
import moment from 'moment-timezone';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Confirmation, DateTimePicker, Checkbox } from '@upperhand/playmaker';

import MembershipSuspensionActions from 'memberships/actions/MembershipSuspensionActions.js';
import MembershipSuspensionStore from 'memberships/stores/MembershipSuspensionStore.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import uhTheme from '_uh_theme.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';

import './styles.scss';

const styles = {
  dialog: {
    maxWidth: 350,
  },

  dialogBody: {
    padding: 0,
  },

  header: {
    fontSize: 22,
    padding: 24,
    color: 'white',
    backgroundColor: uhColors.charcoalBlack,
  },

  body: {
    color: uhTheme.palette.textColor,
    fontSize: 15,
    padding: 24,
  },
};

function SuspensionDialog({ membershipSuspensionStore, intl }) {
  const {
    client,
    membership,
    reactivateOnFuture,
    suspendInProgress,
    suspendAt,
    reactivationDate,
    showReactivationDialog,
  } = membershipSuspensionStore;

  const clientName = client ? client.name() : '';
  const membershipName = membership ? membership.name : '';

  return (
    <Confirmation
      title=""
      classes={{
        root: 'modal-dialog',
        title: 'dialog-title',
        actions: 'modal-actions',
      }}
      hasCheckbox={false}
      open={!!client && !showReactivationDialog}
      confirmationDisabled={!suspendAt}
      cancelButtonLabel={t('actions.cancel', intl, __filenamespace)}
      confirmButtonLabel={t(
        suspendInProgress ? '.suspending' : '.confirm_suspension',
        intl,
        __filenamespace
      )}
      onCancel={MembershipSuspensionActions.suspensionAborted}
      onConfirm={MembershipSuspensionActions.suspensionConfirmed}
    >
      <div style={styles.header}>
        <FormattedMessage
          id={messageId('.suspend_membership', __filenamespace)}
        />
      </div>
      <div style={styles.body}>
        <div style={{ marginBottom: 20, lineHeight: '22px' }}>
          <FormattedMessage
            id={messageId('.are_you_sure', __filenamespace)}
            values={{
              clientName: (
                <span style={{ fontWeight: 'bold' }}>{clientName}</span>
              ),
              membershipName: (
                <span style={{ fontWeight: 'bold' }}>{membershipName}</span>
              ),
            }}
          />
        </div>

        <div style={{ fontWeight: 'bold', marginBottom: 3 }}>
          <FormattedMessage id={messageId('.suspend_date', __filenamespace)} />
        </div>
        <div style={{ marginBottom: 9 }}>
          <DateTimePicker
            autoOk
            fullWidth
            name="suspend_at"
            placeholder={t('.choose_date', intl, __filenamespace)}
            value={suspendAt}
            type="date"
            minDate={moment().toDate()}
            onChange={date =>
              MembershipSuspensionActions.setSuspendAtDate(
                moment(date).tz(customerTZ(), true)
              )
            }
          />
        </div>
        <Checkbox
          label={t('.reactivate_on_future', intl, __filenamespace)}
          checked={reactivateOnFuture}
          onChange={() => MembershipSuspensionActions.reactivateOnFuture()}
        />
        {reactivateOnFuture && (
          <div>
            <div style={{ fontWeight: 'bold', marginBottom: 3, marginTop: 9 }}>
              <FormattedMessage
                id={messageId('.reactivation_date', __filenamespace)}
              />
            </div>
            <div>
              <DateTimePicker
                autoOk
                fullWidth
                name="reactivation_date"
                placeholder={t('.choose_date', intl, __filenamespace)}
                value={reactivationDate}
                type="date"
                minDate={moment(suspendAt).add(1, 'days').toDate()}
                onChange={date =>
                  MembershipSuspensionActions.setReactivationDate(
                    moment(date).tz(customerTZ(), true)
                  )
                }
              />
            </div>
          </div>
        )}
      </div>
    </Confirmation>
  );
}

export default compose(
  injectIntl,
  altContainer({
    stores: {
      membershipSuspensionStore: MembershipSuspensionStore,
    },
  })
)(SuspensionDialog);
