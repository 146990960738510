/* eslint-disable react/require-default-props */
import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Map } from 'immutable';
import { TextField, Grid, Spinner, Button } from '@upperhand/playmaker';

import TableContent from 'containers/events/admin/registrationResponses/components/TableContent.jsx';
import TableHeader from 'containers/events/admin/registrationResponses/components/TableHeader.jsx';
import ResponseEditDrawer from 'containers/events/admin/registrationResponses/components/ResponseEditDrawer.jsx';
import Paginator from 'shared/components/Paginator.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose, downloadFileFromURL } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';
import { toSlug } from 'shared/utils/StringUtils.jsx';

import { EventDataStore } from 'dataStores';

import RegistrationResponsesActions from './Actions';
import RegistrationResponsesStore from './Store';

import './styles.scss';

function RegistrationResponses({
  eventId = null,
  registrationResponsesStore = {
    searchText: '',
    responses: Map(),
    loading: false,
    openEditDrawer: false,
    fieldsLoading: false,
    pagination: Map({
      page: 1,
      perPage: 15,
      totalCount: 0,
    }),
  },
  eventDataStore = {
    events: Map(),
  },
}) {
  const intl = useIntl();
  const { events } = eventDataStore;
  const event = events.get(eventId);

  useEffect(() => {
    if (eventId) {
      RegistrationResponsesActions.mounted({ eventId });
    }
  }, [eventId]);

  const {
    searchText,
    responses,
    loading,
    fieldsLoading,
    openEditDrawer,
    selectedResponse,
    pagination,
  } = registrationResponsesStore;

  return (
    <div className="registration-responses">
      <Grid container spacing={1}>
        <Grid item xs={9} md={10}>
          <TextField
            rounded
            fullWidth
            placeholder={t('.search', intl, __filenamespace)}
            variant="outlined"
            icon="search"
            iconPlacement="left"
            classes={{ root: 'registration-responses__search-input' }}
            value={searchText}
            onChange={e =>
              RegistrationResponsesActions.handleSearchChange(e.target.value)
            }
          />
        </Grid>
        <Grid item xs={3} md={2}>
          <Button
            rounded
            fullWidth
            icon="cloudDownload"
            classes={{ root: 'registration-responses__export-btn' }}
            disabled={!eventId || !event}
            onClick={() =>
              downloadFileFromURL({
                url: `events/${eventId}/registrations_csv`,
                fileName: `registrations-${toSlug(
                  `${eventId}-${event?.title}`
                )}.csv`,
              })
            }
          >
            {t('.export', intl, __filenamespace)}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TableHeader />
        </Grid>
        <Grid item xs={12}>
          {!loading && (
            <div className="registration-responses__list">
              <TableContent
                responses={responses.toArray()}
                onEditResponse={RegistrationResponsesActions.toggleEditDrawer}
              />
              <Paginator
                showInfo
                pageLimit={0}
                className="registration-responses__paginator"
                currentPage={pagination.get('page')}
                perPage={pagination.get('perPage')}
                totalCount={pagination.get('totalCount')}
                onPageSelect={RegistrationResponsesActions.handlePageSelect}
              />
            </div>
          )}
          {loading && (
            <div className="registration-responses__loading">
              <Spinner />
            </div>
          )}
        </Grid>
      </Grid>
      <ResponseEditDrawer
        eventId={eventId}
        loading={fieldsLoading}
        open={openEditDrawer}
        selectedResponse={selectedResponse}
        onFieldChange={RegistrationResponsesActions.updateResponse}
        onSave={RegistrationResponsesActions.saveResponse}
        onClose={() => RegistrationResponsesActions.toggleEditDrawer({})}
      />
    </div>
  );
}

RegistrationResponses.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  registrationResponsesStore: PropTypes.object,
  eventDataStore: PropTypes.object,
};

export default compose(
  memo,
  altContainer({
    stores: {
      registrationResponsesStore: RegistrationResponsesStore,
      eventDataStore: EventDataStore,
    },
  })
)(RegistrationResponses);
