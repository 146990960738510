import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Confirmation, Grid } from '@upperhand/playmaker';
import { Map } from 'immutable';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import ExplanatoryText from 'shared/components/_ExplanatoryText.jsx';
import { Preference } from 'customers/settings/components/PreferencesTab.jsx';
import ClientEmailDrawerEditor from 'customers/settings/components/preferences_tab/ClientEmailDrawerEditor.jsx';

import { t, messageId } from 'shared/utils/LocaleUtils';

import CustomerActions from 'shared/actions/CustomerActions.jsx';

import './styles.scss';

function ClientEmailSettings({ emailPreferences, customizedEmails }) {
  const intl = useIntl();
  const [selectedEmailType, setSelectedEmailType] = useState(null);
  const [emailTypeToDelete, setEmailTypeToDelete] = useState(null);
  const [loading, setLoading] = useState(false);

  const allActive = !emailPreferences.some(p => !p);

  const getDrawerTitle = () => {
    if (customizedEmails[selectedEmailType]) {
      return t('.drawer_title_edit', intl, __filenamespace, {
        emailType: (
          <FormattedMessage
            id={messageId(`.${selectedEmailType}`, __filenamespace)}
            defaultMessage={selectedEmailType?.replace(/_/g, ' ')}
          />
        ),
      });
    }

    return t('.drawer_title_customize', intl, __filenamespace, {
      emailType: (
        <FormattedMessage
          id={messageId(`.${selectedEmailType}`, __filenamespace)}
          defaultMessage={selectedEmailType?.replace(/_/g, ' ')}
        />
      ),
    });
  };

  const handleCreate = template => {
    const data = template.set('email_type', selectedEmailType).toJS();

    setLoading(true);

    CustomerActions.createBusinessEmail({
      body: {
        attributes: {
          ...data,
        },
      },
      onSuccessCallback: () => {
        setSelectedEmailType(null);
        setLoading(false);
      },
    });
  };

  const handleUpdate = template => {
    setLoading(true);

    CustomerActions.updateBusinessEmail({
      emailId: template.id,
      body: {
        attributes: {
          ...template.toJS(),
        },
      },
      onSuccessCallback: () => {
        setSelectedEmailType(null);
        setLoading(false);
      },
    });
  };

  const handleDelete = preference => {
    CustomerActions.deleteBusinessEmail({
      emailId: customizedEmails[preference],
    });
  };

  return (
    <Preference
      className="client-email-settings"
      name={t('.email_settings', intl, __filenamespace)}
    >
      <ExplanatoryText
        text={t('.email_settings_help', intl, __filenamespace)}
      />
      <FormGroup style={{ marginTop: '1em' }}>
        <FormControlLabel
          label={t('.all_emails', intl, __filenamespace)}
          control={
            <Checkbox
              checked={allActive}
              name="all"
              onChange={(_e, checked) => {
                CustomerActions.changeEmailPreference({
                  preference: 'all',
                  checked,
                });
              }}
            />
          }
        />
        <Grid container>
          {emailPreferences.entrySeq().map(([preference, value]) => (
            <Grid key={preference} xs={12} item container>
              <Grid item xs={8} md={9} lg={8}>
                <FormControlLabel
                  label={
                    <FormattedMessage
                      id={messageId(`.${preference}`, __filenamespace)}
                      defaultMessage={preference.replace(/_/g, ' ')}
                    />
                  }
                  control={
                    <Checkbox
                      checked={value}
                      name={preference}
                      onChange={(_e, checked) =>
                        CustomerActions.changeEmailPreference({
                          preference,
                          checked,
                        })
                      }
                    />
                  }
                />
              </Grid>
              <Grid item xs={4} md={3} lg={4}>
                {!customizedEmails[preference] && (
                  <Button
                    className="client-email-settings__customize-btn"
                    onClick={() => setSelectedEmailType(preference)}
                  >
                    {t('.customize', intl, __filenamespace)}
                  </Button>
                )}
                {customizedEmails[preference] && (
                  <Stack direction="row" alignItems="center">
                    <Button
                      className="client-email-settings__customize-btn"
                      onClick={e => {
                        e.preventDefault();
                        setSelectedEmailType(preference);
                      }}
                    >
                      {t('.custom_message', intl, __filenamespace)}
                    </Button>
                    <IconButton
                      size="small"
                      className="client-email-settings__action-btn"
                      onClick={() => setSelectedEmailType(preference)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      className="client-email-settings__action-btn"
                      onClick={() => setEmailTypeToDelete(preference)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </FormGroup>
      <ClientEmailDrawerEditor
        title={getDrawerTitle()}
        isLoading={loading}
        open={Boolean(selectedEmailType)}
        emailTemplateId={customizedEmails[selectedEmailType]}
        onClose={() => setSelectedEmailType(null)}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
      />
      <Confirmation
        classes={{
          root: 'client-email-settings__delete-confirmation',
        }}
        open={Boolean(emailTypeToDelete)}
        hasCheckbox={false}
        title={t('.delete_confirmation_title', intl, __filenamespace)}
        onConfirm={() => {
          handleDelete(emailTypeToDelete);
          setEmailTypeToDelete(null);
        }}
        onCancel={() => setEmailTypeToDelete(null)}
      >
        <Typography>
          {t('.delete_confirmation_info', intl, __filenamespace, {
            emailType: (
              <FormattedMessage
                id={messageId(`.${emailTypeToDelete}`, __filenamespace)}
                defaultMessage={emailTypeToDelete?.replace(/_/g, ' ')}
              />
            ),
          })}
        </Typography>
      </Confirmation>
    </Preference>
  );
}

ClientEmailSettings.propTypes = {
  emailPreferences: PropTypes.instanceOf(Map).isRequired,
  customizedEmails: PropTypes.object.isRequired,
};

export default memo(ClientEmailSettings);
