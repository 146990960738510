import * as React from 'react';

import CartActions from 'event_mgmt/shared/actions/CartActions.jsx';
import FixedScheduleContent from 'event_mgmt/display/components/purchase_drawer/_FixedScheduleContent.jsx';
import OpenBookingScheduling from 'shared/components/OpenBookingScheduling.jsx';
import PDRegistrationPackageActions from 'event_mgmt/display/actions/PurchaseDrawerRegistrationPackageActions.jsx';

const styles = {
  root: {
    textAlign: 'start',
    fontSize: 15,
  },
};

const addOrUpdateCart =
  ({ redirect }) =>
  (registrationPackage, orderItemId) => {
    if (orderItemId === null || orderItemId === undefined) {
      CartActions.registrationPackageAdded(registrationPackage, { redirect });
    } else {
      CartActions.update(orderItemId, registrationPackage, { redirect });
    }
  };

function Content({
  purchasedCounts,
  athletes,
  availableTimesStore,
  cart,
  event,
  membershipDiscountStore,
  pdRegistrationPackageStore,
  sessions,
  sessionsShowMore,
  sessionsLoadingMore,
  onSessionsLoadMore,
  sspStore,
  staff,
}) {
  return (
    <div style={styles.root}>
      {event.customerEvent.isOpenBooking() ? (
        <OpenBookingScheduling
          athletes={athletes}
          eventStore={event}
          availableTimesStore={availableTimesStore}
          isUpdatingCart={cart.isUpdatingCart}
          isNavigatingToCart={cart.isNavigatingToCart}
          membershipEventDiscounts={membershipDiscountStore.findByEventId(
            event.customerEvent.id
          )}
          onAddToCart={(registrationPackage, orderItemId) => {
            addOrUpdateCart({ redirect: false })(
              registrationPackage,
              orderItemId
            );
            PDRegistrationPackageActions.addAttendeeClicked();
          }}
          onContinueToCart={addOrUpdateCart({ redirect: true })}
          pdRegistrationPackageStore={pdRegistrationPackageStore}
          schedulingActions={PDRegistrationPackageActions}
          schedulingStore={pdRegistrationPackageStore}
          showAddToCart
          staff={staff}
        />
      ) : (
        <FixedScheduleContent
          purchasedCounts={purchasedCounts}
          athletes={athletes}
          cartStore={cart}
          eventStore={event}
          membershipEventDiscounts={membershipDiscountStore.findByEventId(
            event.customerEvent.id
          )}
          pdRegistrationPackageStore={pdRegistrationPackageStore}
          sessions={sessions}
          sessionsShowMore={sessionsShowMore}
          sessionsLoadingMore={sessionsLoadingMore}
          onSessionsLoadMore={onSessionsLoadMore}
          sspStore={sspStore}
        />
      )}
    </div>
  );
}

export default Content;
