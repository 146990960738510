import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { Grid, Spinner, Typography } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils.js';
import RefundModalActions from 'containers/reports/refundModal/Actions';
import DotsMenu from './DotsMenu.jsx';
import OrderItemCard from './OrderItemCard.jsx';
import OrderDetails from './OrderDetails.jsx';

function Content({
  balances,
  clients,
  creditPasses,
  dotsMenuAnchorEl,
  events,
  eventTypes,
  isLoading,
  orderStatus,
  refundedAmount,
  waivedAmount,
  paidAmount,
  remainingAmount,
  onNoteSave,
  intl,
  order = {},
}) {
  const orderItem = order.getIn(['order_items', 0]);

  return (
    <div className="order-summary-drawer__content">
      <DotsMenu intl={intl} anchorEl={dotsMenuAnchorEl} />
      {orderItem && (
        <>
          <OrderDetails
            client={clients.get(orderItem.get('buyer_id'))}
            order={order}
            orderItem={orderItem}
            orderStatus={orderStatus}
            isLoading={isLoading}
            refundedAmount={refundedAmount}
            waivedAmount={waivedAmount}
            paidAmount={paidAmount}
            remainingAmount={remainingAmount}
            onNoteSave={onNoteSave}
            intl={intl}
          />

          <Typography className="order-items-header" variant="subtitle1">
            {t('.items_title', intl, __filenamespace)}
          </Typography>

          {isLoading ? (
            <Grid container xs={12} alignItems="center" justify="center">
              <Spinner />
            </Grid>
          ) : (
            order
              .get('order_items')
              .map(oi => (
                <OrderItemCard
                  key={oi.get('id')}
                  balances={balances}
                  clients={clients}
                  creditPasses={creditPasses}
                  events={events}
                  eventTypes={eventTypes}
                  onRefund={RefundModalActions.openModal}
                  order={order}
                  orderItem={oi}
                  intl={intl}
                />
              ))
          )}
        </>
      )}
    </div>
  );
}

Content.propTypes = {
  order: PropTypes.object,
};

export default injectIntl(Content);
