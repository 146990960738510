import { Map, fromJS } from 'immutable';

import ClientCreditActions from 'shared/actions/ClientCreditActions';
import EnrolledEventListActions from 'user_management/shared/actions/EnrolledEventListActions.jsx';
import POSEventActions from 'point_of_sale/actions/POSEventActions.jsx';
import POSEventStore from 'point_of_sale/stores/POSEventStore.jsx';
import POSProductListActions from 'point_of_sale/actions/POSProductListActions.jsx';
import POSStore from 'point_of_sale/stores/POSStore.jsx';
import QuickScheduleActions from 'calendar/actions/QuickScheduleActions.jsx';
import RegistrationActions from 'event_mgmt/display/actions/RegistrationActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class ClientCreditStore extends UpperHandStore {
  constructor() {
    super();

    this.clientCredits = Map();
    this.eventId = null;
    this.isLoading = false;

    this.bindListeners({
      list: ClientCreditActions.list,
      listSuccess: ClientCreditActions.listSuccess,
      listError: ClientCreditActions.listError,

      scheduledRegistration: RegistrationActions.scheduleSuccess,
      cancelledRegistration: [
        RegistrationActions.cancelSuccess,
        EnrolledEventListActions.cancelRegistrationSuccess,
      ],

      listPOSCredits: [
        POSProductListActions.eventSelected,
        POSEventActions.fetchEventSuccess,
      ],

      listQSCredits: QuickScheduleActions.selectClient,

      listClientDetailsDrawerCredits:
        EnrolledEventListActions.toggleExpandEvent,
    });
  }

  list({ eventIds, clientIds, page = 1 } = {}) {
    this.isLoading = true;
    return uhApiClient.get({
      url: 'credit_counts',
      data: { client_ids: clientIds, event_ids: eventIds, page, per_page: 50 },
      success: {
        action: ClientCreditActions.listSuccess,
        args: [{ eventIds, clientIds }],
      },
      error: ClientCreditActions.listError,
    });
  }

  listSuccess([
    // eslint-disable-next-line camelcase
    { credit_counts: creditCounts, page, per_page, total_count },
    { eventIds, clientIds },
  ]) {
    if (page === 1) {
      this.clientCredits = Map();
    }
    const clientCredits = fromJS(creditCounts)
      .groupBy(c => c.get('event_id'))
      .map(eventCredits =>
        eventCredits.groupBy(c => c.get('client_id')).map(c => c.first())
      );
    this.clientCredits = this.clientCredits.mergeDeep(clientCredits);

    // eslint-disable-next-line camelcase
    if (page * per_page < total_count) {
      this.list({ eventIds, clientIds, page: page + 1 });
    } else {
      this.isLoading = false;
    }
  }

  listError(...args) {
    this.notifyError('error listing credit counts', args);
  }

  scheduledRegistration(registrations) {
    const eventIds = registrations.map(r => r.event_id);

    this.list({ eventIds });
  }

  cancelledRegistration([_, registration]) {
    this.list({ eventIds: [registration.event_id] });
  }

  listPOSCredits() {
    this.waitFor(POSStore);
    this.waitFor(POSEventStore);

    this.clientCredits = Map();
    const eventIds = [POSEventStore.getState().event.id];
    const clientIds = POSStore.getState()
      .allProfiles.map(c => c.id)
      .toJS();

    this.list({ eventIds, clientIds });
  }

  listQSCredits([client, event]) {
    this.clientCredits = Map();
    this.list({ eventIds: [event.id], clientIds: [client.id] });
  }

  listAdminEventCredits({ eventId }) {
    this.clientCredits = Map();
    this.list({ eventIds: [eventId] });
  }

  listClientDetailsDrawerCredits({ clientId, eventId }) {
    this.clientCredits = Map();
    this.list({ eventIds: [eventId], clientIds: [clientId] });
  }

  listRevokedCredits({ registration_package: registrationPackage }) {
    const eventIds = [registrationPackage.event_id];
    const clientIds = registrationPackage.client_ids;

    this.clientCredits = Map();
    this.list({ eventIds, clientIds });
  }

  listAgendaCredits() {
    this.clientCredits = Map();
    this.list({});
  }
}

export default alt.createStore(ClientCreditStore, 'ClientCreditStore');
