import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { FormModal } from '@upperhand/playmaker';
import { Box } from '@mui/material';

import EditProfile from 'user_management/shared/components/_EditProfile.jsx';
import AdditionalEmailsEditor from 'user_management/shared/components/AdditionalEmailsEditor.jsx';
import FieldErrors from 'shared/records/FieldErrors.jsx';

import { uhFlexbox } from 'shared/styles/uhStyles.jsx';
import { t } from 'shared/utils/LocaleUtils';
import { Map } from 'immutable';

function ClientEditor({ actions, currentEmailData, errors, intl }) {
  return (
    <EditProfile>
      <AdditionalEmailsEditor
        actionButtons={
          <Box
            sx={{
              alignSelf: 'center',
              marginTop: '1em',
              marginBottom: '1em',
            }}
          />
        }
        intl={intl}
        errors={errors}
        emailData={currentEmailData}
        onChange={e =>
          actions.updateStore({
            key: e.target.name,
            value: e.target.value,
          })
        }
      />
    </EditProfile>
  );
}

function getLabel({ isEditingMode, intl }) {
  return isEditingMode
    ? t('.update', intl, __filenamespace)
    : t('.create', intl, __filenamespace);
}

function AdditionalEmailsDialog({
  actions,
  closeDialog = () => {},
  open = false,
  currentEmailData = Map({
    id: null,
    email: '',
    first_name: '',
    last_name: '',
  }),
  errors = new FieldErrors(),
  intl = {},
}) {
  const isEditingMode = currentEmailData.get('id');
  function handleCreateOrUpdate() {
    if (isEditingMode) {
      actions.updateAdditionalEmail();
    } else {
      actions.postAdditionalEmail();
    }
  }
  return (
    <FormModal
      autoScrollBodyContent
      bodyStyle={{
        padding: '1em 2em 1em 2em',
        alignItems: 'center',
        ...uhFlexbox.column,
      }}
      open={open}
      contentStyle={{ width: 350 }}
      title={t('.title', intl, __filenamespace)}
      actions={[
        {
          type: 'primary',
          label: getLabel({ isEditingMode, intl }),
          action: () => handleCreateOrUpdate(),
        },
        {
          type: 'tertiary',
          label: 'Back',
          action: closeDialog,
        },
      ]}
    >
      <ClientEditor
        intl={intl}
        closeDialog={closeDialog}
        currentEmailData={currentEmailData}
        errors={errors}
        actions={actions}
      />
    </FormModal>
  );
}

AdditionalEmailsDialog.propTypes = {
  closeDialog: PropTypes.func,
  open: PropTypes.bool,
  currentEmailData: PropTypes.any,
  errors: PropTypes.instanceOf(FieldErrors),
  intl: PropTypes.object,
};

export default injectIntl(AdditionalEmailsDialog);
