import { Map } from 'immutable';

// eslint-disable-next-line import/no-cycle
import { UpperHandStore } from 'dataStores';
import { RegistrationPackageActions } from 'sources';

class RegistrationPackageDataStore extends UpperHandStore {
  constructor() {
    super();

    this.registrationPackages = Map();
    this.purchasedCounts = Map();

    this.bindListeners({
      listSuccess: RegistrationPackageActions.listSuccess,
      listError: RegistrationPackageActions.listError,
      updateSuccess: RegistrationPackageActions.updateSuccess,
      fetchEventPurchasesSuccess:
        RegistrationPackageActions.fetchEventPurchasesSuccess,
      fetchEventPurchasesError:
        RegistrationPackageActions.fetchEventPurchasesError,
    });
  }

  listSuccess({ registration_packages: registrationPackages }) {
    const fetchedRegistrationPackages = registrationPackages
      .groupBy(rp => rp.client_ids.first())
      .map(c =>
        c
          .groupBy(e => e.event_id)
          .map(x => x.groupBy(e => e.id).map(p => p.first()))
      );
    this.registrationPackages = this.registrationPackages.mergeDeep(
      fetchedRegistrationPackages
    );
  }

  listError(...args) {
    this.notifyError('error fetching registration packages', args);
  }

  updateSuccess(pkg) {
    const clientId = pkg.client_ids.first();
    const eventId = pkg.event_id;
    const packageId = pkg.id;

    this.registrationPackages = this.registrationPackages.setIn(
      [clientId, eventId, packageId],
      pkg
    );
  }

  fetchEventPurchasesSuccess({ purchase_counts: purchasedCounts, eventId }) {
    this.purchasedCounts = this.purchasedCounts.set(
      eventId,
      Map(purchasedCounts)
    );
  }

  fetchEventPurchasesError() {
    this.notifyError('error fetching event purchases');
  }
}

export default alt.createStore(
  RegistrationPackageDataStore,
  'RegistrationPackageDataStore'
);
