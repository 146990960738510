import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { injectIntl } from 'react-intl';

import {
  Grid,
  Spinner,
  Typography,
  TextField,
  Button,
  Menu,
} from '@upperhand/playmaker';

import ClientDetails from 'shared/components/client/clientDetails/ClientDetails.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import Circle from 'shared/components/Circle.jsx';

import { currentUser } from 'shared/utils/UserUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

const SALE_SOURCE_ONLINE = 'online';

function OrderDetails({
  client,
  isLoading,
  refundedAmount,
  waivedAmount,
  paidAmount,
  remainingAmount,
  onNoteSave,
  intl,
  order = {},
  orderItem = {},
}) {
  const [saving, setSaving] = React.useState(false);
  const [editNote, setEditNote] = React.useState(false);
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [note, setNote] = React.useState(order?.note);
  const isStaff = currentUser().isStaff();
  const adjustments = order
    .get('order_items')
    .flatMap(oi => oi.applied_adjustments);
  const membershipDiscount = adjustments
    .filter(
      a =>
        a.get('type') === 'MembershipRetailDiscount' ||
        a.get('type') === 'MembershipEventDiscount' ||
        a.get('type') === 'MembershipCreditPassDiscount'
    )
    .reduce((sum, adj) => sum - adj.get('amount'), 0);
  const manualDiscount = adjustments
    .filter(a => a.get('type') === 'Manual')
    .reduce((sum, adj) => sum - adj.get('amount'), 0);
  const accountCreditDiscount = adjustments
    .filter(a => a.get('type') === 'AccountCredit')
    .reduce((sum, adj) => sum - adj.get('amount'), 0);
  const coupon = order.get('coupon');

  const handleNoteChange = e => {
    setNote(e.target.value);
  };

  const handleSaveNote = () => {
    setSaving(true);
    onNoteSave({
      note,
      onSuccessCallback: () => {
        setEditNote(false);
        setNote(note);
        setSaving(false);
      },
    });
  };

  const handleDeleteNote = () => {
    setSaving(true);
    onNoteSave({
      note: '',
      onSuccessCallback: () => {
        setEditNote(false);
        setNote('');
        setSaving(false);
      },
    });
  };

  return (
    <>
      <Grid className="order-details__header" container spacing={1}>
        <Grid item xs={12}>
          <div className="order-details__title">
            <Typography align="center" variant="body1">
              {t('.order_number', intl, __filenamespace, {
                n: order.get('order_number'),
              })}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" variant="h3">
            <FormattedCurrency value={order.total || 0} fromCents />
          </Typography>
        </Grid>
      </Grid>
      {isLoading ? (
        <Grid container xs={12} alignItems="center" justify="center">
          <Spinner />
        </Grid>
      ) : (
        <ClientDetails client={client} />
      )}
      <div className="order-details__card">
        <Grid container spacing={1}>
          <Grid container item xs={12}>
            <Typography className="order-details__title" variant="subtitle2">
              {t('.order_details', intl, __filenamespace)}
            </Typography>
          </Grid>
          <Grid container item xs={12} justify="space-between">
            <Grid item>
              <Typography variant="body1">
                {t('.date', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" align="right">
                {t('.order_date', intl, __filenamespace, {
                  date: orderItem.get('ordered_at')
                    ? moment(orderItem.get('ordered_at')).format('MMM DD, YYYY')
                    : '-',
                  time: orderItem.get('ordered_at')
                    ? moment(orderItem.get('ordered_at')).format('h:mm a')
                    : '-',
                })}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} justify="space-between">
            <Grid item>
              <Typography variant="body1">
                {t('.order_method', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" align="right">
                {t(`.${order.get('sale_source')}`, intl, __filenamespace)}
              </Typography>
            </Grid>
          </Grid>
          {order.get('sale_source') !== SALE_SOURCE_ONLINE &&
            order.get('checkout_by_name') && (
              <Grid container item xs={12} justify="space-between">
                <Grid item>
                  <Typography variant="body1">
                    {t('.sold_by', intl, __filenamespace)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" align="right">
                    {order.get('checkout_by_name')}
                  </Typography>
                </Grid>
              </Grid>
            )}
        </Grid>
      </div>
      {isStaff && (
        <div className="order-details__card">
          <Grid container spacing={1}>
            <Grid item container xs={12} alignItems="center">
              <div className="order-details__circle-title">
                <Circle
                  className="order-details__circle"
                  diameter={10}
                  style={{ backgroundColor: 'var(--color-warning-yellow)' }}
                />
                <Typography
                  className="order-details__title"
                  variant="subtitle2"
                >
                  {t('.order_note', intl, __filenamespace)}
                </Typography>
              </div>

              {!editNote && (
                <>
                  <Button
                    rounded
                    classes={{ root: 'order-details__more-button' }}
                    type="tertiary"
                    icon="moreVert"
                    onClick={e => setMenuAnchor(e.currentTarget)}
                  />
                  <Menu
                    anchor={menuAnchor}
                    items={[
                      {
                        label: t('.edit_note', intl, __filenamespace),
                        onClick: () => {
                          setEditNote(true);
                          setNote(order?.note);
                        },
                        visible: !editNote && note,
                      },
                      {
                        label: t('.add_note', intl, __filenamespace),
                        onClick: () => {
                          setNote('');
                          setEditNote(true);
                        },
                        visible: !note && !editNote,
                      },
                      {
                        label: t('.delete_note', intl, __filenamespace),
                        visible: note,
                        onClick: handleDeleteNote,
                      },
                    ].filter(i => i.visible)}
                    onClose={() => setMenuAnchor(null)}
                  />
                </>
              )}
            </Grid>
            {!note && (
              <Grid item container xs={12} justify="center">
                <Typography variant="body1">
                  {t('.empty_note', intl, __filenamespace)}
                </Typography>
              </Grid>
            )}
            {note !== null && (
              <>
                <Grid item xs={12}>
                  {editNote ? (
                    <TextField
                      multiline
                      fullWidth
                      rows={6}
                      value={note}
                      placeholder={t(
                        '.note_placeholder',
                        intl,
                        __filenamespace
                      )}
                      onChange={handleNoteChange}
                    />
                  ) : (
                    <Typography variant="body1">{note}</Typography>
                  )}
                </Grid>
                {editNote && (
                  <Grid item xs={12}>
                    <div className="order-details__button-group">
                      <Button
                        type="tertiary"
                        onClick={() => {
                          setEditNote(false);
                          setNote(order?.note);
                        }}
                      >
                        {t('.cancel_note', intl, __filenamespace)}
                      </Button>
                      <Button disabled={saving} onClick={handleSaveNote}>
                        {t('.save_note', intl, __filenamespace)}
                      </Button>
                    </div>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </div>
      )}
      {isLoading ? (
        <Grid container xs={12} alignItems="center" justify="center">
          <Spinner />
        </Grid>
      ) : (
        <>
          <div className="order-details__card order-details__with-bottom order-details__with-middle">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography
                  className="order-details__title"
                  variant="subtitle2"
                >
                  {t('.total', intl, __filenamespace)}
                </Typography>
              </Grid>
              <Grid container item xs={12} justify="space-between">
                <Grid item>
                  <Typography variant="body1">
                    {t('.subtotal', intl, __filenamespace)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" align="right">
                    <FormattedCurrency value={order.subtotal || 0} fromCents />
                  </Typography>
                </Grid>
              </Grid>
              {manualDiscount > 0 && (
                <Grid container item xs={12} justify="space-between">
                  <Grid item>
                    <Typography variant="body1">
                      {t('.manual_discount', intl, __filenamespace)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" align="right">
                      <FormattedCurrency value={manualDiscount} fromCents />
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {coupon?.code && (
                <Grid container item xs={12} justify="space-between">
                  <Grid item>
                    <Typography variant="body1">
                      {t('.discount_code', intl, __filenamespace)}
                    </Typography>
                    <Typography
                      variant="body1"
                      className="order-details__coupon-name"
                    >
                      {coupon?.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" align="right">
                      {coupon?.code}
                    </Typography>
                    <Typography variant="subtitle1" align="right">
                      <FormattedCurrency value={coupon?.value || 0} fromCents />
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {membershipDiscount > 0 && (
                <Grid container item xs={12} justify="space-between">
                  <Grid item>
                    <Typography variant="body1">
                      {t('.membership_discount', intl, __filenamespace)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" align="right">
                      <FormattedCurrency value={membershipDiscount} fromCents />
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {accountCreditDiscount > 0 && (
                <Grid container item xs={12} justify="space-between">
                  <Grid item>
                    <Typography variant="body1">
                      {t('.account_credit', intl, __filenamespace)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" align="right">
                      <FormattedCurrency
                        value={accountCreditDiscount}
                        fromCents
                      />
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid container item xs={12} justify="space-between">
                <Grid item>
                  <Typography variant="body1">
                    {t('.taxes', intl, __filenamespace)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" align="right">
                    <FormattedCurrency value={order.tax || 0} fromCents />
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} justify="space-between">
                <Grid item>
                  <Typography variant="body1">
                    {t('.fees', intl, __filenamespace)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" align="right">
                    <FormattedCurrency value={order.fee || 0} fromCents />
                  </Typography>
                </Grid>
              </Grid>
              {Boolean(order.insurance_amount) && (
                <Grid container item xs={12} justify="space-between">
                  <Grid item>
                    <Typography variant="body1">
                      {t('.insurance_amount', intl, __filenamespace)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" align="right">
                      <FormattedCurrency
                        value={order.insurance_amount || 0}
                        fromCents
                      />
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </div>
          <div className="order-details__middle-block">
            <div className="order-details__middle-inner">
              <Grid container spacing={1}>
                <Grid container item xs={12} justify="space-between">
                  <Grid item>
                    <Typography variant="body1">
                      {t('.total', intl, __filenamespace)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" align="right">
                      <FormattedCurrency value={order.total || 0} fromCents />
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={12} justify="space-between">
                  <Grid item>
                    <Typography variant="body1">
                      {t('.paid', intl, __filenamespace)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" align="right">
                      <FormattedCurrency value={paidAmount} fromCents />
                    </Typography>
                  </Grid>
                </Grid>
                {refundedAmount > 0 && (
                  <Grid container item xs={12} justify="space-between">
                    <Grid item>
                      <Typography variant="body1">
                        {t('.refunds', intl, __filenamespace)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1" align="right">
                        <FormattedCurrency value={refundedAmount} fromCents />
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {waivedAmount > 0 && (
                  <Grid container item xs={12} justify="space-between">
                    <Grid item>
                      <Typography variant="body1">
                        {t('.waives', intl, __filenamespace)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1" align="right">
                        <FormattedCurrency value={waivedAmount} fromCents />
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </div>
          </div>
          <div className="order-details__bottom-block ">
            <Grid container item xs={12} justify="space-between">
              <Grid item>
                <Typography variant="body1">
                  {t('.remaining', intl, __filenamespace)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" align="right">
                  <FormattedCurrency value={remainingAmount} fromCents />
                </Typography>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  );
}

OrderDetails.propTypes = {
  order: PropTypes.object,
  orderItem: PropTypes.object,
};

export default injectIntl(OrderDetails);
