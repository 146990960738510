import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Confirmation } from '@upperhand/playmaker';

import MembershipSuspensionStore from 'memberships/stores/MembershipSuspensionStore.jsx';
import MembershipSuspensionActions from 'memberships/actions/MembershipSuspensionActions.js';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import uhTheme from '_uh_theme.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

import './styles.scss';

const styles = {
  dialog: {
    maxWidth: 350,
  },

  dialogBody: {
    padding: 0,
  },

  header: {
    fontSize: 22,
    padding: 24,
    color: 'white',
    backgroundColor: uhColors.charcoalBlack,
  },

  body: {
    color: uhTheme.palette.textColor,
    fontSize: 15,
    padding: 24,
  },
};

function ReactivationDialog({ membershipSuspensionStore, intl }) {
  const { client, membership, showReactivationDialog, cancelInProgress } =
    membershipSuspensionStore;

  const clientName = client ? client.name() : '';
  const membershipName = membership ? membership.name : '';

  return (
    <Confirmation
      title=""
      classes={{
        root: 'modal-dialog',
        title: 'dialog-title',
        actions: 'modal-actions',
      }}
      hasCheckbox={false}
      open={!!client && showReactivationDialog}
      cancelButtonLabel={t('actions.cancel', intl, __filenamespace)}
      confirmButtonLabel={t(
        cancelInProgress ? '.cancelling' : '.confirm_reactivation',
        intl,
        __filenamespace
      )}
      onCancel={MembershipSuspensionActions.reactivationAborted}
      onConfirm={MembershipSuspensionActions.reactivateMembership}
    >
      <div style={styles.header}>
        <FormattedMessage
          id={messageId('.reactivate_membership', __filenamespace)}
        />
      </div>
      <div style={styles.body}>
        <div style={{ marginBottom: 20, lineHeight: '22px' }}>
          <FormattedMessage
            id={messageId('.are_you_sure', __filenamespace)}
            values={{
              clientName: (
                <span style={{ fontWeight: 'bold' }}>{clientName}</span>
              ),
              membershipName: (
                <span style={{ fontWeight: 'bold' }}>{membershipName}</span>
              ),
            }}
          />
        </div>
      </div>
    </Confirmation>
  );
}

export default compose(
  injectIntl,
  altContainer({
    stores: {
      membershipSuspensionStore: MembershipSuspensionStore,
    },
  })
)(ReactivationDialog);
