import * as React from 'react';
import moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import { Grid, Button, Typography } from '@upperhand/playmaker';
import altContainer from 'shared/hocs/altContainer.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils';
import {
  PhoneNumber,
  Address1,
  Address2,
  City,
  PostalCode,
  State,
  DateOfBirth,
  Gender,
} from 'user_management/shared/components/UserProfileFields.jsx';
import Address from 'shared/records/Address.jsx';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import UserValidator from 'shared/utils/UserValidator.jsx';
import { capitalizeSentence } from 'shared/utils/StringUtils.jsx';
import { ClientDataStore } from 'dataStores';
import {
  formatPhone,
  formatPhoneToE164,
} from 'shared/utils/FormattingUtils.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import useEditMode from '../useEditMode';

const validateClient = (
  client,
  requiredFields = ['phone', 'date_of_birth']
) => {
  const userValidator = new UserValidator(client);
  userValidator.validate(requiredFields);
  return userValidator.errors;
};

function Edit({ client: propClient, toggleEdit, onClientSave, intl }) {
  const [client, setClient] = React.useState(propClient);
  const [errors, setErrors] = React.useState(new FieldErrors());

  React.useEffect(() => {
    setClient(
      propClient.address ? propClient : propClient.set('address', new Address())
    );
  }, [propClient]);

  const updateClient = (path, value) => {
    setClient(client.setIn(path, value));
    if (errors.hasErrors()) {
      setErrors(validateClient(client));
    }
  };

  const saveClient = () => {
    const clientErrors = validateClient(client);

    if (clientErrors.hasErrors()) {
      setErrors(clientErrors);
    } else {
      const attributes = {
        phone: formatPhoneToE164(client.phone),
        date_of_birth: client.date_of_birth,
        gender: client.gender,
      };
      if (!client.address.isBlank()) attributes.address = client.address.toJS();
      onClientSave(client, attributes);
      toggleEdit();
    }
  };

  return (
    <Grid item container direction="column" spacing={2}>
      <Grid item>
        <PhoneNumber
          userProfile={client}
          onChange={(_, value) => updateClient(['phone'], value)}
          errorText={errors.getErrors('phone', intl)}
        />
      </Grid>
      <Grid item>
        <Address1
          userProfile={client}
          onChange={(_, value) => updateClient(['address', 'line_1'], value)}
          errorText={errors.getErrors(['address', 'line_1'], intl)}
        />
      </Grid>
      <Grid item>
        <Address2
          userProfile={client}
          onChange={(_, value) => updateClient(['address', 'line_2'], value)}
          errorText={errors.getErrors(['address', 'line_2'], intl)}
        />
      </Grid>
      <Grid item>
        <City
          userProfile={client}
          onChange={(_, value) => updateClient(['address', 'city'], value)}
          errorText={errors.getErrors(['address', 'city'], intl)}
        />
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={6}>
          <State
            userProfile={client}
            onChange={(_, value) => updateClient(['address', 'state'], value)}
            errorText={errors.getErrors(['address', 'state'], intl)}
          />
        </Grid>
        <Grid item xs={6}>
          <PostalCode
            userProfile={client}
            onChange={(_, value) =>
              updateClient(['address', 'postal_code'], value)
            }
            errorText={errors.getErrors(['address', 'postal_code'], intl)}
          />
        </Grid>
      </Grid>
      <Grid item>
        <DateOfBirth
          userProfile={client}
          onChange={(_, value) => updateClient(['date_of_birth'], value)}
          errorText={errors.getErrors('date_of_birth', intl)}
        />
      </Grid>
      <Grid item>
        <Gender
          userProfile={client}
          onChange={(_, value) => updateClient(['gender'], value)}
          errorText={errors.getErrors('gender', intl)}
        />
      </Grid>
      <Grid item className="contact-info__form-actions">
        <Button onClick={saveClient}>
          {t('.save', intl, __filenamespace)}
        </Button>
      </Grid>
    </Grid>
  );
}

function Show({ client, intl }) {
  return (
    <Grid item container direction="column" spacing={3}>
      <Grid item container direction="column" spacing={1}>
        <Grid item xs={12}>
          <Typography color="secondary" variant="h5">
            {t('.phone', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {formatPhone(client.phone)}
        </Grid>
      </Grid>
      <Grid item container direction="column" spacing={1}>
        <Grid item xs={12}>
          <Typography color="secondary" variant="h5">
            {t('.address', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {client.address ? (
            <span>
              {client.address.line_1}
              {client.address.line_2}
              {client.address.line_3},&nbsp;{client.address.city}&nbsp;
              {client.address.state}&nbsp;
              {client.address.postal_code}
            </span>
          ) : (
            '-'
          )}
        </Grid>
      </Grid>
      <Grid item container direction="column" spacing={1}>
        <Grid item xs={12}>
          <Typography color="secondary" variant="h5">
            {t('.birthdate', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {client.date_of_birth ? (
            <FormattedMessage
              id={messageId('.dob', __filenamespace)}
              values={{
                dob: client.date_of_birth.format('MMM  Do, YYYY'),
                age: moment().diff(client.date_of_birth, 'years'),
              }}
            />
          ) : (
            '-'
          )}
        </Grid>
      </Grid>
      <Grid item container direction="column" spacing={1}>
        <Grid item>
          <Typography color="secondary" variant="h5">
            {t('.select_gender', intl, __filenamespace)}
          </Typography>
        </Grid>
        <Grid item>{capitalizeSentence(client.gender ?? '-')}</Grid>
      </Grid>
    </Grid>
  );
}

function ContactInfo({
  clientId,
  clientDataStore: { clients },
  intl,
  onClientSave,
  onVerifyEmail,
  onResetPassword,
}) {
  const client = clients.get(clientId);
  const [edit, toggleEdit] = useEditMode(clientId);

  if (!client) return null;

  const isInvitePending =
    client.isInvitePending() || client.isUnclaimedAccount();

  return (
    <Grid
      className="contact-info"
      direction="row"
      alignItems="center"
      container
      spacing={2}
    >
      <Grid item container xs={12}>
        <div className="contact-info__header-wrapper">
          <div className="contact-info__header-title">
            <Typography variant="h4" color="secondary">
              {t('.header', intl, __filenamespace)}
            </Typography>
            <Button
              type="tertiary"
              rounded
              size="1x"
              fullWidth
              icon={edit ? 'close' : 'edit'}
              onClick={toggleEdit}
            />
          </div>
          {currentUser().isStaff() && (
            <div className="contact-info__header-actions">
              <Button
                disabled={!isInvitePending}
                classes={{ root: isInvitePending ? 'verify' : 'verified' }}
                icon={isInvitePending ? undefined : 'checkInCircle'}
                type={isInvitePending ? 'contained' : 'tertiary'}
                iconPosition="right"
                onClick={onVerifyEmail}
              >
                {t(
                  isInvitePending ? '.verify' : '.verified',
                  intl,
                  __filenamespace
                )}
              </Button>
              <Button onClick={onResetPassword}>
                {t('.reset', intl, __filenamespace)}
              </Button>
            </div>
          )}
        </div>
      </Grid>
      {edit ? (
        <Edit
          toggleEdit={toggleEdit}
          onClientSave={onClientSave}
          client={client}
          intl={intl}
        />
      ) : (
        <Show client={client} intl={intl} />
      )}
    </Grid>
  );
}

export default altContainer({
  stores: {
    clientDataStore: ClientDataStore,
  },
})(ContactInfo);
