import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, Tabs } from '@upperhand/playmaker';

import Balances from 'containers/reports/balancesReport/BalancesReport.jsx';
import PaymentPlans from 'containers/reports/paymentPlans/PaymentPlans.jsx';
import MessagingDrawer from 'shared/components/MessagingDrawer.jsx';
import MessagingDrawerStore from 'shared/stores/MessagingDrawerStore.jsx';
import Clients from 'containers/events/admin/clients/Clients.jsx';
import CustomerEvent from 'event_mgmt/shared/records/CustomerEvent.jsx';
import Schedule, {
  SessionRange,
} from 'containers/events/admin/schedule/Schedule.jsx';
import Sidebar from 'containers/events/admin/admin_page/components/Sidebar.jsx';
import RegistrationResponses from 'containers/events/admin/registrationResponses/RegistrationResponses.jsx';
import Staff from 'containers/events/admin/staff/Staff.jsx';
import Waitlist from 'containers/events/admin/waitlist/Waitlist.jsx';
import EventMarketingStore from 'event_mgmt/display/stores/EventMarketingStore.js';
import EventMarketingWrapper from 'event_mgmt/display/components/_EventMarketingWrapper.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';

import { EventDataStore } from 'dataStores';
import { t } from 'shared/utils/LocaleUtils.js';
import AdminPageActions from './actions';
import AdminPageStore, {
  PAYMENTS_TAB,
  PAYMENT_PLANS_TAB,
  WAITLIST_TAB,
} from './store';

import './style.scss';

const styles = {
  root: {
    height: '100%',
    backgroundColor: 'var(--color-bg-gray)',
    padding: 4,
  },

  tabContainer: {
    padding: '0 12px',
  },
};

const parseIntEventId = eventSegment =>
  eventSegment ? parseInt(eventSegment.split('-')[0], 10) : null;

function AdminPage({
  adminPageStore: { selectedTab, showTab, revenue },
  eventSlug,
  eventDataStore: { events },
  eventMarketingStore,
  messagingDrawerStore,
  intl,
}) {
  React.useEffect(() => {
    AdminPageActions.mounted(parseIntEventId(eventSlug));
  }, [eventSlug]); // eslint-disable-line react-hooks/exhaustive-deps

  const eventId = parseIntEventId(eventSlug);
  const event = events.get(eventId, new CustomerEvent());

  const tabs = [
    {
      label: t('.roster', intl, __filenamespace),
      content: <Clients event={event} />,
    },
    showTab[PAYMENTS_TAB] && {
      label: t('.payments', intl, __filenamespace),
      content: (
        <Balances eventId={eventId} revenue={revenue} reportMode={false} />
      ),
    },
    showTab[PAYMENT_PLANS_TAB] && {
      label: t('.paymentPlans', intl, __filenamespace),
      content: <PaymentPlans eventId={eventId} />,
    },
    {
      label: t('.schedule', intl, __filenamespace),
      content: (
        <Schedule
          eventId={eventId}
          scheduleType={event.schedule_type}
          sessionRange={SessionRange.FUTURE}
        />
      ),
    },
    {
      label: t('.completed', intl, __filenamespace),
      content: (
        <Schedule
          eventId={eventId}
          scheduleType={event.schedule_type}
          sessionRange={SessionRange.COMPLETED}
        />
      ),
    },
    {
      label: t('.staff', intl, __filenamespace),
      content: <Staff eventId={eventId} />,
    },
    {
      label: t('.registration_responses', intl, __filenamespace),
      content: <RegistrationResponses eventId={eventId} />,
    },
    showTab[WAITLIST_TAB] && {
      label: t('.waitlist', intl, __filenamespace),
      content: <Waitlist eventId={eventId} />,
    },
  ].filter(tab => !!tab);

  const handleTabChange = (_event, value) =>
    AdminPageActions.tabSelected(value);

  return (
    <div style={styles.root} className="admin-events">
      <Grid container spacing={1}>
        <Grid item xs={12} lg={4}>
          <Sidebar event={event} revenue={revenue} />
        </Grid>
        <Grid item xs={12} lg={8}>
          <div style={styles.tabContainer}>
            <Tabs
              eventId={eventId}
              onChange={handleTabChange}
              tabItems={tabs}
              value={selectedTab}
              variant="scrollable"
            />
          </div>
        </Grid>
      </Grid>
      <MessagingDrawer messagingDrawerStore={messagingDrawerStore} />
      {events && (
        <EventMarketingWrapper
          event={event}
          eventMarketingStore={eventMarketingStore}
        />
      )}
    </div>
  );
}

export default altContainer({
  stores: {
    adminPageStore: AdminPageStore,
    eventDataStore: EventDataStore,
    eventMarketingStore: alt.createStore(
      EventMarketingStore,
      'EventMarketingStore'
    ),
    messagingDrawerStore: MessagingDrawerStore,
  },
})(injectIntl(AdminPage));
