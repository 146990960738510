import debounce from 'lodash.debounce';
import { Set, Map } from 'immutable';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import {
  ClientSource,
  ResourceSource,
  RegistrationSource,
  LocationSource,
  StaffSource,
  SessionSource,
  CreditCountSource,
} from 'sources';
import CompletedEventSessionsActions from 'containers/events/admin/schedule/completed_event_sessions/Actions';
import FutureEventSessionsActions from 'containers/events/admin/schedule/future_event_sessions/Actions';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import { SessionDataStore, EventDataStore } from 'dataStores';
import { convertStaffToDataSource } from 'shared/records/Staff.jsx';
import { convertResourceToDataSource } from 'shared/records/Resource.js';
import { convertClientToDataSource } from 'shared/records/Client.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { creditOperations } from 'user_management/shared/stores/EnrolledEventListStore.jsx';

import EditDateTimeActions from '../editDateTime/actions';
import DetailsActions from './actions';

class DetailsStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.debouncedListFilteredStaff = debounce(() => {
      this.listFilteredStaff();
      this.emitChange();
    }, 600);

    this.debouncedListFilteredResources = debounce(() => {
      this.listFilteredResources();
      this.emitChange();
    }, 600);

    this.debouncedListFilteredAttendees = debounce(() => {
      this.listFilteredAttendees();
      this.emitChange();
    }, 600);

    this.bindListeners({
      resetChanges: DetailsActions.resetChanges,
      mounted: DetailsActions.mounted,

      clientListSuccess: DetailsActions.clientListSuccess,
      clientListError: DetailsActions.clientListError,

      registrationListSuccess: DetailsActions.registrationListSuccess,
      registrationListError: DetailsActions.registrationListError,

      resourceListSuccess: DetailsActions.resourceListSuccess,
      resourceListError: DetailsActions.resourceListError,

      sessionStaffListSuccess: DetailsActions.sessionStaffListSuccess,
      sessionStaffListError: DetailsActions.sessionStaffListError,
      sessionResourceListSuccess: DetailsActions.sessionResourceListSuccess,
      sessionResourceListError: DetailsActions.sessionResourceListError,

      filteredStaffListSuccess: DetailsActions.filteredStaffListSuccess,
      filteredStaffListError: DetailsActions.filteredStaffListError,
      filteredResourceListSuccess: DetailsActions.filteredResourceListSuccess,
      filteredResourceListError: DetailsActions.filteredResourceListError,
      filteredAttendeeListSuccess: DetailsActions.filteredAttendeeListSuccess,
      filteredAttendeeListError: DetailsActions.filteredAttendeeListError,

      creditCountListSuccess: DetailsActions.creditCountListSuccess,
      creditCountListError: DetailsActions.creditCountListError,

      initStaffAdd: DetailsActions.initStaffAdd,
      cancelStaffAdd: [
        CompletedEventSessionsActions.closeSessionSummaryDrawer,
        DetailsActions.cancelStaffAdd,
        FutureEventSessionsActions.closeSessionSummaryDrawer,
      ],
      removeStaff: DetailsActions.removeStaff,
      addStaff: DetailsActions.addStaff,

      initResourceAdd: DetailsActions.initResourceAdd,
      cancelResourceAdd: [
        CompletedEventSessionsActions.closeSessionSummaryDrawer,
        DetailsActions.cancelResourceAdd,
        FutureEventSessionsActions.closeSessionSummaryDrawer,
      ],

      initAttendeeAdd: DetailsActions.initAttendeeAdd,
      cancelAttendeeAdd: [
        CompletedEventSessionsActions.closeSessionSummaryDrawer,
        DetailsActions.cancelAttendeeAdd,
        FutureEventSessionsActions.closeSessionSummaryDrawer,
      ],

      addAttendee: DetailsActions.addAttendee,
      addAttendeeSuccess: DetailsActions.addAttendeeSuccess,
      addAttendeeError: DetailsActions.addAttendeeError,

      openPurchaseModal: DetailsActions.openPurchaseModal,
      closePurchaseModal: DetailsActions.closePurchaseModal,

      addResource: DetailsActions.addResource,
      removeResource: DetailsActions.removeResource,

      locationListSuccess: DetailsActions.locationListSuccess,
      locationListError: DetailsActions.locationListError,

      attendeesChanged: DetailsActions.attendeesChanged,
      staffChanged: DetailsActions.staffChanged,
      resourcesChanged: DetailsActions.resourcesChanged,

      updateStaffSearchText: DetailsActions.updateStaffSearchText,
      updateResourceSearchText: DetailsActions.updateResourceSearchText,
      updateAttendeeSearchText: DetailsActions.updateAttendeeSearchText,

      stageRegistrationToRemove: DetailsActions.stageRegistrationToRemove,
      setWaive: DetailsActions.setWaive,
      setCreditOperation: DetailsActions.setCreditOperation,
      cancelRegistration: DetailsActions.cancelRegistration,
      cancelRegistrationSuccess: DetailsActions.cancelRegistrationSuccess,
      cancelRegistrationError: DetailsActions.cancelRegistrationError,

      sessionUpdateSuccess: DetailsActions.sessionUpdateSuccess,
      sessionUpdateError: DetailsActions.sessionUpdateError,

      staffChangedTeam: DetailsActions.staffChangedTeam,
      resourcesChangedTeam: DetailsActions.resourcesChangedTeam,
      handleDescriptionChange: DetailsActions.descriptionChanged,
    });
  }

  reset() {
    this.sessionId = null;
    this.sessionTimes = Map();
    this.eventId = null;

    this.clientIds = Set();
    this.totalRegistrations = 0;
    this.resourceIds = Set();
    this.staffIds = Set();
    this.registrationIds = Set();

    // For purchase modal
    this.clientId = null;

    // convertStaffToDataSource returns a plain array. So default to plain array here.
    this.filteredStaff = [];
    this.filteredResource = [];
    this.filteredAttendees = [];
    this.selectableResources = [];
    this.selectableStaff = [];
    this.selectableAttendees = [];

    this.loadingClients = false;
    this.loadingResources = false;
    this.loadingStaff = false;
    this.loadingLocation = false;
    this.loadingRegistrations = false;

    this.staffExpanded = true;
    this.clientsExpanded = true;
    this.resourcesExpanded = true;

    this.isAddingStaff = false;
    this.isAddingResource = false;
    this.isAddingAttendee = false;

    this.searchedResourceName = '';
    this.searchedStaffName = '';
    this.searchedAttendeeName = '';

    this.successMessage = null;

    // Cancellation Modal fields
    this.registrationToRemove = null;
    this.creditOperation = undefined;
    this.waiveBalance = false;

    this.staffExpandedTeam = true;
    this.resourcesExpandedTeam = true;
    this.changed = false;
    this.descriptionExpanded = true;
  }

  resetChanges() {
    this.isAddingStaff = false;
    this.isAddingResource = '';
    this.isAddingAttendee = '';
    this.searchedResourceName = '';
    this.searchedStaffName = '';
    this.searchedAttendeeName = '';

    this.creditOperation = undefined;
    this.waiveBalance = false;
  }

  mounted([session, eventId]) {
    this.reset();

    this.sessionId = session.get('id');
    this.eventId = eventId;
    this.sessionTimes = Map({
      startsAt: session.get('starts_at'),
      endsAt: session.get('ends_at'),
    });

    if (this.sessionId) {
      this.listClients();
      if (currentCustomer().resources_enabled) {
        this.listResources();
      }
      this.listSessionStaff();
      this.listLocations();
      this.listRegistrations();
    }
  }

  listClients(page = 1) {
    this.loadingClients = true;

    ClientSource.list({
      params: {
        fields: ['agreement_details'],
        session_ids: [this.sessionId],
        per_page: 50,
        page,
      },
      success: DetailsActions.clientListSuccess,
      error: DetailsActions.clientListError,
    });
  }

  clientListSuccess({ clients, page, perPage, totalCount }) {
    this.clientIds = this.clientIds.union(clients.map(c => c.id));
    this.clientsExpanded = this.clientIds.size > 0;

    if (page * perPage < totalCount) {
      this.listClients(page + 1);
    } else {
      this.loadingClients = false;
    }
  }

  clientListError(...args) {
    this.loadingClients = false;
    this.notifyError('error listing clients', args);
  }

  listRegistrations(page = 1) {
    this.loadingRegistrations = true;

    RegistrationSource.list({
      params: {
        session_ids: [this.sessionId],
        per_page: 50,
        fields: ['paid'],
        page,
      },
      success: DetailsActions.registrationListSuccess,
      error: DetailsActions.registrationListError,
    });
  }

  registrationListSuccess({ registrations, page, perPage, totalCount }) {
    this.registrationIds = this.registrationIds.union(
      registrations.map(c => c.id)
    );
    this.totalRegistrations = totalCount;

    if (page * perPage < totalCount) {
      this.listRegistrations(page + 1);
    } else {
      this.loadingRegistrations = false;
    }
  }

  registrationListError(...args) {
    this.loadingRegistrations = false;
    this.notifyError('error listing registrations', args);
  }

  listResources() {
    this.loadingResources = true;

    ResourceSource.list({
      params: {
        session_ids: [this.sessionId],
      },
      success: DetailsActions.resourceListSuccess,
      error: DetailsActions.resourceListError,
    });
  }

  resourceListSuccess({ resources }) {
    this.resourceIds = Set(resources.map(r => r.id));

    this.loadingResources = false;
    this.setSelectableResources();
  }

  resourceListError(...args) {
    this.loadingResources = false;
    this.notifyError('error listing resources', args);
  }

  listSessionStaff() {
    this.loadingStaff = true;

    StaffSource.list({
      params: {
        session_ids: [this.sessionId],
      },
      success: DetailsActions.sessionStaffListSuccess,
      error: DetailsActions.sessionStaffListError,
    });
  }

  sessionStaffListSuccess({ staff }) {
    this.staffIds = Set(staff.map(s => s.id));
    this.staffExpanded = this.staffIds.size > 0;
    this.loadingStaff = false;

    this.setSelectableStaff();
  }

  sessionStaffListError(...args) {
    this.loadingStaff = false;
    this.notifyError('error listing staff', args);
  }

  sessionResourceListError(...args) {
    this.loadingResources = false;
    this.notifyError('error listing resources', args);
  }

  sessionResourceListSuccess({ resources }) {
    this.resourceIds = Set(resources.map(s => s.id));
    this.resourcesExpanded = this.resourceIds.size > 0;
    this.loadingResources = false;

    this.setSelectableResources();
  }

  initStaffAdd() {
    this.staffExpanded = true;
    this.isAddingStaff = true;
    this.searchedStaffName = '';

    this.listFilteredStaff();
  }

  initResourceAdd() {
    this.resourcesExpanded = true;
    this.isAddingResource = true;
    this.searchedResourceName = '';

    this.listFilteredResources();
  }

  initAttendeeAdd() {
    this.clientsExpanded = true;
    this.isAddingAttendee = true;
    this.searchedAttendeeName = '';
    this.listFilteredAttendees();
  }

  updateResourceSearchText([name]) {
    this.searchedResourceName = name;
    this.debouncedListFilteredResources();
  }

  updateStaffSearchText([name]) {
    this.searchedStaffName = name;
    this.debouncedListFilteredStaff();
  }

  updateAttendeeSearchText([name]) {
    this.searchedAttendeeName = name;
    this.debouncedListFilteredAttendees();
  }

  listFilteredStaff() {
    StaffSource.list({
      params: {
        per_page: 10,
        search: this.searchedStaffName,
        // available_starts_at: this.sessionTimes.get('startsAt'),
        // available_ends_at: this.sessionTimes.get('endsAt'),
        access_revoked: false,
      },
      success: DetailsActions.filteredStaffListSuccess,
      error: DetailsActions.filteredStaffListError,
    });
  }

  listFilteredResources() {
    ResourceSource.list({
      params: {
        per_page: 10,
        name: this.searchedResourceName,
        available_starts_at: this.sessionTimes.get('startsAt'),
        available_ends_at: this.sessionTimes.get('endsAt'),
      },
      success: DetailsActions.filteredResourceListSuccess,
      error: DetailsActions.filteredResourceListError,
    });
  }

  listFilteredAttendees() {
    this.loadingAutocomplete = true;
    ClientSource.list({
      params: {
        per_page: 10,
        search: this.searchedAttendeeName,
        statuses: ['active', 'invited'],
      },
      success: DetailsActions.filteredAttendeeListSuccess,
      error: DetailsActions.filteredAttendeeListError,
    });
  }

  filteredStaffListSuccess({ staff }) {
    this.filteredStaff = convertStaffToDataSource(staff);
    this.setSelectableStaff();
  }

  filteredResourceListSuccess({ resources }) {
    this.filteredResource = convertResourceToDataSource(resources);
    this.setSelectableResources();
  }

  filteredAttendeeListSuccess({ clients }) {
    this.filteredAttendees = convertClientToDataSource(clients);
    this.setSelectableAttendees();
    CreditCountSource.list({
      params: {
        client_ids: clients.map(client => client.get('id')).toJS(),
        event_ids: [this.eventId],
        per_page: 100,
      },
      success: DetailsActions.creditCountListSuccess,
      error: DetailsActions.creditCountListError,
    });
  }

  creditCountListSuccess() {
    this.loadingAutocomplete = false;
  }

  filteredStaffListError(...args) {
    this.notifyError('error listing filtered staff', args);
  }

  filteredResourceListError(...args) {
    this.notifyError('error listing filtered resources', args);
  }

  filteredAttendeeListError(...args) {
    this.notifyError('error listing filtered clients', args);
  }

  creditCountListError(...args) {
    this.notifyError('error listing filtered credit counts', args);
  }

  setSelectableResources() {
    this.selectableResources = this.filteredResource.filter(
      s => !this.resourceIds.includes(s.value)
    );
  }

  setSelectableStaff() {
    this.selectableStaff = this.filteredStaff.filter(
      s => !this.staffIds.includes(s.value)
    );
  }

  setSelectableAttendees() {
    this.selectableAttendees = this.filteredAttendees.filter(
      s => !this.clientIds.includes(s.value)
    );
  }

  cancelStaffAdd() {
    this.isAddingStaff = false;
    this.searchedStaffName = '';
    if (this.staffIds.size === 0) this.staffExpanded = false;
  }

  cancelResourceAdd() {
    this.isAddingResource = false;
    this.searchedResourceName = '';
    if (this.resourceIds.size === 0) this.resourcesExpanded = false;
  }

  cancelAttendeeAdd() {
    this.isAddingAttendee = false;
    this.searchedAttendeeName = '';
    if (this.clientIds.size === 0) this.clientsExpanded = false;
  }

  addStaff([{ value }]) {
    if (!value) {
      return;
    }
    this.isAddingStaff = false;
    this.saveChanges({ staff_ids: this.staffIds.add(value) });

    this.updateStaffSearchText(['']);

    this.successMessage = new TranslatableMessage({
      id: '.staff_added',
      filename: __filenamespace,
    });
  }

  removeStaff(id) {
    this.saveChanges({ staff_ids: this.staffIds.remove(id) });

    this.successMessage = new TranslatableMessage({
      id: '.staff_removed',
      filename: __filenamespace,
    });
  }

  addResource([{ value }]) {
    // if user searches for an invaild selection and presses enter don't let them do anything
    if (!value) {
      return;
    }
    this.isAddingResource = false;
    this.saveChanges({ resource_ids: this.resourceIds.add(value) });

    this.updateResourceSearchText(['']);

    this.successMessage = new TranslatableMessage({
      id: '.resource_added',
      filename: __filenamespace,
    });
  }

  removeResource(id) {
    this.saveChanges({ resource_ids: this.resourceIds.remove(id) });

    this.successMessage = new TranslatableMessage({
      id: '.resource_removed',
      filename: __filenamespace,
    });
  }

  addAttendee(id) {
    const scheduleObj = new RegistrationSource.Schedule(id);
    scheduleObj.bySession(this.sessionId);

    this.updateAttendeeSearchText(['']);
    RegistrationSource.schedule({
      scheduleObj,
      success: DetailsActions.addAttendeeSuccess,
      error: DetailsActions.addAttendeeError,
    });
  }

  addAttendeeError(...args) {
    this.notifyError('error while scheduling a registration', args);
  }

  addAttendeeSuccess() {
    this.mounted([this.sessionId, this.eventId]);
    this.resetChanges();
    MessageWindowActions.addMessage.defer(
      new TranslatableMessage({
        id: '.attendee_added',
        filename: __filenamespace,
      })
    );
  }

  openPurchaseModal(clientId) {
    this.searchedAttendeeName = '';
    this.clientId = clientId;
  }

  closePurchaseModal() {
    this.clientId = null;
  }

  saveChanges(changes) {
    const { sessions } = SessionDataStore.getState();
    const session = sessions.get(this.sessionId);

    if (session) {
      SessionSource.put({
        id: this.sessionId,
        params: {
          attributes: session.merge(changes),
          fields: ['note'],
        },
        success: DetailsActions.sessionUpdateSuccess,
        error: DetailsActions.sessionUpdateError,
      });
    }
  }

  listLocations() {
    this.loadingLocation = true;

    LocationSource.fetchSessionLocation({
      params: {
        session_ids: [this.sessionId],
      },
      success: DetailsActions.locationListSuccess,
      error: DetailsActions.locationListError,
    });
  }

  locationListSuccess({ locations }) {
    this.locationId = locations.size > 0 ? locations.first().id : null;
    EditDateTimeActions.handleLocationChange.defer(this.locationId);
    this.loadingLocation = false;
  }

  locationListError(...args) {
    this.loadingLocation = false;
    this.notifyError('error listing locations', args);
  }

  attendeesChanged(expanded) {
    // We cannot expand when there is no content to show.
    if (!(expanded && this.clientIds.size === 0))
      this.clientsExpanded = expanded;
  }

  staffChanged(expanded) {
    if (!(expanded && this.staffIds.size === 0)) this.staffExpanded = expanded;
  }

  resourcesChanged(expanded) {
    if (!(expanded && this.resourceIds.size === 0))
      this.resourcesExpanded = expanded;
  }

  staffChangedTeam(expanded) {
    this.staffExpandedTeam = expanded;
  }

  handleDescriptionChange(expanded) {
    this.descriptionExpanded = expanded;
  }

  resourcesChangedTeam(expanded) {
    this.resourcesExpandedTeam = expanded;
  }

  stageRegistrationToRemove(registration) {
    this.registrationToRemove = registration;

    if (!registration) {
      return;
    }

    this.creditOperation = registration.paid
      ? creditOperations.REFUND_CREDIT
      : creditOperations.REVOKE_CREDIT;

    this.waiveBalance = !registration.paid;
  }

  setCreditOperation(operation) {
    this.creditOperation = operation;
  }

  setWaive(checked) {
    this.waiveBalance = checked;
  }

  cancelRegistration() {
    const { events } = EventDataStore.getState();
    const event = events.get(this.eventId);
    const isSSPOn = event.isSSPTurnedOn();
    const isFixedSchedule = event.isFixedSchedule();
    const clientId = this.registrationToRemove.get('client_id');

    if (isFixedSchedule && !isSSPOn) {
      return RegistrationSource.destroyFixedSSPOff({
        eventId: this.eventId,
        params: {
          client_ids: [clientId],
          waive_balance: this.waiveBalance,
        },
        success: DetailsActions.cancelRegistrationSuccess,
        error: DetailsActions.cancelRegistrationError,
      });
    }
    return RegistrationSource.destroy({
      id: this.registrationToRemove.id,
      params: this.params(),
      success: DetailsActions.cancelRegistrationSuccess,
      error: DetailsActions.cancelRegistrationError,
    });
  }

  cancelRegistrationSuccess() {
    this.creditOperation = undefined;
    this.waiveBalance = false;
    this.registrationToRemove = null;
    MessageWindowActions.addMessage.defer(
      new TranslatableMessage({
        id: '.attendee_removed',
        filename: __filenamespace,
      })
    );
    this.listClients();
  }

  cancelRegistrationError(...args) {
    this.notifyError(`error while deleting registration`, args);
  }

  params() {
    if (this.creditOperation === creditOperations.REVOKE_CREDIT) {
      return { waive_balance: this.waiveBalance, revoke_credit: true };
    }

    return {};
  }

  sessionUpdateSuccess(session) {
    if (this.successMessage) {
      MessageWindowActions.addMessage.defer(this.successMessage);
      this.successMessage = null;
    }
    this.staffIds = session.staff_ids;
    this.resourceIds = session.resource_ids;
    this.resetChanges();
  }

  sessionUpdateError(...args) {
    this.notifyError('error updating session', args);
  }
}

export default alt.createStore(DetailsStore, 'DetailsStore');
