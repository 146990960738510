import PropTypes from 'prop-types';
import * as React from 'react';
import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import ThemeSidebar from 'client_theme/ThemeSidebar.jsx';
import ThemeSmallLogin from 'client_theme/ThemeSmallLogin.jsx';
import activeTheme from '_uh_theme.jsx';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { SINGLE_COLUMN_WIDTH } from 'shared/utils/DOMUtils';
import { isCampPulseTheme } from 'shared/utils/ThemeUtils';

const styles = {
  safariSupport: { position: 'absolute', top: '0', bottom: '0', width: '100%' },
};

class SmallScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: 1,
      initialHeight: 1,
    };
    this.setHeight = this.setHeight.bind(this);
    this.resetInitialHeight = this.resetInitialHeight.bind(this);
  }

  componentDidMount() {
    this.setState({
      initialHeight: window.innerHeight,
      windowHeight: window.innerHeight,
    });
    window.addEventListener('resize', this.setHeight);
    window.addEventListener('orientationchange', this.resetInitialHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setHeight);
    window.removeEventListener('orientationchange', this.resetInitialHeight);
  }

  setHeight() {
    this.setState({ windowHeight: window.innerHeight });
  }

  resetInitialHeight() {
    // The order of the orientation and resize events is not consistent. We need to check if we are
    // in landscape or portrait here and pick the appropriate dimension.
    const actualHeight =
      window.orientation === 90 || window.orientation === -90
        ? Math.min(window.innerHeight, window.innerWidth)
        : Math.max(window.innerHeight, window.innerWidth);

    this.setState({ initialHeight: actualHeight });
  }

  render() {
    // There is no event that fires when the keyboard is open. Instead we will use the fact that
    // the keyboard reduces the viewport size on android, and see if we have lost more than 30% of
    // our initial viewport, which most likely means the keyboard is open. On iOS the keyboard does
    // not change the viewport, so this change will not have an effect on iOS.
    const { windowHeight, initialHeight } = this.state;

    const keyboardOpen = windowHeight / initialHeight < 0.7;
    const justifyStyle = keyboardOpen ? { justifyContent: 'flex-end' } : {};
    const { children } = this.props;

    return ThemeSmallLogin({ styles, keyboardOpen, justifyStyle, children });
  }
}

function LargeScreen({ children, modalOpen }) {
  return (
    <div
      style={
        modalOpen ? { width: '900px', height: '600px' } : { height: '100%' }
      }
    >
      <ThemeSidebar modalOpen={modalOpen} />
      <FlexBoxCenter
        style={{
          paddingLeft: isCampPulseTheme() ? '0' : SINGLE_COLUMN_WIDTH,
          height: '100%',
          justifyContent: 'center',
          backgroundColor: '#fff',
        }}
      >
        <div
          style={
            modalOpen
              ? { margin: '0 100px', width: '100%' }
              : { margin: '0 20px', maxWidth: SINGLE_COLUMN_WIDTH }
          }
        >
          <img
            src={activeTheme.logo.url}
            width={activeTheme.logo.width}
            style={{ marginBottom: '5rem' }}
            alt="logo"
          />
          {children}
        </div>
      </FlexBoxCenter>
    </div>
  );
}

function LandingTemplate({
  smallScreen = null,
  largeScreen = null,
  modalOpen = false,
}) {
  return (
    <ResponsiveElement
      largeScreen={
        <LargeScreen modalOpen={modalOpen}>{largeScreen}</LargeScreen>
      }
      smallScreen={<SmallScreen>{smallScreen}</SmallScreen>}
    />
  );
}

LandingTemplate.propTypes = {
  smallScreen: PropTypes.node,
  largeScreen: PropTypes.node,
  modalOpen: PropTypes.bool,
};

export default LandingTemplate;
