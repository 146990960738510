import * as React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@upperhand/playmaker';

import AutomationTemplateDescriptionActions from 'shared/actions/AutomationTemplateDescriptionActions.jsx';
import ClientCreditActions from 'shared/actions/ClientCreditActions';
import CartActions from 'event_mgmt/shared/actions/CartActions.jsx';
import EventDisplayActions from 'event_mgmt/display/actions/EventDisplayActions.jsx';
import RegistrationActions from 'event_mgmt/display/actions/RegistrationActions.jsx';
import SchedulingDrawerActions from 'shared/actions/SchedulingDrawerActions';

import { BrowseEvent } from 'records';
import { STATUS } from 'records/compoundObjects/BrowseEvent.js';
import { isLoggedIn } from 'shared/utils/UserUtils.jsx';
import { humanizeDuration } from 'event_mgmt/shared/utils/FormattingUtils.jsx';
import { smallScreen } from 'shared/utils/DOMUtils';
import { t } from 'shared/utils/LocaleUtils.js';

import StaffDisplay from 'components/StaffDisplay.jsx';

function OpenBookingCard({ browseEvent, intl }) {
  const { duration, eventId, eventTitle, eventTypeName, staffIds, status } =
    browseEvent;
  const isSmallScreen = smallScreen();
  const handleClick = _e => {
    _e.stopPropagation();
    if (isLoggedIn()) {
      CartActions.fetch({});
      EventDisplayActions.clientDisplayMounted({ eventId });
      AutomationTemplateDescriptionActions.list({
        eventId,
      });
      ClientCreditActions.list({ eventIds: [eventId] });
      RegistrationActions.list({ event_id: eventId });
    }
    SchedulingDrawerActions.clientSelected();
  };

  return (
    <Grid
      container
      alignItems={isSmallScreen ? 'flex-start' : 'center'}
      xs={12}
      spacing={1}
    >
      <Grid container item xs={3} sm={2}>
        <Grid item xs={12}>
          <Typography variant="h5">{humanizeDuration(duration)}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="drawerTitle">
            {t('.session', intl, __filenamespace)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={6} sm={7}>
        <Grid container item xs={12} sm={8}>
          <Grid item xs={12}>
            <Typography variant="h5" className="browse-events__event-title">
              {eventTitle}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="drawerTitle">{eventTypeName}</Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={4} alignItems="center">
          {staffIds.length > 1
            ? t('.staff_count', intl, __filenamespace, {
                count: staffIds.length,
              })
            : staffIds.map(staffId => (
                <StaffDisplay key={staffId} staffId={staffId} />
              ))}
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={3}
        sm={3}
        direction={isSmallScreen ? 'column' : 'row'}
        justify="flex-end"
      >
        <Grid item xs={isSmallScreen ? 12 : 6}>
          <Button
            type="secondary"
            fullWidth
            disabled={status !== STATUS.active}
            onClick={handleClick}
            classes={{
              root: 'browse-events__button',
            }}
          >
            {t('.btn', intl, __filenamespace)}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

OpenBookingCard.propTypes = {
  browseEvent: PropTypes.instanceOf(BrowseEvent).isRequired,
  intl: PropTypes.object.isRequired,
};

export default OpenBookingCard;
