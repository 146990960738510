import { List, Set } from 'immutable';
import ContactGroup from 'shared/records/ContactGroup.jsx';
import ContactGroupActions from 'shared/actions/ContactGroupActions.jsx';
import ContactsGroupsActions from 'containers/contacts/contactsGroups/Actions';
import InviteActions from 'user_management/shared/actions/InviteActions.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

const url = id => {
  if (id) {
    return `/contact_groups/${id}`;
  }
  return '/contact_groups';
};

class ContactGroupStore extends UpperHandStore {
  constructor() {
    super();
    this.contactGroup = new ContactGroup();
    this.allGroup = new ContactGroup({
      id: -1,
      name: 'All Contacts',
    });
    this.allContactGroups = List().unshift(this.allGroup);
    this.selectedContactGroups = Set();
    this.selectedContacts = List();

    this.isLoading = false;
    this.isSaving = false;

    // Deleting
    this.awaitingConfirmation = false;
    this.toDelete = null;

    this.isAddingContact = false;

    this.findById = id => this.allContactGroups.find(group => group.id === id);

    this.bindListeners({
      resetGroup: ContactGroupActions.RESET_CONTACT_GROUP,
      stageGroup: [
        ContactGroupActions.STAGE_CONTACT_GROUP,
        ContactsGroupsActions.selectContactGroup,
      ],
      updateStore: ContactGroupActions.UPDATE_STORE,

      handleListAll: ContactsGroupsActions.mounted,
      handleList: ContactGroupActions.LIST,
      handleListSuccess: ContactGroupActions.LIST_SUCCESS,
      handleListError: ContactGroupActions.LIST_ERROR,

      create: ContactGroupActions.CREATE,
      createSuccess: ContactGroupActions.CREATE_SUCCESS,
      createError: ContactGroupActions.CREATE_ERROR,

      deleteRequested: ContactGroupActions.DELETE_REQUESTED,
      deleteCancelled: ContactGroupActions.DELETE_CANCELLED,
      doDelete: ContactGroupActions.DELETE,
      deleteSuccess: ContactGroupActions.DELETE_SUCCESS,
      deleteError: ContactGroupActions.DELETE_ERROR,

      toggleAddContact: ContactGroupActions.TOGGLE_ADD_CONTACT,
      addContact: [
        ContactGroupActions.ADD_CONTACT,
        ContactsGroupsActions.addContact,
      ],
      addContactSuccess: ContactGroupActions.ADD_CONTACT_SUCCESS,
      addContactError: ContactGroupActions.ADD_CONTACT_ERROR,

      removeContacts: [
        ContactGroupActions.REMOVE_CONTACTS,
        ContactsGroupsActions.removeContacts,
      ],
      removeSelectedContacts: [
        ContactGroupActions.REMOVE_SELECTED_CONTACTS,
        ContactsGroupsActions.removeSelectedContacts,
      ],
      removeContactsSuccess: ContactGroupActions.REMOVE_CONTACTS_SUCCESS,
      removeContactsError: ContactGroupActions.REMOVE_CONTACTS_ERROR,

      handleContactsSelected: ContactGroupActions.CONTACTS_SELECTED,
      handleGroupSelection: ContactGroupActions.SELECTION,

      handleInviteDeleted: InviteActions.DELETE_SUCCESS,
    });
  }

  resetGroup() {
    this.contactGroup = this.allContactGroups.first();
    this.selectedContacts = List();
  }

  stageGroup(id) {
    this.contactGroup = this.findById(id) || new ContactGroup();
    this.selectedContacts = List();
  }

  updateStore([keypath, value]) {
    this.contactGroup = this.contactGroup.setIn(keypath, value);
  }

  handleListAll(page = 1) {
    this.isLoading = true;

    return uhApiClient.get({
      url: url(),
      data: {
        page,
        per_page: 50,
      },
      success: {
        action: ContactGroupActions.listSuccess,
        args: [true],
      },
      error: ContactGroupActions.listError,
    });
  }

  handleList() {
    this.isLoading = true;

    return uhApiClient.get({
      url: url(),
      data: { per_page: 50 },
      success: {
        action: ContactGroupActions.listSuccess,
        args: [false],
      },
      error: ContactGroupActions.listError,
    });
  }

  handleListSuccess([data, listAll = false]) {
    this.allContactGroups = this.allContactGroups.merge(
      List(data.contact_groups.map(cg => new ContactGroup(cg))).unshift(
        this.allGroup
      )
    );
    this.selectedContacts = List();

    if (listAll && data.page * data.per_page < data.total_count) {
      this.handleListAll(data.page + 1);
      return;
    }

    this.isLoading = false;
  }

  handleListError(...args) {
    this.isLoading = false;
    this.notifyError('error while listing contacts', args);
  }

  toggleAddContact() {
    this.isAddingContact = !this.isAddingContact;
  }

  addContact(contactId) {
    const params = {
      attributes: {
        customer_user_ids: [contactId],
      },
    };

    this.isAddingContact = true;
    uhApiClient.post({
      url: `${url(this.contactGroup.id)}/add`,
      data: JSON.stringify(params),
      success: ContactGroupActions.addContactSuccess,
      error: ContactGroupActions.addContactError,
    });
  }

  addContactSuccess(group) {
    const fetchedGroup = new ContactGroup(group);
    const index = this.allContactGroups.indexOf(this.contactGroup);

    this.contactGroup = fetchedGroup;
    this.allContactGroups = this.allContactGroups.set(index, this.contactGroup);
    this.isAddingContact = false;
  }

  addContactError(...args) {
    this.isAddingContact = false;
    this.notifyError('error while adding contact to group', args);
  }

  removeContacts(contactsToRemove) {
    const params = {
      attributes: {
        customer_user_ids: contactsToRemove,
      },
    };

    uhApiClient.post({
      url: `${url(this.contactGroup.id)}/remove`,
      data: JSON.stringify(params),
      success: ContactGroupActions.removeContactsSuccess,
      error: ContactGroupActions.removeContactsError,
    });
  }

  removeSelectedContacts() {
    const params = {
      attributes: {
        customer_user_ids: this.selectedContacts,
      },
    };

    uhApiClient.post({
      url: `${url(this.contactGroup.id)}/remove`,
      data: JSON.stringify(params),
      success: ContactGroupActions.removeContactsSuccess,
      error: ContactGroupActions.removeContactsError,
    });
  }

  removeContactsSuccess(group) {
    const fetchedGroup = new ContactGroup(group);
    const index = this.allContactGroups.indexOf(this.contactGroup);

    this.contactGroup = fetchedGroup;
    this.allContactGroups = this.allContactGroups.set(index, this.contactGroup);
    this.selectedContacts = List();
  }

  removeContactsError(...args) {
    this.notifyError('error while removing contact', args);
  }

  create({ contactFilterId }) {
    if (!this.isSaving) {
      this.isSaving = true;

      uhApiClient.post({
        url: url(),
        data: this.payload({ contactFilterId }),
        success: ContactGroupActions.createSuccess,
        error: ContactGroupActions.createError,
      });
    }
  }

  createSuccess(data) {
    const contactGroup = new ContactGroup(data);

    this.contactGroup = contactGroup;
    this.allContactGroups = this.allContactGroups
      .push(contactGroup)
      .sortBy(group => group.name);
    this.isSaving = false;

    if (contactGroup.contact_filter_id) {
      const message = new TranslatableMessage({
        id: 'shared.stores.ContactGroupStore.create_group_filter_success',
      });

      MessageWindowActions.addMessage.defer(message);
    } else {
      const message = new TranslatableMessage({
        id: 'shared.stores.ContactGroupStore.create_group_success',
      });

      MessageWindowActions.addMessage.defer(message);
    }
  }

  createError(...args) {
    this.isSaving = false;
    this.notifyError('error while creating contact group', args);
  }

  deleteRequested(id) {
    if (id) {
      this.toDelete = id;
      this.awaitingConfirmation = true;
    }
  }

  deleteCancelled() {
    this.toDelete = null;
    this.awaitingConfirmation = false;
  }

  doDelete() {
    this.awaitingConfirmation = false;

    if (this.toDelete) {
      uhApiClient.delete({
        url: url(this.toDelete),
        success: ContactGroupActions.deleteSuccess,
        error: ContactGroupActions.deleteError,
      });
    }
  }

  deleteSuccess() {
    this.allContactGroups = this.allContactGroups.filterNot(
      group => group.id === this.toDelete
    );

    if (this.contactGroup.id === this.toDelete) {
      this.contactGroup = this.allContactGroups.first();
    }

    this.toDelete = null;
  }

  deleteError(...args) {
    this.toDelete = null;
    this.notifyError('error while deleting contact group', args);
  }

  handleContactsSelected(selectedContacts) {
    this.selectedContacts = selectedContacts;
  }

  handleGroupSelection(newSelection) {
    this.selectedContactGroups = newSelection;
  }

  handleInviteDeleted([_, id]) {
    this.contactGroup = this.contactGroup.removeContacts([id]);
    this.allContactGroups = this.allContactGroups.map(group =>
      group.removeContacts([id])
    );
  }

  payload({ contactFilterId }) {
    if (contactFilterId) {
      return JSON.stringify({
        attributes: { contact_filter_id: contactFilterId },
      });
    }

    return JSON.stringify({ attributes: this.contactGroup.toServer() });
  }
}

export default alt.createStore(ContactGroupStore, 'ContactGroupStore');
