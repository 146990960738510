import { List } from 'immutable';

import EmailCampaign from 'shared/records/EmailCampaign';

import Parser from 'sources/Parser';
import { onSuccess, onError } from 'sources/handlers';

import uhApiClient from 'shared/helpers/uhApiClient.jsx';

import DataStoreActions from './EmailCampaignsActions';

const parserTo = { type: EmailCampaign, paginationKey: 'campaigns' };

const parser = new Parser(parserTo);

export const create = ({ campaign, fields, success, error }) => {
  uhApiClient.post({
    url: `marketing/${parserTo.paginationKey}`,
    data: JSON.stringify({
      attributes: campaign,
      fields,
    }),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.createSuccess, success])
    ),
    error: onError(List([DataStoreActions.createError, error])),
  });
};

export const update = ({ campaign, fields, success, error }) => {
  uhApiClient.put({
    url: `marketing/${parserTo.paginationKey}/${campaign.id}`,
    data: JSON.stringify({
      attributes: campaign,
      fields,
    }),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.updateSuccess, success])
    ),
    error: onError(List([DataStoreActions.updateError, error])),
  });
};

export const remove = ({ campaignId, success, error }) => {
  uhApiClient.delete({
    url: `marketing/${parserTo.paginationKey}/${campaignId}`,
    success: onSuccess(
      () => parser.parseSingle({ id: campaignId }),
      List([DataStoreActions.deleteSuccess, success])
    ),
    error: onError(List([DataStoreActions.deleteError, error])),
  });
};

export const cancel = ({ campaignId, success, error }) => {
  uhApiClient.post({
    url: `marketing/${parserTo.paginationKey}/${campaignId}/cancel`,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.cancelSuccess, success])
    ),
    error: onError(List([DataStoreActions.cancelError, error])),
  });
};

export const fetch = ({ id, params, success, error }) => {
  uhApiClient.get({
    url: `marketing/${parserTo.paginationKey}/${id}`,
    data: params,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.fetchSuccess, success])
    ),
    error: onError(List([DataStoreActions.fetchError, error])),
  });
};

export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: `marketing/${parserTo.paginationKey}`,
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error: onError(List([DataStoreActions.listError, error])),
  });
};

export const downloadCsv = ({ params, success, error }) => {
  uhApiClient.get({
    url: `marketing/${parserTo.paginationKey}_csv`,
    headers: { Accept: 'application/csv', 'Content-Type': 'application/csv' },
    data: params,
    success: onSuccess(data => parser.parseNone(data), success),
    error,
  });
};
