import * as React from 'react';
import { useIntl } from 'react-intl';
import CartActions from 'event_mgmt/shared/actions/CartActions.jsx';
import CartCommitButton from 'shared/components/checkout/CartCommitButton.jsx';
import DaytimesExpander from 'event_mgmt/display/components/_DaytimesExpander.jsx';
import PaymentPlanSelector from 'shared/components/registration/PaymentPlanSelector.jsx';
import PDRegistrationPackageActions from 'event_mgmt/display/actions/PurchaseDrawerRegistrationPackageActions.jsx';
import SimpleFixedScheduleContent from 'event_mgmt/display/components/purchase_drawer/_SimpleFixedScheduleContent.jsx';
import SingleSessionPurchasableContent from 'shared/components/checkout/SingleSessionPurchasableContent.jsx';
import SingleSessionPurchasableContentActions from 'event_mgmt/display/actions/SingleSessionPurchasableContentActions.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';

import { t } from 'shared/utils/LocaleUtils';

const handlePlanSelect = uuid => {
  PDRegistrationPackageActions.paymentPlanSelected(uuid);
};

function FixedScheduleContent({
  purchasedCounts,
  athletes,
  cartStore,
  eventStore,
  membershipEventDiscounts,
  pdRegistrationPackageStore,
  sessions,
  sessionsShowMore,
  sessionsLoadingMore,
  onSessionsLoadMore,
  sspStore,
}) {
  const intl = useIntl();
  const {
    allDayRegistrationCount,
    allRegisteredAthleteIds,
    changed,
    isSessionAvailable,
    orderItemId,
    packageIsValid,
    paymentPlanDescription,
    purchasedRegistrations,
    registrationPackage,
    singleSessionRegistrationCount,
    sessionAvailability,
    tentativeAthleteIds,
    totalPrice,
  } = pdRegistrationPackageStore;
  const { customerEvent, spots_remaining: spotsRemaining } = eventStore;
  const { isUpdatingCart, isNavigatingToCart } = cartStore;
  const eventIsFull = tentativeAthleteIds.size >= spotsRemaining;

  const handleUpdateCartClick = () => {
    CartActions.update(orderItemId, registrationPackage, { redirect: true });
  };

  const handleAddToCartClick = () => {
    CartActions.registrationPackageAdded(registrationPackage, {
      redirect: true,
    });
  };

  const handleSSPProfileSelect = (_, __, id) => {
    const buyerLimitReached = customerEvent.isBuyerLimitReached({
      clientId: id,
      purchasedCounts,
    });

    if (!buyerLimitReached) {
      PDRegistrationPackageActions.singleSessionAttendeeAdded(id);
    } else {
      MessageWindowActions.addMessage(
        t('.buyer_limit_reached', intl, __filenamespace)
      );
    }
  };

  const selectableAthletes = athletes.filter(
    a =>
      !allRegisteredAthleteIds.has(a.id) &&
      !customerEvent.participant_ids.has(a.id) &&
      !customerEvent.registered_client_ids.has(a.id) &&
      a.hasAccessTo(customerEvent)
  );

  const selectedAthletes = customerEvent.allow_single_session_purchase
    ? athletes.filter(a => allRegisteredAthleteIds.has(a.id))
    : athletes.filter(a => tentativeAthleteIds.has(a.id));

  const daytimes = customerEvent
    .getIn(['schedules', 0, 'availability_schedule'])
    .dailyHours();

  return (
    <div>
      <DaytimesExpander daytimes={daytimes} style={{ marginBottom: 20 }} />

      {!!paymentPlanDescription && (
        <PaymentPlanSelector
          registrationPackage={registrationPackage}
          paymentPlan={paymentPlanDescription}
          onChange={handlePlanSelect}
        />
      )}

      {eventStore.customerEvent.get('allow_single_session_purchase') ? (
        <SingleSessionPurchasableContent
          purchasedCounts={purchasedCounts}
          afterProfileCreate={
            PDRegistrationPackageActions.singleSessionProfileCreated
          }
          allDayRegistrationCount={allDayRegistrationCount}
          contentActions={SingleSessionPurchasableContentActions}
          event={customerEvent}
          eventIsFull={eventIsFull}
          fullPrice={customerEvent.price}
          isSessionAvailable={isSessionAvailable}
          membershipEventDiscounts={membershipEventDiscounts}
          onProfileSelect={handleSSPProfileSelect}
          purchasedRegistrations={purchasedRegistrations}
          registrationPackage={registrationPackage}
          schedulingActions={PDRegistrationPackageActions}
          sessions={sessions}
          sessionsShowMore={sessionsShowMore}
          sessionsLoadingMore={sessionsLoadingMore}
          onSessionsLoadMore={onSessionsLoadMore}
          selectableAthletes={selectableAthletes}
          selectedAthletes={selectedAthletes}
          singleSessionPrice={customerEvent.getTieredPrice(
            singleSessionRegistrationCount,
            customerEvent.single_session_price
          )}
          singleSessionRegistrationCount={singleSessionRegistrationCount}
          sessionAvailability={sessionAvailability}
          sspStore={sspStore}
          totalPrice={totalPrice}
        />
      ) : (
        <SimpleFixedScheduleContent
          purchasedCounts={purchasedCounts}
          event={customerEvent}
          eventIsFull={eventIsFull}
          membershipEventDiscounts={membershipEventDiscounts}
          paymentPlanDescription={paymentPlanDescription}
          selectableAthletes={selectableAthletes}
          selectedAthletes={selectedAthletes}
          totalPrice={totalPrice}
        />
      )}

      <CartCommitButton
        itemIsValid={packageIsValid}
        isUpdatingCart={isUpdatingCart}
        isNavigatingToCart={isNavigatingToCart}
        hasOrderItemId={orderItemId}
        hasUpdate={changed}
        handleUpdateCartClick={handleUpdateCartClick}
        handleAddToCartClick={handleAddToCartClick}
      />
    </div>
  );
}

export default FixedScheduleContent;
